import React, {type ReactElement} from 'react';
import {Chip, type ChipProps, useTheme} from '@mui/joy';
import type {SxProps} from '@mui/joy/styles/types';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import {useLocalization} from '../../context/localization';
import {notBlank} from '../../utils';

export function TransactionStatusChip({clearedAt, size = 'sm', ...properties}: ChipProps & { clearedAt?: string }) {
    const theme = useTheme();
    const {t} = useLocalization();
    const label = notBlank(clearedAt)
        ? t('transactions.cleared')
        : t('transactions.inProgress');
    let icon: ReactElement = <QuestionMarkRoundedIcon />;
    let sx: SxProps = {
        backgroundColor: undefined,
        color: 'white'
    };
    if (notBlank(clearedAt)) {
        sx.bgcolor = 'success.500';
        icon = <CheckRoundedIcon />;
    } else {
        sx.bgcolor = 'warning.plainColor';
        icon = <HourglassTopRoundedIcon />;
    }
    sx = {
        ...sx,
        '& .MuiChip-startDecorator svg': {
            fontSize: theme.fontSize.md
        }
    };
    return <Chip {...{sx, size, props: properties}} startDecorator={icon}>{label}</Chip>;
}