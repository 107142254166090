import React, {useMemo, useState} from 'react';
import {ForgotPasswordDialog, type ForgotPasswordFormData} from '@mlyngvo/common-ui';
import {useLocalization} from '../../context/localization';
import {Logo} from '../../assets/logo';
import {useAuthApi} from '../../data/auth';
import {hashPassword, notBlank} from '../../utils';

enum State {
    Init = 'Init',
    Verification = 'Verification',
    NewPassword = 'NewPassword',
    Success = 'Success'
}

export function AppForgotPassword() {
    const {t} = useLocalization();
    const {requestVerification, verifyCode, resetPassword} = useAuthApi();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();
    const [state, setState] = useState(State.Init);

    const [code, setCode] = useState('');

    async function handleSubmit({email, verificationCode, newPassword, confirmNewPassword}: ForgotPasswordFormData) {
        setError(undefined);

        try {
            setLoading(true);
            switch (state) {
                case State.Init: {
                    await requestVerification(email);
                    setState(State.Verification);
                    break;
                }
                case State.Verification: {
                    if (notBlank(verificationCode)) {
                        /* eslint-disable @typescript-eslint/no-non-null-assertion */
                        await verifyCode(email, verificationCode!);
                        setCode(verificationCode!);
                        /* eslint-enable @typescript-eslint/no-non-null-assertion */
                        setState(State.NewPassword);
                    }
                    break;
                }
                case State.NewPassword: {
                    if (notBlank(code) && notBlank(newPassword) && newPassword === confirmNewPassword) {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        await resetPassword(email, code, hashPassword(newPassword!));
                        setState(State.Success);

                    } else {
                        setError(t('auth.passwordMismatch'));
                    }
                    break;
                }
                default: {
                    console.error('Not handled.');
                    break;
                }
            }
        } catch (error_) {
            console.error('Cannot process data', error_);
            setError(t('auth.verificationError'));
        } finally {
            setLoading(false);
        }
    }

    const submitLabel = useMemo(() => {
        switch (state) {
            case State.Init:
            case State.Verification: {
                return t('actions.next');
            }
            case State.NewPassword: {
                return t('auth.resetPassword');
            }
            default: {
                return '';
            }
        }

    }, [state, t]);
    return (
        <ForgotPasswordDialog
            appTitle="Pin Point Center"
            logo={<Logo />}
            onSubmit={handleSubmit}
            verificationMode={state === State.Verification}
            newPasswordMode={state === State.NewPassword}
            loginUrl="/login"
            i18n={{
                form: t('auth.forgotPassword'),
                email: t('common.email'),
                code: t('auth.verificationCode'),
                newPassword: t('users.newPassword'),
                confirmPassword: t('users.confirmPassword'),
                submit: submitLabel,
                backToLogin: t('auth.login'),
            }}
            loading={loading}
            success={state === State.Success}
            error={error}
        />
    );
}