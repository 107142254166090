import React, {type ReactElement, useRef, useState} from 'react';
import {Button, ButtonGroup, type ButtonGroupProps, IconButton, Menu, MenuItem} from '@mui/joy';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

interface DropdownButtonProperties extends ButtonGroupProps {
    items: Array<{ label: string; onClick: () => void, startDecorator?: ReactElement }>;
}

export function DropdownButton({items, size = 'sm', variant = 'solid', color = 'primary', ...properties}: DropdownButtonProperties) {
    const anchorReference = useRef<HTMLDivElement>(null);

    const [open, setOpen] = useState(false);

    if (items.length === 0) {
        return <div />;
    }

    const [baseButton, ...rest] = items;
    return (
        <>
            <ButtonGroup
                ref={anchorReference}
                {...{
                    size,
                    variant,
                    color,
                    ...properties
                }}
            >
                <Button onClick={baseButton.onClick} startDecorator={baseButton.startDecorator}>{baseButton.label}</Button>
                {rest.length > 0 && (
                    <IconButton
                        onClick={() => { setOpen(v => !v); }}
                    >
                        <ArrowDropDownRoundedIcon />
                    </IconButton>
                )}
            </ButtonGroup>
            <Menu
                open={open}
                onClose={() => { setOpen(false); }}
                anchorEl={anchorReference.current}
                placement="bottom-end"
            >
                {rest.map(({label, onClick}, index) => (
                    <MenuItem
                        key={`dropdown-button-item-${index}`}
                        onClick={() => {
                            setOpen(false);
                            onClick();
                        }}
                    >
                        {label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}