import {createLocalization} from '@mlyngvo/common-ui';

export const {
    LocalizationProvider,
    useLocalization
} =
    // eslint-disable-next-line @typescript-eslint/consistent-type-imports
    createLocalization<typeof import('../i18n/en.json')>({
    i18n: async (lang) => await import((`../i18n/${lang}.json`))
});

export function useTableI18n() {
    const {t} = useLocalization();
    return {
        next: t('actions.next'),
        prev: t('actions.prev'),
        selectPage: t('table.selectPage'),
        pagePrefix: t('table.pagePrefix'),
        pageSize: t('table.pageSize'),
        resetTable: t('table.resetTable'),
        reloadData: t('table.reloadData'),
        searchLabel: t('table.searchLabel'),
        searchPlaceholder: t('actions.search'),
        filterLabel: t('table.filterLabel'),
        filterDone: t('actions.done'),
    };
}