import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {useAsync} from 'react-async-hook';
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {
    AvatarEditor,
    Body,
    Breadcrumbs,
    DataTable, type Pageable,
    Tabs,
    Title,
    useFlag,
    usePagination,
    useTabs
} from '@mlyngvo/common-ui';
import {
    Alert,
    AspectRatio,
    Avatar,
    Box,
    Button,
    Chip,
    Divider,
    Grid,
    IconButton,
    Link,
    List,
    ListDivider,
    ListItem,
    ListItemButton,
    ListItemContent,
    Modal,
    ModalClose,
    ModalDialog,
    Option,
    Sheet,
    Snackbar,
    Stack, Switch,
    Typography
} from '@mui/joy';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import EuroRoundedIcon from '@mui/icons-material/EuroRounded';
import PercentRoundedIcon from '@mui/icons-material/PercentRounded';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import DeveloperBoardRoundedIcon from '@mui/icons-material/DeveloperBoardRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {type Dayjs} from 'dayjs';
import {useLocalization} from '../../context/localization';
import {displayAmount, displayDate, displayDatetime, IsoDateFormat, notBlank, notNull} from '../../utils';
import {
    type Account,
    type AccountClubRequest,
    type AccountClubSummary, type AccountRangefeeRequest,
    useAccountApi
} from '../../data/account';
import {StatusChip} from '../../component/status-chip';
import {Input} from '../../component/input';
import {Checkbox} from '../../component/checkbox';
import {useAccountGroupApi} from '../../data/account-group';
import {Select} from '../../component/select';
import {FormModal} from '../../component/form-modal';
import {type ClubOptionView, useClubApi} from '../../data/club';
import {DeviceTypeChip} from '../device/device-type-chip';
import {type DeviceListView, type DevicePortConfig, DeviceType, useDeviceApi} from '../../data/device';
import {
    type AccountActivity,
    type AccountTransaction,
    TransactionType,
} from '../../data/statistic';
import {TransactionModal} from '../statistic/transaction-modal';
import {TransactionStatusChip} from '../statistic/transaction-status-chip';
import {UserLabel} from '../../component/user-label';
import {ColoredAmount} from '../../component/colored-amount';
import {TransactionTypeChip} from '../statistic/transaction-type-chip';
import {TransactionPurposeChip} from '../statistic/transaction-purpose-chip';
import {AuthWrapper, useAuthContext} from '../../context/auth';
import {UserRole} from '../../data/user';
import {CostAdjustmentDirection, type CostAdjustmentItem, CostAdjustmentType, type DevicePermissionItem} from '../../data/common';
import {DatePicker} from '../../component/date-picker';
import { RouterLink } from '../../component/link';
import {type Wallet} from '../../data/wallet';

const TabsKey = 'account_tabs';

export function AccountDetails() {
    return (
        <AuthWrapper>
            <ContentView />
        </AuthWrapper>
    );
}

function ContentView() {
    const {id} = useParams<{id: string}>();
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role} = useAuthContext();
    const {find, setAvatar, putAccountClub, createRangefee} = useAccountApi();

    const {tab, changeTab} = useTabs(`${TabsKey}:${id}`);

    const {result: account, error, loading, execute} = useAsync(async () =>
            (id === undefined) ? undefined : await find(id)
        , [id]);

    const [club, setClub] = useState<ClubOptionView>();

    const clubOptions = useMemo(() => Object.entries(account?.clubs ?? {}).map(([k, v]) => ({ id: k, name: v.clubName })), [account?.clubs]);

    useEffect(() => {
        setClub(clubOptions.length > 0 ? clubOptions[0] : undefined);
    }, [clubOptions]);

    async function handleSetAvatar(data: string) {
        if (id !== undefined) {
            try {
                await setAvatar(id, data.slice(22));
            } catch (error_) {
                console.error('Failed to set logo', error_);
            }
        }
    }

    async function handlePutAccountClub(clubId: string, data: AccountClubRequest) {
        if (id !== undefined) {
            try {
                await putAccountClub(id, clubId, data);
                await execute();
            } catch (error_) {
                console.error('Failed to put account club', error_);
            }
        }
    }

    async function handleCreateRangefee(clubId: string, data: AccountRangefeeRequest) {
        if (id !== undefined) {
            try {
                await createRangefee(id, clubId, data);
                await execute();
            } catch (error_) {
                console.error('Failed to create account rangefee', error_);
            }
        }
    }

    const name = useMemo(() => [account?.title, [account?.lastName, account?.firstName].filter(notNull).join(', ')].join(' ').trim(), [account]);
    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('accounts.title'), onClick: () => { navigate('/accounts'); } },
                        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                        { label: account === undefined ? t('common.details') : (name || account.id) }
                    ]}
                />
            )}
            title={(
                <Title
                    title={
                        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                        account === undefined ? t('accounts.singular') : (name || account.id)
                    }
                    actions={(
                        <Button
                            color="primary"
                            startDecorator={<EditRoundedIcon />}
                            size="sm"
                            onClick={() => { navigate(`/accounts/${account?.id}/edit`); }}
                        >
                            {t('actions.edit')}
                        </Button>
                    )}
                />
            )}
            {...{ error, loading }}
        >
            <Tabs
                value={tab}
                onChange={changeTab}
                items={[
                    (<Typography key="tab-0" level="body-sm" noWrap><strong>{t('common.overview')}</strong></Typography>),
                    (<Typography key="tab-2" level="body-sm" noWrap><strong>{t('permissions.title')}</strong></Typography>),
                    (<Typography key="tab-3" level="body-sm" noWrap><strong>{t('stats.types.Transactions')}</strong></Typography>),
                    (<Typography key="tab-3" level="body-sm" noWrap><strong>{t('stats.types.Activities')}</strong></Typography>),
                ]}
                expandMx={{
                    xs: -2,
                    md: -6
                }}
            />

            {account !== undefined && (
                <Box
                    mt={2}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        overflow: 'auto',
                    }}
                >
                    {tab === 0 && (
                        <Stack
                            direction={{ xs: 'column', lg: 'row' }}
                            columnGap={3}
                            rowGap={3}
                            alignItems={{ xs: 'stretch', lg: 'flex-start' }}
                        >
                            <Stack
                                direction="column"
                                rowGap={3}
                                flexGrow={1}
                            >
                                <AccountMeta
                                    {...account}
                                    onAvatarChange={handleSetAvatar}
                                />
                                <AccountInfos {...account} name={name} />
                            </Stack>
                            <Stack
                                direction="column"
                                rowGap={3}
                                flexGrow={1}
                            >
                                <AccountClubs
                                    {...account}
                                    onAccountClubChange={handlePutAccountClub}
                                    onAccountRangefeeSubmit={handleCreateRangefee}
                                />
                                <AccountWallets {...account} />
                            </Stack>
                        </Stack>
                    )}
                    {tab === 1 && (
                        <div>
                            {Object.entries(account.permissions).map(([clubId, permissions]) => {
                                const accountClub = account.clubs[clubId];
                                const costAdjustments = account.costAdjustments[clubId];
                                return (
                                    <PermissionList
                                        key={clubId}
                                        refresh={async () => { await execute(account.id); }}
                                        {...{ accountClub, permissions, costAdjustments }}
                                    />
                                );
                            })}
                        </div>
                    )}
                    {(tab === 2 || tab === 3) && (
                        <>
                            {role === UserRole.Root && (
                                <>
                                    <Grid container spacing={2}>
                                        <Grid xs={12}>
                                            <Select
                                                label={t('clubs.singular')}
                                                options={clubOptions}
                                                renderOption={o => (
                                                    <Option key={o.id} value={o}>{o.name}</Option>
                                                )}
                                                SelectProps={{
                                                    placeholder: t('hints.select'),
                                                    value: club,
                                                    onChange: (_, value) => { setClub(value ?? undefined); },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box my={2} />
                                </>
                            )}
                            {club !== undefined && (
                                <>
                                    {tab === 2 &&
                                        <TransactionList
                                            accountId={account.id}
                                            clubId={club.id}
                                        />
                                    }
                                    {tab === 3 &&
                                        <ActivityList
                                            accountId={account.id}
                                            clubId={club.id}
                                        />
                                    }
                                </>
                            )}
                        </>
                    )}
                </Box>
            )}
        </Body>
    );
}


/**
 * Account overview
 */
type AccountMetaProperties = Pick<Account, 'id'|'avatarUrl'|'createdAt'|'clubs'> & {
    onAvatarChange: (data: string) => Promise<void>
}

function AccountMeta({id, avatarUrl, createdAt, clubs, onAvatarChange}: AccountMetaProperties) {
    const {t} = useLocalization();
    const {role} = useAuthContext();

    const [counter, setCounter] = useState(0);
    const [avatarEditorOpen, setAvatarEditorOpen, clearAvatarEditorOpen] = useFlag(false);
    const [previewUrl, setPreviewUrl] = useState(avatarUrl);

    function handleAvatar(data: string) {
        onAvatarChange(data)
            .then(() => {
                clearAvatarEditorOpen();
                setPreviewUrl(`/api/accounts/${id}/avatar`);
                setCounter(c => c + 1);
            })
            .catch(console.error);
    }

    return (
        <Stack
            direction={{ xs: 'column', sm: 'row' }}
            gap={3}
        >
            <Stack
                direction="column"
                spacing={1}
                sx={{ position: 'relative' }}
            >
                <AspectRatio
                    ratio="1"
                    maxHeight={150}
                    variant="outlined"
                    objectFit="contain"
                    sx={{
                        flex: 1,
                        minWidth: 100,
                        borderRadius: '7px',
                        bgcolor: 'common.white'
                    }}
                >
                    {notBlank(previewUrl)
                        ? (
                            <img
                                src={`${previewUrl}?s=${counter}`}
                                loading="lazy"
                                alt={id}
                            />
                        )
                        : <div><AccountBoxRoundedIcon sx={{ fontSize: '3rem', opacity: 0.5 }} /></div>
                    }
                </AspectRatio>
                <IconButton
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    sx={theme => ({
                        bgcolor: 'background.body',
                        position: 'absolute',
                        zIndex: 2,
                        borderRadius: '50%',
                        right: -7,
                        bottom: -5,
                        boxShadow: 'sm',
                        [theme.breakpoints.down('sm')]: {
                            display: 'none'
                        }
                    })}
                    onClick={setAvatarEditorOpen}
                >
                    <EditRoundedIcon />
                </IconButton>
            </Stack>
            <Sheet
                variant="outlined"
                sx={{
                    p: 2,
                    flexGrow: 1
                }}
            >
                <Stack
                    direction={{xs: 'column', sm: 'row'}}
                    alignItems={{xs: 'flex-start', sm: 'center'}}
                    height="100%"
                    rowGap={2}
                    columnGap={3}
                >
                    <div>
                        <Typography level="body-xs" gutterBottom><strong>{t('common.createdAt')}</strong></Typography>
                        {displayDatetime(createdAt)}
                    </div>
                    {role === UserRole.Root && (
                        <div>
                            <Typography level="body-xs"
                                        gutterBottom><strong>{t('accounts.clubCount')}</strong></Typography>
                            {Object.keys(clubs).length}
                        </div>
                    )}
                </Stack>
            </Sheet>
            <Modal open={avatarEditorOpen} onClose={clearAvatarEditorOpen}>
                <ModalDialog>
                    <ModalClose/>
                    <Typography level="title-lg">{t('accounts.editAvatar')}</Typography>
                    <Divider />
                    <AvatarEditor
                        defaultImageUrl={previewUrl ?? undefined}
                        onSave={handleAvatar}
                        onCancel={clearAvatarEditorOpen}
                        i18n={{
                            uploadFile: t('actions.uploadPhoto'),
                            save: t('actions.save'),
                            cancel: t('actions.cancel')
                        }}
                    />
                </ModalDialog>
            </Modal>
        </Stack>
    );
}

type AccountInfosProperties = Pick<Account, 'email'|'phone'|'address'> & { name: string }

function AccountInfos({name, email, phone, address}: AccountInfosProperties) {
    const {t} = useLocalization();

    return (
        <Sheet
            variant="outlined"
            sx={{ flex: 1 }}
        >
            <Box p={2}>
                <Typography level="title-md">{t('common.details')}</Typography>
                <Typography level="body-sm">{t('users.detailsDescription')}</Typography>
            </Box>
            <Divider />
            <Stack
                p={2}
                flexDirection="column"
                rowGap={2}
            >
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('common.name')}</strong></Typography>
                    <Typography>{name}</Typography>
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('common.address')}</strong></Typography>
                    <Typography>{address?.addressLine1}</Typography>
                    <Typography>{address?.addressLine2}</Typography>
                    <Typography>{address?.zipCode} {address?.city}</Typography>
                    <Typography>{address?.country}</Typography>
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('common.email')}</strong></Typography>
                    <Typography startDecorator={<EmailRoundedIcon fontSize="inherit"/>}><Link
                        href={`mailto:${email}`}>{email}</Link></Typography>
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('common.phone')}</strong></Typography>
                    <Typography startDecorator={<LocalPhoneRoundedIcon fontSize="inherit"/>}><Link
                        href={`tel:${phone}`}>{phone}</Link></Typography>
                </div>
            </Stack>
        </Sheet>
    );
}

type AccountClubsProperties = Pick<Account, 'clubs'> & {
    onAccountClubChange: (clubId: string, data: AccountClubRequest) => Promise<void>,
    onAccountRangefeeSubmit: (clubId: string, data: AccountRangefeeRequest) => Promise<void>,
}

function AccountClubs({clubs, onAccountClubChange, onAccountRangefeeSubmit}: AccountClubsProperties) {
    const {t} = useLocalization();


    return (
        <Sheet
            variant="outlined"
            sx={{ flex: 1 }}
        >
            <Box p={2}>
                <Typography level="title-md">{t('clubs.title')}</Typography>
                <Typography level="body-sm">{t('accounts.clubsDescription')}</Typography>
            </Box>
            <Divider />
            <Stack
                p={2}
                flexDirection="column"
                rowGap={2}
            >
                <List>
                    {Object.entries(clubs)
                        .map(([id, club], index) => (
                            <AccountClubListItem
                                key={id}
                                {...{ club, divider: index > 0 }}
                                onChange={async data => { await onAccountClubChange(club.clubId, data); }}
                                onRangefeeSubmit={async data => { await onAccountRangefeeSubmit(club.clubId, data); }}
                            />
                        ))}
                </List>
            </Stack>
        </Sheet>
    );
}

interface AccountClubEditorFormElement extends HTMLFormElement {
    readonly elements: HTMLFormControlsCollection & {
        memberNumber: HTMLInputElement;
        associationNumber: HTMLInputElement;
        enabled: HTMLInputElement;
        rangefeeEnabled: HTMLInputElement;
        customRangefeeAmount: HTMLInputElement;
    };
}

interface AccountClubListItemProperties {
    club: AccountClubSummary;
    divider: boolean;
    onChange: (data: AccountClubRequest) => Promise<void>;
    onRangefeeSubmit: (data: AccountRangefeeRequest) => Promise<void>;
}

function AccountClubListItem({club, divider, onChange, onRangefeeSubmit}: AccountClubListItemProperties) {
    const {t} = useLocalization();
    const {listOptions} = useAccountGroupApi();

    const {result: accountGroups} = useAsync(async () => await listOptions(club.clubId), [club.clubId]);

    const [accountGroupId, setAccountGroupId] = useState(club.accountGroupId);
    const [editModalOpen, setEditModalOpen, clearEditModalOpen] = useFlag(false);
    const [rangefeeModalOpen, setRangefeeModalOpen, clearRangefeeModalOpen] = useFlag(false);

    /* eslint-disable unicorn/no-null */
    const [rangefeeFrom, setRangefeeFrom] = useState<Dayjs|null>(null);
    const [rangefeeUntil, setRangefeeUntil] = useState<Dayjs|null>(null);
    /* eslint-enable unicorn/no-null */

    async function handleSubmit(event: React.FormEvent<AccountClubEditorFormElement>) {
        event.preventDefault();

        const formElements = event.currentTarget.elements;

        const data: AccountClubRequest = {
            memberNumber: formElements.memberNumber.value,
            associationNumber: formElements.associationNumber.value,
            enabled: formElements.enabled.checked,
            rangefeeEnabled: formElements.rangefeeEnabled.checked,
            customRangefeeAmount: formElements.customRangefeeAmount.valueAsNumber,
            accountGroupId
        };

        await onChange(data);
        clearEditModalOpen();
    }

    async function handleRangefeeSubmit(event: React.FormEvent) {
        event.preventDefault();

        if (notNull(rangefeeFrom) && notNull(rangefeeUntil)) {
            const data: AccountRangefeeRequest = {
                from: (rangefeeFrom as Dayjs).format(IsoDateFormat.Date),
                until: (rangefeeUntil as Dayjs).format(IsoDateFormat.Date),
            };

            await onRangefeeSubmit(data);
            clearRangefeeModalOpen();
        }
    }

    return (
        <>
            {divider && <ListDivider sx={{ mx: -2 }} />}
            <ListItem
                sx={{
                    borderRadius: 7, py: 1,
                    display: 'flex', gap: 2, alignItems: 'center'
                }}
            >
                <Avatar size="sm" src={`/api/clubs/${club.clubId}/logo`} />
                <ListItemContent>
                    <Typography level="body-sm"><strong>{club.clubName}</strong></Typography>
                    <Typography level="body-xs" gutterBottom>
                        {t('common.createdAt')}: {displayDatetime(club.createdAt)}
                    </Typography>
                    <Stack
                        direction="row"
                        columnGap={1}
                    >
                        <StatusChip enabled={club.enabled} />
                        {club.rangefeeEnabled && (
                            <Chip
                                color="success"
                                size="sm"
                                variant="outlined"
                            >
                                Rangefee {t('common.enabled')}
                            </Chip>
                        )}
                    </Stack>
                    <Box my={0.5} />
                    {notBlank(club.memberNumber) && (
                        <Typography level="body-xs" gutterBottom>
                            {t('accounts.memberNumber')} <Chip size="sm" variant="outlined">{club.memberNumber}</Chip>
                        </Typography>
                    )}
                    {notBlank(club.associationNumber) && (
                        <Typography level="body-xs" gutterBottom>
                            ÖGV/DGV Nr. <Chip size="sm" variant="outlined">{club.associationNumber}</Chip>
                        </Typography>
                    )}
                    <Box my={1} />
                    {club.rangefeeEnabled && (
                        <>
                            {notBlank(club.customRangefeeAmount) && (
                                <Typography level="body-xs" noWrap>
                                    {t('transactions.customRangefeeAmount')}: € {displayAmount(club.customRangefeeAmount as number)}
                                </Typography>
                            )}
                            {notNull(club.activeRangefee) && (
                                <Typography level="body-xs" noWrap>
                                    {t('common.active')} Rangefee: {displayDate(club.activeRangefee?.until as string)}
                                </Typography>
                            )}
                        </>
                    )}
                    <Box my={1} />
                    {notBlank(club.accountGroupId) && (
                        <Typography level="body-xs" noWrap>
                            {t('accountGroups.singular')}: <RouterLink href={`/account-groups/${club.accountGroupId}`}>{club.accountGroupName}</RouterLink>
                        </Typography>
                    )}
                </ListItemContent>
                <IconButton
                    size="sm"
                    variant="outlined"
                    color="primary"
                    onClick={setEditModalOpen}
                >
                    <EditRoundedIcon fontSize="inherit" />
                </IconButton>
                {club.rangefeeEnabled && (
                    <IconButton
                        size="sm"
                        variant="outlined"
                        color="success"
                        onClick={setRangefeeModalOpen}
                        disabled={notNull(club.activeRangefee)}
                    >
                        RF
                    </IconButton>
                )}
            </ListItem>
            <FormModal
                open={editModalOpen}
                onCancel={clearEditModalOpen}
                onSave={handleSubmit}
            >
                <Typography level="title-lg">
                    {t('actions.edit')}
                </Typography>
                <Divider />
                <Grid
                    container
                    spacing={2}
                    sx={{ py: 1 }}
                >
                    <Grid xs={12}>
                        <Typography level="body-xs"><strong>{t('clubs.singular')}</strong></Typography>
                        <Typography>{club.clubName}</Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Stack
                            direction="column"
                            rowGap={2}
                        >
                            <Select
                                emptyValue
                                emptyLabel={t('common.none')}
                                label={t('accountGroups.singular')}
                                options={accountGroups?.map(g => g.id) ?? []}
                                renderOption={o => (
                                    <Option key={o} value={o}>{accountGroups?.find(g => g.id === o)?.name ?? o}</Option>
                                )}
                                SelectProps={{
                                    placeholder: t('hints.select'),
                                    // eslint-disable-next-line unicorn/no-null
                                    value: accountGroupId ?? null,
                                    onChange: (_, value) => {
                                        setAccountGroupId(value ?? undefined);
                                    },
                                    onClear: () => { setAccountGroupId(undefined); }
                                }}
                                FormControlProps={{
                                    disabled: accountGroups?.length === 0
                                }}
                            />
                            {club.overrideGroupAttr && (
                                <Alert
                                    variant="solid"
                                    color="warning"
                                    startDecorator={<ReportProblemRoundedIcon />}
                                >
                                    {t('accounts.overrideGroupAttrActive')}
                                </Alert>
                            )}
                            {!club.overrideGroupAttr && (
                                <Alert
                                    variant="solid"
                                    color="success"
                                    startDecorator={<InfoRoundedIcon />}
                                >
                                    {t('accounts.overrideGroupAttrInactive')}
                                </Alert>
                            )}


                        </Stack>
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <Input
                            label={t('accounts.memberNumber')}
                            InputProps={{
                                name: 'memberNumber',
                                defaultValue: club.memberNumber
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <Input
                            label="ÖGV/DGV Nr."
                            InputProps={{
                                name: 'associationNumber',
                                defaultValue: club.associationNumber
                            }}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <Stack
                            direction="column"
                            rowGap={2}
                        >
                            <Checkbox
                                CheckboxProps={{
                                    label: `${t('common.enabled')}`,
                                    name: 'enabled',
                                    defaultChecked: club.enabled,
                                }}
                            />
                            <Checkbox
                                CheckboxProps={{
                                    label: `Rangefee ${t('common.active')}`,
                                    name: 'rangefeeEnabled',
                                    defaultChecked: club.rangefeeEnabled,
                                }}
                            />
                            <Input
                                label={t('transactions.customRangefeeAmount')}
                                InputProps={{
                                    name: 'customRangefeeAmount',
                                    startDecorator: '€',
                                    type: 'number',
                                    inputMode: 'numeric',
                                    defaultValue: club.customRangefeeAmount ?? '',
                                }}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </FormModal>
            <FormModal
                open={rangefeeModalOpen}
                onCancel={clearRangefeeModalOpen}
                onSave={handleRangefeeSubmit}
            >
                <Typography level="title-lg">
                    {t('sessions.rangefee.create')}
                </Typography>
                <Divider />
                <Grid
                    container
                    spacing={2}
                    sx={{ py: 1 }}
                >
                    <Grid xs={12}>
                        <Typography>{t('sessions.rangefee.createHint')}</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <DatePicker
                            label={t('common.from')}
                            FormControlProps={{
                                required: true
                            }}
                            PickerProps={{
                                value: rangefeeFrom,
                                onChange: setRangefeeFrom
                            }}
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                        <DatePicker
                            label={t('common.until')}
                            FormControlProps={{
                                required: true
                            }}
                            PickerProps={{
                                value: rangefeeUntil,
                                onChange: setRangefeeUntil
                            }}
                        />
                    </Grid>
                </Grid>
            </FormModal>
        </>
    );
}

type AccountWalletsProperties = Pick<Account, 'clubs'|'intlWallet'>

function AccountWallets({clubs, intlWallet}: AccountWalletsProperties) {
    const {t} = useLocalization();

    return (
        <Sheet
            variant="outlined"
            sx={{ flex: 1 }}
        >
            <Box p={2}>
                <Typography level="title-md">{t('wallets.title')}</Typography>
                <Typography level="body-sm">{t('accounts.walletsDescription')}</Typography>
            </Box>

            <Divider />
            <Stack
                p={2}
                flexDirection="column"
                rowGap={2}
            >
                <List>
                    {notNull(intlWallet) && (
                        <ListItem
                            component={RouterLink}
                            href={`/wallets/${(intlWallet as Wallet).id}`}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 2,
                                borderRadius: 7,
                                py: 1,
                            }}
                        >
                            <Avatar size="sm" variant="outlined"><PublicRoundedIcon /></Avatar>
                            <ListItemContent
                                component={Stack}
                                direction="column"
                            >
                                <Typography level="body-xs" gutterBottom><strong>Pin Point</strong></Typography>
                                <Typography level="body-sm" lineHeight="1em" startDecorator={<EuroRoundedIcon fontSize="inherit" />}>{displayAmount((intlWallet as Wallet).amount)}</Typography>
                            </ListItemContent>
                            <KeyboardArrowRightRoundedIcon />
                        </ListItem>
                    )}
                    {Object.entries(clubs)
                        .map(([_id, club]) => (
                            <ListItem
                                key={_id}
                                component={RouterLink}
                                href={`/wallets/${club.wallet.id}`}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 2,
                                    borderRadius: 7,
                                    py: 1,
                                }}
                            >
                                <Avatar size="sm" variant="outlined"><StorefrontRoundedIcon /></Avatar>
                                <ListItemContent
                                    component={Stack}
                                    direction="column"
                                >
                                    <Typography level="body-xs" gutterBottom><strong>{club.clubName}</strong></Typography>
                                    <Typography level="body-sm" lineHeight="1em" startDecorator={<EuroRoundedIcon fontSize="inherit" />}>{displayAmount(club.wallet.amount)}</Typography>
                                </ListItemContent>
                                <KeyboardArrowRightRoundedIcon />
                            </ListItem>
                        ))}
                </List>
            </Stack>
        </Sheet>
    );
}


/**
 * Permissions
 */
interface PermissionListProperties {
    permissions: DevicePermissionItem[];
    costAdjustments: CostAdjustmentItem[];
    refresh: () => Promise<void>;
    accountClub: AccountClubSummary;
}

function PermissionList(properties: PermissionListProperties) {
    const {
        accountClub: {
            accountId,
            clubId,
            accountGroupId,
            overrideGroupAttr,
        },
        permissions, costAdjustments,
        refresh,
    } = properties;
    const {t} = useLocalization();
    const {find} = useClubApi();
    const {list} = useDeviceApi();
    const {setPermission, setAccountCostAdjustment, setAccountAttrOverride} = useAccountApi();
    const {find: findAccountGroup} = useAccountGroupApi();

    const {result: [club, devices, accountGroup] = []} = useAsync(async () =>
        await Promise.all([
            find(clubId),
            list({ page: 0, size: 999, sort: { fields: ['name'], order: 'asc' }, filter: { clubId }}),
            notBlank(accountGroupId) ? findAccountGroup(accountGroupId as string) : undefined,
        ])
        , [clubId, accountGroupId]);

    const [permissionError, setPermissionError, clearPermissionError] = useFlag(false);

    const [costAdjustment, setCostAdjustment] = useState<CostAdjustmentItem>();

    function handlePermissionSubmit(deviceId: string, enabled: boolean) {
        setPermission(accountId, deviceId, enabled)
            .catch(() => { setPermissionError(); });
    }

    function handleCostAdjustmentChange(key: keyof CostAdjustmentItem, value: string|number) {
        if (costAdjustment !== undefined) {
            const data = (key === 'type' || key === 'direction')
                ? value
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                : Number.parseFloat((value || '0') as string);
            setCostAdjustment({
                ...costAdjustment,
                [key]: data
            });
        }
    }

    async function handleCostAdjustmentSubmit(event_: React.FormEvent) {
        event_.preventDefault();

        if (costAdjustment !== undefined) {
            await setAccountCostAdjustment(accountId, costAdjustment.deviceId, costAdjustment);
            await refresh();
            setCostAdjustment(undefined);
        }
    }

    async function handleAttributeOverrideSubmit(value: boolean) {
        try {
            await setAccountAttrOverride(accountId, clubId, value);
            await refresh();
        } catch (error) {
            console.error('Failed to set account club attribute override', error);
        }
    }

    const device = useMemo(() => devices?.content.find(d => d.id === costAdjustment?.deviceId), [costAdjustment, devices]);

    return (
        <>
            <Typography
                level="title-sm"
                startDecorator={<CorporateFareRoundedIcon sx={{ top: '-4px', position: 'relative' }} />}
            >
                <strong>{club?.name ?? clubId}</strong>
            </Typography>

            <Alert
                color="primary"
                variant="outlined"
                sx={{
                    mt: 2,
                    mb: 1
                }}
                endDecorator={(
                    <Switch
                        checked={overrideGroupAttr}
                        onChange={event_ => { handleAttributeOverrideSubmit(event_.target.checked).catch(console.error); }}
                        color={overrideGroupAttr ? 'success' : 'neutral'}
                        variant={overrideGroupAttr ? 'solid' : 'solid'}
                        startDecorator={<StatusChip {...{ enabled: overrideGroupAttr }} />}
                        slotProps={{
                            input: {
                                sx: {
                                    zIndex: 9
                                }
                            },
                        }}
                    />
                )}
            >
                <Stack
                    flexDirection="column"
                    rowGap={1}
                >
                    <Typography level="title-sm">{t('accounts.overrideAttr.title')}</Typography>
                    <Typography level="body-xs">{t('accounts.overrideAttr.hint')}</Typography>
                </Stack>
            </Alert>

            <List>
                {devices?.content.map(d => {
                    const permission = permissions.find(p => p.deviceId === d.id);
                    const adjustment = costAdjustments.find(c => c.deviceId === d.id);
                    const increment = adjustment?.direction === CostAdjustmentDirection.Markup;
                    const useGroupAttribute = !overrideGroupAttr && accountGroup !== undefined;
                    return (
                        <Fragment key={d.id}>
                            <ListItem
                                sx={theme => ({
                                    borderRadius: 5,
                                    '&:hover': {
                                        background: theme.palette.neutral.plainHoverBg
                                    }
                                })}
                            >
                                <Checkbox
                                    CheckboxProps={{
                                        defaultChecked: permission?.enabled ?? false,
                                        onChange: event_ => { handlePermissionSubmit(d.id, event_.target.checked); },
                                        disabled: useGroupAttribute
                                    }}
                                />
                                <Stack
                                    direction={{ xs: 'column', md: 'row' }}
                                    columnGap={1}
                                    rowGap={0.5}
                                >
                                    <DeviceTypeChip type={d.type} />
                                    <Typography level="body-xs">{d.name}</Typography>

                                    {[adjustment?.port0Amount, adjustment?.port1Amount, adjustment?.port2Amount, adjustment?.port3Amount].map((a, index) =>
                                        (a ?? 0) > 0
                                            ? (
                                                <Chip key={`port-${  index}`} size="sm" variant="outlined" startDecorator={`#${index}:`} color={increment ? 'warning' : 'success'}>
                                                    {increment ? '+' : '-'}{a} {adjustment?.type === 'Percentage' ? '%' : '€'}
                                                </Chip>
                                            )
                                            : <span key={`port-${  index}`} />
                                    )}
                                </Stack>

                                <Box flexGrow={1} />

                                {d.type === DeviceType.BallVendingMachine && (
                                    <IconButton
                                        size="sm"
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => {
                                            setCostAdjustment(adjustment ?? {
                                                deviceId: d.id,
                                                deviceName: d.name,
                                                deviceType: d.type,
                                                direction: 'Discount',
                                                type: 'Percentage',
                                                port0Amount: 0,
                                                port1Amount: 0,
                                                port2Amount: 0,
                                                port3Amount: 0
                                            } as CostAdjustmentItem);
                                        }}
                                        disabled={useGroupAttribute}
                                    >
                                        <PercentRoundedIcon sx={{ fontSize: '1.3rem' }} />
                                    </IconButton>
                                )}
                            </ListItem>
                        </Fragment>
                    );
                })}
            </List>
            <Box mt={2} mb={3} />

            <Snackbar
                variant="solid"
                color="danger"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={permissionError}
                onClose={clearPermissionError}
            >
                {t('permissions.setPermissionError')}
            </Snackbar>

            <FormModal
                open={costAdjustment !== undefined}
                onCancel={() => { setCostAdjustment(undefined); }}
                onSave={handleCostAdjustmentSubmit}
            >
                <Typography level="title-lg">{t('accounts.costAdjustment.title')}</Typography>
                <Divider />
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Typography level="body-sm" gutterBottom><strong>{t('devices.singular')}</strong></Typography>
                        <Typography>{costAdjustment?.deviceName}</Typography>
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <Select
                            label={t('common.direction')}
                            options={Object.values(CostAdjustmentDirection)}
                            renderOption={o => (
                                <Option key={o} value={o}>{t(`accounts.costAdjustment.directions.${o}`)}</Option>
                            )}
                            SelectProps={{
                                placeholder: t('hints.select'),
                                value: costAdjustment?.direction ?? CostAdjustmentDirection.Discount,
                                onChange: (_, value) => { handleCostAdjustmentChange('direction', value ?? CostAdjustmentDirection.Discount); },
                                renderValue: o =>
                                    o === null
                                        ? ''
                                        : t(`accounts.costAdjustment.directions.${o.value}`)
                            }}
                            FormControlProps={{
                                required: true
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <Select
                            label={t('common.type')}
                            options={Object.values(CostAdjustmentType)}
                            renderOption={o => (
                                <Option key={o} value={o}>{t(`accounts.costAdjustment.types.${o}`)}</Option>
                            )}
                            SelectProps={{
                                placeholder: t('hints.select'),
                                value: costAdjustment?.type ?? CostAdjustmentType.Percentage,
                                onChange: (_, value) => { handleCostAdjustmentChange('type', value ?? CostAdjustmentType.Percentage); },
                                renderValue: o =>
                                    o === null
                                        ? ''
                                        : t(`accounts.costAdjustment.types.${o.value}`)
                            }}
                            FormControlProps={{
                                required: true
                            }}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <Divider />
                    </Grid>
                    {[costAdjustment?.port0Amount, costAdjustment?.port1Amount, costAdjustment?.port2Amount, costAdjustment?.port3Amount].map((a, index) => {
                        const amount = device === undefined
                            ? 0
                            : (device[`port${index}` as keyof DeviceListView] as DevicePortConfig).amount ?? 0;
                        return (
                            <Grid key={`port-${  index}`} xs={12} sm={3}>
                                <div>
                                    <Typography level="body-xs" gutterBottom><strong>{t('devices.basePrice')}</strong></Typography>
                                    € {displayAmount(amount)}
                                </div>
                                <Box my={2} />
                                <Input
                                    label={t('common.value')}
                                    InputProps={{
                                        startDecorator: costAdjustment?.type === CostAdjustmentType.FixedAmount ? '€' : undefined,
                                        endDecorator: costAdjustment?.type === CostAdjustmentType.Percentage ? '%' : undefined,
                                        type: 'number',
                                        inputMode: 'numeric',
                                        value: a,
                                        onChange: event_ => {
                                            handleCostAdjustmentChange(`port${index}Amount` as keyof CostAdjustmentItem, event_.target.value);
                                        }
                                    }}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </FormModal>
        </>
    );

}


/**
 * Transactions
 */
function TransactionList({accountId, clubId}: { accountId: string, clubId: string }) {
    const {t} = useLocalization();
    const {listAccountClubTransactions} = useAccountApi();

    const pagination = usePagination({
        paginationKey: 'inMemory',
        inMemory: true,
        fetch: async (pageable: Pageable<AccountTransaction>) => await listAccountClubTransactions(accountId, clubId, pageable)
    });

    useEffect(() =>
        {
            pagination.onReload()
                .catch(console.error);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [clubId]);

    const [transaction, setTransaction] = useState<AccountTransaction>();

    return (
        <>
            <DataTable
                { ...pagination }
                searchDisabled
                headers={[
                    { label: t('common.timestamp'), width: 160 },
                    { label: t('common.type'), width: 120 },
                    { label: t('transactions.purpose'), width: 160 },
                    { label: t('transactions.clerk'), width: 100 },
                    { label: t('common.amount'), width: 120 },
                ]}
                renderTableRow={at => (
                    <TransactionTableRow
                        key={at.uuid}
                        transaction={at}
                        onClick={() => { setTransaction(at); }}
                    />
                )}
                renderListRow={at => (
                    <TransactionListRow
                        key={at.uuid}
                        transaction={at}
                        onClick={() => { setTransaction(at); }}
                    />
                )}
            />
            {transaction !== undefined && <TransactionModal transaction={transaction} onClose={() => { setTransaction(undefined); }} />}
        </>
    );
}

function TransactionTableRow({transaction: {timestamp, type, clearedAt, note, data, clerkName}, onClick}: { transaction: AccountTransaction, onClick: () => void }) {
    const isNegative = type === TransactionType.SubtractCredit || type === TransactionType.ConsumeCredit;
    return (
        <tr style={{cursor: 'pointer'}} onClick={onClick}>
            <td>
                <Typography level="body-xs" gutterBottom>{displayDatetime(timestamp)}</Typography>
                <TransactionStatusChip clearedAt={clearedAt}/>
            </td>
            <td>
                <TransactionTypeChip type={type}/>
            </td>
            <td>
                <Typography level="body-xs" gutterBottom>{note}</Typography>
                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                {notBlank(data.purpose) && <TransactionPurposeChip purpose={data.purpose!}/>}
            </td>
            <td>
                {typeof clerkName === 'string' && <UserLabel label={clerkName} noWrap/>}
            </td>
            <td>
                <ColoredAmount amount={data.amount} negative={isNegative} disabled={!notBlank(clearedAt)}/>
            </td>
        </tr>
    );
}

function TransactionListRow({transaction: {timestamp, type, clearedAt, note, data, clerkName}, onClick}: { transaction: AccountTransaction, onClick: () => void }) {
    return (
        <List
            size="sm"
            sx={{
                '--ListItem-paddingX': 0,
            }}
        >
            <ListItemButton
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    '&:hover': {
                        background: 'none !important'
                    }
                }}
                onClick={onClick}
            >
                <ListItemContent>
                    <Typography level="body-xs">{displayDatetime(timestamp)}</Typography>
                    <Typography fontWeight={600} gutterBottom sx={{ mt: 0.5 }}>{note}</Typography>
                    <TransactionTypeChip type={type}/>
                    {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                    {notBlank(data.purpose) && <TransactionPurposeChip purpose={data.purpose!}/>}
                    {typeof clerkName === 'string' && <UserLabel label={clerkName} noWrap/>}
                </ListItemContent>
                <TransactionStatusChip clearedAt={clearedAt}/>
            </ListItemButton>
            <ListDivider />
        </List>
    );
}


/**
 * Activities
 */
function ActivityList({accountId, clubId}: {accountId: string, clubId: string}) {
    const {t} = useLocalization();
    const {listAccountClubActivities} = useAccountApi();

    const pagination = usePagination({
        paginationKey: 'inMemory',
        inMemory: true,
        fetch: async (pageable: Pageable<AccountActivity>) => await listAccountClubActivities(accountId, clubId, pageable)
    });

    useEffect(() => {
            pagination.onReload()
                .catch(console.error);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [clubId]);

    return (
        <DataTable
            { ...pagination }
            searchDisabled
            headers={[
                { label: t('common.timestamp'), width: 160 },
                { label: t('common.type'), width: 120 },
                { label: t('devices.singular'), width: 140 },
            ]}
            renderTableRow={activity => <ActivityTableRow key={activity.id} {...{activity}} />}
            renderListRow={activity => <ActivityListRow key={activity.id} {...{activity}} />}
        />
    );
}

function ActivityTableRow({activity: {timestamp, type, deviceName}}: { activity: AccountActivity }) {
    return (
        <tr>
            <td>
                <Typography level="body-xs">{displayDatetime(timestamp)}</Typography>
            </td>
            <td>
                <Typography level="body-xs">{type}</Typography>
            </td>
            <td>
                {notBlank(deviceName) && (
                    <Typography
                        level="body-xs"
                        startDecorator={<DeveloperBoardRoundedIcon/>}
                    >
                        {deviceName}
                    </Typography>
                )}
            </td>
        </tr>
    );
}

function ActivityListRow({activity: {timestamp, type, deviceName}}: { activity: AccountActivity }) {
    return (
        <List
            size="sm"
            sx={{
                '--ListItem-paddingX': 0,
            }}
        >
            <ListItem
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    '&:hover': {
                        background: 'none !important'
                    }
                }}
            >
                <ListItemContent>
                    <Typography level="body-xs">{displayDatetime(timestamp)}</Typography>
                    <Typography fontWeight={600} gutterBottom sx={{ mt: 0.5 }}>{type}</Typography>
                    {notBlank(deviceName) && (
                        <Typography
                            level="body-xs"
                            startDecorator={<DeveloperBoardRoundedIcon/>}
                        >
                            {deviceName}
                        </Typography>
                    )}
                </ListItemContent>
            </ListItem>
            <ListDivider />
        </List>
    );
}
