import React from 'react';
import {Chip} from '@mui/joy';
import {useLocalization} from '../context/localization';

export function StatusChip({enabled, trueLabel, falseLabel}: { enabled: boolean, trueLabel?: string, falseLabel?: string }) {
    const {t} = useLocalization();
    return (
        <Chip
            color={enabled ? 'success' : 'danger'}
            size="sm"
            variant="solid"
        >
            {enabled ? (trueLabel ?? t('common.enabled')) : (falseLabel ?? t('common.disabled'))}
        </Chip>
    );
}