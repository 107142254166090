import React, {type ReactElement} from 'react';
import {Chip, type ChipProps, useTheme} from '@mui/joy';
import {type SxProps} from '@mui/joy/styles/types';
import GolfCourseRoundedIcon from '@mui/icons-material/GolfCourseRounded';
import LocalParkingRoundedIcon from '@mui/icons-material/LocalParkingRounded';
import ScatterPlotRoundedIcon from '@mui/icons-material/ScatterPlotRounded';
import SailingRoundedIcon from '@mui/icons-material/SailingRounded';
import SportsTennisRoundedIcon from '@mui/icons-material/SportsTennisRounded';
import FmdBadRoundedIcon from '@mui/icons-material/FmdBadRounded';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import {useLocalization} from '../../context/localization';
import {ClubType} from '../../data/club';

export function ClubTypeChip({type, size = 'sm', ...properties}: ChipProps & { type: ClubType }) {
    const theme = useTheme();
    const {t} = useLocalization();
    const label = t(`clubs.types.${type}`);
    let icon: ReactElement = <QuestionMarkRoundedIcon />;
    let sx: SxProps = {
        backgroundColor: undefined,
        color: 'white'
    };
    switch (type) {
        case ClubType.GolfClub:
        case ClubType.GolfCentral:
        case ClubType.GolfResort:
        case ClubType.GolfClubCleaning: {
            sx.backgroundColor = '#65A30D';
            icon = <GolfCourseRoundedIcon />;
            break;
        }
        case ClubType.ParkingHouse:
        case ClubType.ParkingZone: {
            sx.backgroundColor = '#3949AB';
            icon = <LocalParkingRoundedIcon />;
            break;
        }
        case ClubType.BilliardClub: {
            sx.backgroundColor = '#FFA000';
            icon = <ScatterPlotRoundedIcon />;
            break;
        }
        case ClubType.SailingClub: {
            sx.backgroundColor = '#0091EA';
            icon = <SailingRoundedIcon />;
            break;
        }
        case ClubType.TennisClub: {
            sx.backgroundColor = '#009688';
            icon = <SportsTennisRoundedIcon />;
            break;
        }
        case ClubType.TestClub: {
            sx.backgroundColor = '#4B5563';
            icon = <FmdBadRoundedIcon />;
            break;
        }
        default: {
            break;
        }
    }
    sx = {
        ...sx,
        '& .MuiChip-endDecorator svg': {
            fontSize: theme.fontSize.md
        }
    };
    return (
        <Chip
            {...properties}
            sx={sx}
            size={size}
            endDecorator={icon}
        >
            {label}
        </Chip>
    );
}