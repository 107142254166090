import {Box, List, ListDivider, ListItemButton, ListItemContent, Typography} from '@mui/joy';
import {PlainTable} from '@mlyngvo/common-ui';
import DeveloperBoardRoundedIcon from '@mui/icons-material/DeveloperBoardRounded';
import React from 'react';
import type {AccountActivity} from '../../data/statistic';
import {useLocalization} from '../../context/localization';
import {displayDatetime, notBlank} from '../../utils';
import {ClubLabel} from '../../component/club-label';

export function RecentActivities({activities}: { activities: AccountActivity[] }) {
    const {t} = useLocalization();

    return (
        <Box sx={{ maxHeight: { xs: 'none', sm: 500 }, display: 'flex', flexDirection: 'column', rowGap: 1 }}>
            <Typography level="title-md"><strong>{t('stats.recentActivities')}</strong></Typography>
            <PlainTable
                items={activities}
                loading={false}
                error={undefined}
                headers={[
                    { label: t('common.timestamp'), width: 160 },
                    { label: t('accounts.singular'), width: 140 },
                    { label: t('common.type'), width: 120 },
                    { label: t('devices.singular'), width: 140 },
                    { label: t('clubs.singular'), width: 120 },
                ]}
                renderTableRow={activity => <ActivityTableRow key={activity.id} {...{ activity }} />}
                renderListRow={activity => <ActivityListRow key={activity.id} {...{ activity }} />}
            />
        </Box>
    );
}

function ActivityTableRow({activity: {timestamp, type, accountName, deviceName, clubName, clubId}}: { activity: AccountActivity }) {
    const {t} = useLocalization();
    return (
        <tr>
            <td>
                <Typography level="body-xs" gutterBottom>{displayDatetime(timestamp)}</Typography>
            </td>
            <td>
                <Typography level="body-xs">{accountName}</Typography>
            </td>
            <td>
                <Typography level="body-xs">{t(`activities.types.${type}`)}</Typography>
            </td>
            <td>
                {notBlank(deviceName) && (
                    <Typography
                        level="body-xs"
                        startDecorator={<DeveloperBoardRoundedIcon/>}
                    >
                        {deviceName}
                    </Typography>
                )}
            </td>
            <td>
                {notBlank(clubName) && notBlank(clubId) && <ClubLabel label={clubName as string} clubId={clubId as string} noWrap />}
            </td>
        </tr>
    );
}

function ActivityListRow({activity: {timestamp, type, accountName, deviceName, clubName, clubId}}: { activity: AccountActivity }) {
    const {t} = useLocalization();
    return (
        <List
            size="sm"
            sx={{
                '--ListItem-paddingX': 0,
            }}
        >
            <ListItemButton
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    '&:hover': {
                        background: 'none !important'
                    }
                }}
            >
                <ListItemContent>
                    <Typography level="body-xs">{displayDatetime(timestamp)}</Typography>
                    <Typography fontWeight={600} gutterBottom sx={{ mt: 0.5 }}>{t(`activities.types.${type}`)}</Typography>
                    <Box my={1} />
                    <Typography level="body-sm">{accountName}</Typography>
                    {notBlank(deviceName) && (
                        <Typography
                            level="body-xs"
                            startDecorator={<DeveloperBoardRoundedIcon/>}
                        >
                            {deviceName}
                        </Typography>
                    )}
                    {notBlank(clubName) && notBlank(clubId) && <ClubLabel label={clubName as string} clubId={clubId as string} noWrap />}
                </ListItemContent>
            </ListItemButton>
            <ListDivider />
        </List>
    );
}