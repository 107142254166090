import React from 'react';
import {Card, CardContent, Typography} from '@mui/joy';

export function AccountConfirm() {

    return (
        <Card>
            <Typography level="title-lg">Account registration confirmed</Typography>
            <CardContent>
                <Typography>Thank you for confirming your account registration. You can now close this page.</Typography>
            </CardContent>
        </Card>
    );
}