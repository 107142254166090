import dayjs from 'dayjs';
import React from 'react';
import {StatusChip} from '../../component/status-chip';
import {useLocalization} from '../../context/localization';
import {notBlank} from '../../utils';

export function AccountGroupExpirationChip({expirationDate}: { expirationDate?: string }) {
    const {t} = useLocalization();

    return (
        <StatusChip
            enabled={!notBlank(expirationDate) || dayjs(expirationDate).isAfter(dayjs())}
            trueLabel={t('common.active')}
            falseLabel={t('common.expired')}
        />
    );
}