import {createQueryString, useApi} from './api';
import {type WalletType} from './wallet';
import {type Address} from './common';

export enum TransactionDirection {
    BookIn = 'BookIn',
    BookOut = 'BookOut'
}

export enum TransactionPurpose {
    Bvm = 'Bvm',
    InAppPayment = 'InAppPayment',
    Rangefee = 'Rangefee',
}

export enum TransactionType {
    PurchaseCredit = 'PurchaseCredit',
    ReceiveCredit = 'ReceiveCredit',
    SubtractCredit = 'SubtractCredit',
    ConsumeCredit = 'ConsumeCredit'
}

interface TransactionData {
    id: string;
    stripeId?: string;
    purpose?: TransactionPurpose;
    amount: number;
    portName?: string;
    value?: number;
    ballQuantity?: number;
}

interface TransactionBilling {
    firstName: string;
    lastName: string;
    address?: Address;
}

export interface AccountTransaction {
    uuid: string;
    accountId: string;
    accountName: string;
    walletId: string;
    walletType: WalletType;
    clubId?: string;
    clubName?: string;
    clerkId?: string;
    clerkName?: string;
    timestamp: string;
    type: TransactionType;
    note?: string;
    deviceId?: string;
    deviceName?: string;
    previousAmount: number;
    newAmount: number;
    clearedAt?: string;
    data: TransactionData;
    billing: TransactionBilling;
}

export interface TransactionSummary {
    totalSum: number;
    clubSum: number;
    intlSum: number;
}

export interface TransactionStatistic {
    record: AccountTransaction[];
    bookIn: TransactionSummary;
    bookOut: TransactionSummary;
    totalBallQty: number;
    serviceFeePercentage: number;
    serviceFee: number;
    payout: number;
}

export interface TransactionsStatisticFilter {
    clubId: string;
    from: string;
    until: string;
    deviceId?: string;
    accountId?: string;
    direction?: TransactionDirection;
    purpose?: TransactionPurpose;
    notes?: string[];
    hideBlankAmount?: boolean;
    hideIncomplete?: boolean;
}

export interface RangefeeSession {
    uuid: string;
    from: string;
    until: string;
    accountId: string;
    accountName: string;
    clubId: string;
    clubName: string;
}

export enum ActivityType {
    Registration = 'Registration',
    Login = 'Login',
    Logout = 'Logout',
    ClubRegistration = 'ClubRegistration',
    OpenEntrance = 'OpenEntrance',
    BvmTransaction = 'BvmTransaction',
    RangefeeTransaction = 'RangefeeTransaction',
    InAppPaymentTransaction = 'InAppPaymentTransaction',
}

export interface AccountActivity {
    id: string;
    accountId: string;
    accountName: string;
    clubId?: string;
    clubName?: string;
    timestamp: string;
    type: ActivityType;
    deviceId?: string;
    deviceName?: string;
    accountTransaction?: AccountTransaction;
    rangefeeSession?: RangefeeSession;
}

export interface ActivityStatistic {
    record: AccountActivity[];
}

export interface ActivitiesStatisticFilter {
    clubId: string;
    from: string;
    until: string;
    deviceId?: string;
    accountId?: string;
    type?: ActivityType;
}

export interface ClubKpiStatistic {
    clubRegistrationsCount: number;
    totalClubWalletBalance: number;
    transactionsCount: number;
    totalClubBookInAmount: number;
    totalClubBookOutAmount: number;
    bvmDeviceCount: number;
    entranceDeviceCount: number;
    recentTransactions: AccountTransaction[];
    recentActivities: AccountActivity[];
}

export interface KpiStatistic {
    clubsCount: number;
    clubRegistrationsCount: number;
    accountsCount: number;
    totalClubWalletBalance: number;
    totalInternationWalletBalance: number;
    transactionsCount: number;
    totalClubBookInAmount: number;
    totalInternationalBookInAmount: number;
    totalClubBookOutAmount: number;
    totalInternationalBookOutAmount: number;
    bvmDeviceCount: number;
    entranceDeviceCount: number;
    recentTransactions: AccountTransaction[];
    recentActivities: AccountActivity[];
}

export function useStatisticApi() {
    const {get} = useApi();

    return {
        computeTransactions: async ({clubId, notes, ...rest}: TransactionsStatisticFilter) => {
            let query = createQueryString(rest);
            if (notes !== undefined) query += `&${  notes.map(n => `notes=${n}`).join('&')}`;
            return await get<TransactionStatistic>(`/center/statistics/${clubId}/transactions?${query}`);
        },
        computeActivities: async ({clubId, ...rest}: ActivitiesStatisticFilter) =>
            await get<ActivityStatistic>(`/center/statistics/${clubId}/activities?${createQueryString(rest)}`),
        computeNotes: async (clubId: string) =>
            await get<string[]>(`/center/statistics/${clubId}/notes`),
        computeClubKpis: async (clubId: string) =>
            await get<ClubKpiStatistic>(`/center/statistics/${clubId}/kpis`),
        computeKpis: async () =>
            await get<KpiStatistic>('/center/statistics/kpis'),
    };
}