import React, {type FormEvent, useState} from 'react';
import {utils, writeFile} from 'xlsx';
import {Divider, Grid, Typography} from '@mui/joy';
import {useLocalization} from '../../context/localization';
import {useAuthContext} from '../../context/auth';
import {type Club, useClubApi} from '../../data/club';
import {formatExcelAmount, formatExcelDateTime} from '../../utils';
import {useWalletApi, WalletFilterState, WalletType} from '../../data/wallet';
import {computeColumnLengths, formatCellAsNumber} from '../../excels';
import {FormModal} from '../../component/form-modal';
import {UserRole} from '../../data/user';
import {ClubSelect} from '../../component/club-select';

export function WalletExportModal({open, onCancel, intlWallets}: { open: boolean, onCancel: () => void, intlWallets: boolean }) {
    const {t} = useLocalization();
    const {role, clubId: authClubId} = useAuthContext();
    const {find} = useClubApi();
    const {list} = useWalletApi();

    const [clubId, setClubId] = useState(authClubId);

    async function exportToExcel(event_: FormEvent) {
        event_.preventDefault();

        let club: Club | undefined;
        if (!intlWallets) {
            if (clubId === undefined) {
                return;
            }

            try {
                club = await find(clubId);
            } catch (error) {
                console.error('Failed to fetch club', error);
                return;
            }

            if (club === undefined) {
                console.error('Empty data return.');
                return;
            }
        }

        const workbook = utils.book_new();

        const worksheet = utils.json_to_sheet([]);
        utils.book_append_sheet(workbook, worksheet, '');

        utils.sheet_add_aoa(worksheet, [[`${t('wallets.title')}`]], { origin: 'A1' });
        if (intlWallets) {
            utils.sheet_add_aoa(worksheet, [[`${t('wallets.types.International')}`]], { origin: 'A2' });
        }
        else {
            utils.sheet_add_aoa(worksheet, [[`${t('clubs.singular')}: ${  club?.name}`]], { origin: 'A2' });
        }

        const headers = [
            '#',
            t('common.name'),
            t('common.createdAt'),
            `${t('common.balance')} (${intlWallets ? t('wallets.types.International') : t('wallets.types.Club')})`,
        ];

        let rows: Array<Record<string, string|number>> = [];
        let page = 0;
        let totalPage = 1;
        try {
            do {
                // eslint-disable-next-line no-await-in-loop
                const data = await list({
                    page,
                    size: 100,
                    filter: {
                        state: WalletFilterState.ActiveBalance,
                        clubId: club?.id,
                    }
                }, intlWallets ? WalletType.International : WalletType.Club);
                if (data === undefined) {
                    console.error('Failed to fetch data.');
                    return;
                }

                rows.push(...data.content.map((w, index) => {
                    const {
                        accountName,
                        amount,
                        createdAt,
                    } = w;
                    return {
                        name: accountName ?? '',
                        createdAt: formatExcelDateTime(createdAt),
                        balance: formatExcelAmount(amount),
                        amount,
                    };
                }));

                totalPage = data.totalPages;
                page += 1;
            } while (page < totalPage);
        } catch (error) {
            console.error('Failed to get data', error);
        }

        rows = rows
            .sort((a, b) => (b.amount as number) - (a.amount as number))
            .map(({amount, ...rest}, index) => ({ index: (index + 1), ...rest }) as any);

        utils.sheet_add_json(worksheet, rows, { origin: 'A5' });
        utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });

        formatCellAsNumber(worksheet, 5, 0, rows.length);
        formatCellAsNumber(worksheet, 5, 3, rows.length, '0.00');

        worksheet['!cols'] = computeColumnLengths(rows);

        writeFile(workbook, `${t('wallets.title')} - ${intlWallets ? t('wallets.types.International') : club?.name}.xlsx`, { compression: true });

        onCancel();
    }

    return (
        <FormModal
            open={open}
            onCancel={onCancel}
            onSave={exportToExcel}
            saveLabel={t('actions.export')}
            successMessage={t('hints.dataExported')}
        >
            <Typography level="title-lg">{t('actions.export')}</Typography>
            <Divider />
            <Grid container spacing={2}>
                <Grid xs={12}>
                    {(role === UserRole.Root && !intlWallets) && (
                        <ClubSelect
                            required
                            defaultClubId={clubId}
                            onChange={setClubId}
                        />
                    )}
                    {role !== UserRole.Root || intlWallets && (
                        <Typography>Ready to export.</Typography>
                    )}
                </Grid>
            </Grid>
        </FormModal>
    );
}