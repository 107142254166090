import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {useAsync} from 'react-async-hook';
import {Body, Breadcrumbs, Title, useFlag} from '@mlyngvo/common-ui';
import {
    Avatar,
    Box,
    Button,
    Divider,
    List,
    ListItemButton,
    ListItemContent,
    Sheet,
    Stack,
    Typography
} from '@mui/joy';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import React, {type FormEvent, useState} from 'react';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import type {Dayjs} from 'dayjs';
import {ClubTypeChip} from '../club';
import {useNotificationApi, type Notification} from '../../data/notification';
import {useLocalization} from '../../context/localization';
import {useClubApi} from '../../data/club';
import {displayDate, notBlank} from '../../utils';
import {FormModal} from '../../component/form-modal';
import {DateTimePicker} from '../../component/date-time-picker';
import {useAuthContext} from '../../context/auth';
import {UserRole} from '../../data/user';

export function NotificationDetails() {
    const {id} = useParams<{id: string}>();
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role} = useAuthContext();
    const {find} = useNotificationApi();

    const {result: notification, error, loading, execute} = useAsync(async () =>
            (id === undefined) ? undefined : await find(id)
        , [id]);

    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('notifications.title'), onClick: () => { navigate('/notifications'); } },
                        { label: notification === undefined ? t('common.details') : notification.title }
                    ]}
                />
            )}
            title={(
                <Title
                    title={
                        notification === undefined ? t('notifications.singular') : notification.title
                    }
                    actions={(
                        <Button
                            color="primary"
                            startDecorator={<EditRoundedIcon />}
                            size="sm"
                            onClick={() => { navigate(`/notifications/${notification?.id}/edit`); }}
                            disabled={role === UserRole.User}
                        >
                            {t('actions.edit')}
                        </Button>
                    )}
                />
            )}
            {...{ error, loading }}
        >
            {notification !== undefined && (
                <Box
                    mt={2}
                >
                    <Stack
                        direction={{ xs: 'column', lg: 'row' }}
                        columnGap={3}
                        rowGap={3}
                    >
                        <Stack
                            direction="column"
                            flexGrow={1}
                        >
                            <NotificationInfos {...notification} />
                        </Stack>
                        <Stack
                            direction="column"
                            sx={{
                                minWidth: '30%'
                            }}
                        >
                            <NotificationSettings
                                refresh={async () => { await execute(id); }}
                                {...notification}
                            />
                        </Stack>
                    </Stack>
                </Box>
            )}
        </Body>
    );
}

type NotificationInfosProperties = Pick<Notification, 'title'|'content'|'clubId'>

function NotificationInfos({title, content, clubId}: NotificationInfosProperties) {
    const {t} = useLocalization();
    const navigate = useNavigate();
    const {find} = useClubApi();

    const {result: club} = useAsync(async () => await find(clubId), [clubId]);

    return (
        <Sheet
            variant="outlined"
            sx={{ flex: 1 }}
        >
            <Box p={2}>
                <Typography level="title-md">{t('common.details')}</Typography>
                <Typography level="body-sm">{t('notifications.detailsDescription')}</Typography>
            </Box>
            <Divider />
            <Stack
                p={2}
                flexDirection="column"
                rowGap={2}
            >
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('common.title')}</strong></Typography>
                    <Typography>{title}</Typography>
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('notifications.content')}</strong></Typography>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{
                        __html: content.replaceAll('\n', '<br>')
                    }} />
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('clubs.singular')}</strong></Typography>
                    <List>
                        {club !== undefined && (
                            <ListItemButton
                                sx={{
                                    borderRadius: 7, py: 1,
                                    display: 'flex', gap: 2, alignItems: 'center'
                                }}
                                onClick={() => {
                                    navigate(`/clubs/${club.id}`);
                                }}
                            >
                                <Avatar size="sm" src={club.logoUrl}/>
                                <ListItemContent>
                                    <Typography level="body-xs">{club.name}</Typography>
                                    <ClubTypeChip type={club.type}/>
                                </ListItemContent>
                                <KeyboardArrowRightRoundedIcon/>
                            </ListItemButton>
                        )}
                    </List>
                </div>
            </Stack>
        </Sheet>
    );
}

type NotificationSettingsProperties = Pick<Notification, 'id'|'createdAt'|'publishedAt'> & { refresh: () => Promise<void>}

function NotificationSettings({id, createdAt, publishedAt, refresh}: NotificationSettingsProperties) {
    const {t} = useLocalization();
    const {role} = useAuthContext();
    const {publish} = useNotificationApi();

    const [publishMode, setPublishMode, clearPublishMode] = useFlag(false);

    // eslint-disable-next-line unicorn/no-null
    const [publishDate, setPublishDate] = useState<Dayjs|null>(null);

    async function handleSubmit(event_: FormEvent) {
        event_.preventDefault();

        if (publishDate !== null) {
            await publish(id, publishDate.toISOString());
            await refresh();
            clearPublishMode();
        }
    }

    return (
        <>
            <Sheet
                variant="outlined"
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Box p={2}>
                    <Typography level="title-md">{t('common.settings')}</Typography>
                    <Typography level="body-sm">{t('notifications.settingsDescription')}</Typography>
                </Box>
                <Divider />
                <Stack
                    p={2}
                    flexDirection="column"
                    rowGap={2}
                    flexGrow={1}
                >
                    <div>
                        <Typography level="body-xs" gutterBottom><strong>{t('common.createdAt')}</strong></Typography>
                        {displayDate(createdAt)}
                    </div>
                    <div>
                        <Typography level="body-xs" gutterBottom><strong>{t('common.publishedAt')}</strong></Typography>
                        {notBlank(publishedAt) ? displayDate(publishedAt ?? '') : '-'}
                    </div>
                    <Box flexGrow={1} />
                    <Button
                        onClick={setPublishMode}
                        disabled={notBlank(publishedAt) || role === UserRole.User}
                    >
                        {t('actions.publish')}
                    </Button>
                </Stack>
            </Sheet>
            <FormModal
                open={publishMode}
                onCancel={clearPublishMode}
                onSave={handleSubmit}
            >
                <Typography level="title-lg">
                    {t('actions.publish')}
                </Typography>
                <Divider />
                <DateTimePicker
                    label={t('common.timestamp')}
                    PickerProps={{
                        value: publishDate,
                        onChange: setPublishDate
                    }}
                />
            </FormModal>
        </>
    );
}