import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {useAsync} from 'react-async-hook';
import React, {useEffect, useMemo, useState} from 'react';
import {Body, Breadcrumbs, Title, useFlag} from '@mlyngvo/common-ui';
import {Box, Button, Divider, Grid, Option, Sheet, Stack, Typography} from '@mui/joy';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {type UserRequest, UserRole, useUserApi} from '../../data/user';
import {useLocalization} from '../../context/localization';
import {notNull} from '../../utils';
import {Input} from '../../component/input';
import {Select} from '../../component/select';
import {UserRoleChip} from './user-role-chip';
import {ConfirmDialog} from '../../component/confirm-dialog';
import {AuthWrapper} from '../../context/auth';

export function UserEditor() {
    return (
        <AuthWrapper allowRoles={[ UserRole.Root, UserRole.Admin ]}>
            <ContentView />
        </AuthWrapper>
    );
}

interface FormElements extends HTMLFormControlsCollection {
    title: HTMLInputElement;
    firstName: HTMLInputElement;
    lastName: HTMLInputElement;
    email: HTMLInputElement;
    phone: HTMLInputElement;
    position: HTMLInputElement;
}

interface UserEditorFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

function ContentView() {
    const {id } = useParams<{id: string}>();
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {find, put, deleteUser} = useUserApi();

    const {result: user, error: loadError, loading} = useAsync(async () =>
            (id === undefined) ? undefined : await find(id)
        , [id]);

    const [role, setRole] = useState<UserRole>();
    const [deletionPromptOpen, setDeletionPromptOpen, clearDeletionPromptOpen] = useFlag(false);
    const [error, setError] = useState<Error>();

    useEffect(() =>
        {
            if (user?.role !== undefined && user.role !== role) setRole(user.role);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user?.role]);

    function handleFormSubmit(event: React.FormEvent<UserEditorFormElement>) {
        event.preventDefault();

        if (user !== undefined) {
            const formElements = event.currentTarget.elements;

            const data: UserRequest = {
                email: formElements.email.value,
                role: role ?? user.role,
                title: formElements.title.value,
                firstName: formElements.firstName.value,
                lastName: formElements.lastName.value,
                phone: formElements.phone.value,
                position: formElements.position.value,
            };

            put(user.id, data)
                .then(() => { navigate(`/users/${user.id}`); })
                .catch(error_ => {
                    console.error('Failed to put user data', error_);
                    setError(new Error('Failed to update.'));
                });
        }
    }

    function handleDelete() {
        if (user !== undefined) {
            deleteUser(user.id)
                .then(() => { navigate('/users'); })
                .catch(setError);
        }
    }

    const name = useMemo(() => [user?.lastName, user?.firstName].filter(notNull).join(', '), [user]);
    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('users.title'), onClick: () => { navigate('/users'); } },
                        { label: user === undefined ? t('common.details') : name, onClick: () => { navigate(`/users/${id}`); } },
                        { label: t('actions.edit') }
                    ]}
                />
            )}
            title={(
                <Title
                    title={user === undefined ? t('users.singular') : name}
                    actions={(
                        <Button
                            variant="outlined"
                            color="neutral"
                            startDecorator={<ArrowBackIosRoundedIcon />}
                            size="sm"
                            onClick={() => { navigate(`/users/${user?.id}`); }}
                        >
                            {t('actions.back')}
                        </Button>
                    )}
                />
            )}
            loading={loading}
            error={error ?? loadError}
        >
            {user !== undefined && (
                <>
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={3}>
                            <Grid xs={12} lg={8}>
                                <Sheet
                                    variant="outlined"
                                    sx={{
                                        p: 2
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid xs={12}>
                                            <Typography level="title-lg">{t('common.details')}</Typography>
                                        </Grid>
                                        <Grid xs={12} md={12} xl={4}>
                                            <Input
                                                label={t('common.title')}
                                                InputProps={{
                                                    name: 'title',
                                                    defaultValue: user.title
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={12} md={12} xl={4}>
                                            <Input
                                                label={t('common.firstName')}
                                                InputProps={{
                                                    name: 'firstName',
                                                    defaultValue: user.firstName
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={12} md={12} xl={4}>
                                            <Input
                                                label={t('common.lastName')}
                                                InputProps={{
                                                    name: 'lastName',
                                                    defaultValue: user.lastName
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={12}>
                                            <Input
                                                label={t('users.position')}
                                                InputProps={{
                                                    name: 'position',
                                                    defaultValue: user.position
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{my: 3, mx: -2}}/>
                                    <Grid container spacing={2}>
                                        <Grid xs={12}>
                                            <Typography level="title-lg">{t('common.contact')}</Typography>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Input
                                                label={t('common.email')}
                                                InputProps={{
                                                    startDecorator: <EmailRoundedIcon fontSize="inherit"/>,
                                                    type: 'email',
                                                    inputMode: 'email',
                                                    name: 'email',
                                                    defaultValue: user.email
                                                }}
                                                FormControlProps={{
                                                    required: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={12}>
                                            <Input
                                                label={t('common.phone')}
                                                InputProps={{
                                                    startDecorator: <LocalPhoneRoundedIcon fontSize="inherit"/>,
                                                    name: 'phone',
                                                    defaultValue: user.phone
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Sheet>
                            </Grid>
                            <Grid xs={12} lg={4}>
                                <Sheet
                                    variant="outlined"
                                    sx={{
                                        p: 2
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid xs={12}>
                                            <Typography level="title-lg">{t('common.settings')}</Typography>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Select
                                                label={t('users.role')}
                                                options={Object.values(UserRole)}
                                                renderOption={o => (
                                                    <Option key={o} value={o}><UserRoleChip role={o}/></Option>
                                                )}
                                                SelectProps={{
                                                    placeholder: t('hints.select'),
                                                    value: role,
                                                    onClear: () => { setRole(undefined); },
                                                    onChange: (_, value) => { setRole(value ?? undefined); },
                                                    renderValue: o =>
                                                        o === null
                                                            ? ''
                                                            : <UserRoleChip role={o.value}/>
                                                }}
                                                FormControlProps={{
                                                    required: true
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Sheet>
                            </Grid>
                        </Grid>
                        <Divider sx={{my: 2}}/>
                        <Stack
                            direction={{sm: 'column', md: 'row'}}
                            gap={2}
                        >
                            <Button
                                startDecorator={<SaveRoundedIcon/>}
                                type="submit"
                            >
                                {t('actions.save')}
                            </Button>
                            <Button
                                startDecorator={<CloseRoundedIcon/>}
                                variant="outlined"
                                color="neutral"
                                onClick={() => {
                                    navigate(`/users/${user?.id}`);
                                }}
                            >
                                {t('actions.cancel')}
                            </Button>
                            <Box flexGrow={1} sx={theme => ({[theme.breakpoints.down('md')]: {display: 'none'}})}/>
                            <Button
                                startDecorator={<DeleteRoundedIcon />}
                                variant="outlined"
                                color="danger"
                                onClick={setDeletionPromptOpen}
                            >
                                {t('actions.delete')}
                            </Button>
                        </Stack>
                    </form>
                    <ConfirmDialog
                        open={deletionPromptOpen}
                        onCancel={clearDeletionPromptOpen}
                        onConfirm={handleDelete}
                        title={t('users.deleteTitle')}
                        message={t('users.deletePrompt')}
                        color="danger"
                    />
                </>
            )}
        </Body>
    );
}