import {
    FormControl,
    type FormControlProps,
    Checkbox as MuiCheckbox,
    type CheckboxProps as MuiCheckboxProperties,
    FormHelperText
} from '@mui/joy';
import React from 'react';
import {notBlank} from '../utils';

interface CheckboxProperties {
    CheckboxProps?: MuiCheckboxProperties;
    FormControlProps?: FormControlProps;
    helperText?: string;
}

export function Checkbox({CheckboxProps, FormControlProps, helperText}: CheckboxProperties) {
    return (
        <FormControl {...FormControlProps}>
            <MuiCheckbox
                sx={{
                    typography: 'body-md',
                }}
                {...CheckboxProps}
            />
            {notBlank(helperText) && <FormHelperText sx={{ typography: 'body-xs' }}>{helperText}</FormHelperText>}
        </FormControl>
    );
}