import React from 'react';
import {Body, Breadcrumbs, Title} from '@mlyngvo/common-ui';
import {useNavigate,Link as RouterLink} from 'react-router-dom';
import {Button, Card, CardContent, Stack, Typography} from '@mui/joy';
import {UserRole} from '../../data/user';
import {AuthWrapper} from '../../context/auth';

export function InternalFunctionsList() {
    return (
        <AuthWrapper allowRoles={[ UserRole.Root ]}>
            <ContentView />
        </AuthWrapper>
    );
}

function ContentView() {
    const navigate = useNavigate();

    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: 'Interne' }
                    ]}
                />
            )}
            title={(
                <Title
                    title="Interne"
                />
            )}
        >
            <Stack
                direction="row"
                gap={2}
            >
                <Card
                    sx={{
                        width: 400
                    }}
                >
                    <Typography level="title-lg" gutterBottom>E-Mail-Benachrichtigungen</Typography>
                    <Typography>Versenden verschiedener Test-Benachrichtigungen zur Vorschau der E-Mail-Vorlagen.</Typography>
                    <CardContent orientation="horizontal">
                        <Button
                            variant="solid"
                            color="primary"
                            size="md"
                            component={RouterLink}
                            to="/internal/email-notifications"
                        >
                            Testen
                        </Button>
                    </CardContent>
                </Card>
            </Stack>
        </Body>
    );
}