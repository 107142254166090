import React, {type ReactElement} from 'react';
import {
    Select as MuiSelect,
    FormControl,
    FormLabel,
    type SelectProps as MuiSelectProperties,
    type FormControlProps,
    Option, IconButton
} from '@mui/joy';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {notBlank} from '../utils';
import {useLocalization} from '../context/localization';

interface SelectProperties<T> {
    label: string;
    options: T[];
    renderOption?: (option: T, index: number) => ReactElement;
    emptyValue?: boolean;
    emptyLabel?: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    SelectProps?: MuiSelectProperties<T & {}, false> & { onClear?: () => void };
    FormControlProps?: FormControlProps;
}

export function Select<T>({label, options, renderOption, emptyValue = false, emptyLabel, SelectProps: {sx, value, onClear, ...selectProperties} = {}, FormControlProps}: SelectProperties<T>) {
    const {t} = useLocalization();
    return (
        <FormControl {...FormControlProps}>
            <FormLabel
                sx={{
                    typography: 'body-sm',
                    fontWeight: 600
                }}
            >
                {label}
            </FormLabel>
            <MuiSelect
                sx={{
                    bgcolor: 'background.body',
                    ...sx
                }}
                {...selectProperties}
                value={
                    // eslint-disable-next-line unicorn/no-null
                    value ?? null
                }
                {...((emptyValue && notBlank(value)) && {
                    endDecorator: (
                        <IconButton
                            size="sm"
                            variant="plain"
                            color="neutral"
                            onMouseDown={(event) => {
                                event.stopPropagation();
                            }}
                            onClick={onClear}
                            sx={{
                                minHeight: '1em'
                            }}
                        >
                            <CloseRoundedIcon />
                        </IconButton>
                    )
                })}
            >
                {emptyValue && <Option value="">{emptyLabel ?? t('common.all')}</Option>}
                {options.map((o, index) =>
                    renderOption === undefined
                        ? <Option key={String(o)} value={o}>{String(o)}</Option>
                        : renderOption(o, index)
                )}
            </MuiSelect>
        </FormControl>
    );
}