import React, {type ReactElement} from 'react';
import {Chip, type ChipProps, useTheme} from '@mui/joy';
import type {SxProps} from '@mui/joy/styles/types';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import SportsGolfRoundedIcon from '@mui/icons-material/SportsGolfRounded';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import QueryBuilderRoundedIcon from '@mui/icons-material/QueryBuilderRounded';
import {TransactionPurpose} from '../../data/statistic';
import {useLocalization} from '../../context/localization';

export function TransactionPurposeChip({purpose, size = 'sm', ...properties}: ChipProps & { purpose: TransactionPurpose }) {
    const theme = useTheme();
    const {t} = useLocalization();
    const label = t(`transactions.purposes.${purpose}`);
    let icon: ReactElement = <QuestionMarkRoundedIcon />;
    let sx: SxProps = {
        backgroundColor: undefined,
        color: 'white'
    };
    switch (purpose) {
        case TransactionPurpose.Bvm: {
            sx.backgroundColor = '#9333EA';
            icon = <SportsGolfRoundedIcon />;
            break;
        }
        case TransactionPurpose.InAppPayment: {
            sx.backgroundColor = '#0369A1';
            icon = <ReceiptRoundedIcon />;
            break;
        }
        case TransactionPurpose.Rangefee: {
            sx.backgroundColor = '#16A34A';
            icon = <QueryBuilderRoundedIcon />;
            break;
        }
        default: { break;
        }
    }
    sx = {
        ...sx,
        '& .MuiChip-endDecorator svg': {
            fontSize: theme.fontSize.md
        }
    };
    return <Chip {...{sx, size, props: properties}} endDecorator={icon}>{label}</Chip>;
}