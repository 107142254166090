import {createPageableParameters, type Page, type Pageable} from '@mlyngvo/common-ui';
import {useApi} from './api';
import {type AccountTransaction} from './statistic';

export function usePaymentApi() {
    const {get} = useApi();
    return {
        list: async (pageable: Pageable<AccountTransaction>) =>
            await get<Page<AccountTransaction>>(`/center/payments?${createPageableParameters(pageable)}`),
    };
}