import {Body, Breadcrumbs, Input, Title, useFlag} from '@mlyngvo/common-ui';
import React, {type FormEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Divider, Sheet, Stack, Typography, Option, Button, Alert, Grid} from '@mui/joy';
import {useAsync, useAsyncCallback} from 'react-async-hook';
import {Select} from '../../component/select';
import {useInternalApi} from '../../data/internal';
import {AuthWrapper} from '../../context/auth';
import {UserRole} from '../../data/user';
import {useCenterApi} from '../../data/center';

const NotificationTypes = [
    'UserCreated',
    'UserPasswordChanged',

    'AccountCreated',
    'AccountClubSignUp',
    'AccountPasswordReset',
    'AccountPasswordChanged',
    'AccountEmailUpdate',
    'AccountEmailChanged',
    'AccountTopUp',
];

const Languages = {
    'en': 'Englisch',
    'de': 'Deutsch',
};

export function InternalEmailNotifications() {
    const navigate = useNavigate();
    const {getProfile} = useCenterApi();
    const {sendNotification} = useInternalApi();

    const {result: profile} = useAsync(getProfile, []);
    const {error, loading, execute: processSendNotification} = useAsyncCallback(sendNotification);

    const [type, setType] = useState('AccountCreated');
    const [recipient, setRecipient] = useState('');
    const [locale, setLocale] = useState<keyof typeof Languages>('de');

    const [success, setSuccess, clearSuccess] = useFlag(false);

    useEffect(() => {
        if (profile !== undefined) {
            setRecipient(profile.email);
        }
    }, [profile]);

    function handleFormSubmit(event_: FormEvent) {
        event_.preventDefault();

        clearSuccess();

        processSendNotification({
            recipient,
            type,
            locale,
        })
            .then(setSuccess)
            .catch(console.error);
    }

    return (
        <AuthWrapper allowRoles={[ UserRole.Root ]}>
            <Body
                top={(
                    <Breadcrumbs
                        onHomeClick={() => { navigate('/'); }}
                        items={[
                            { label: 'Interne', onClick: () => { navigate('/internal'); } },
                            { label: 'E-Mail-Benachrichtigungen' },
                        ]}
                    />
                )}
                title={(
                    <Title
                        title="E-Mail-Benachrichtigungen"
                    />
                )}
            >
                <Grid container>
                    <Grid xs={12} md={10} lg={6}>
                        <Sheet
                            variant="outlined"
                            // sx={{ flex: 1 }}
                        >
                            <Box p={2}>
                                <Typography level="title-md">Testbenachrichtigung senden</Typography>
                                <Typography level="body-sm">Verwenden Sie das untenstehende Formular, um eine Testbenachrichtigung zu versenden.</Typography>
                            </Box>
                            <Divider />
                            <Stack
                                direction="column"
                                gap={2}
                                p={2}
                                component="form"
                                onSubmit={handleFormSubmit}
                            >
                                <Input
                                    label="Empfänger E-Mail"
                                    InputProps={{
                                        value: recipient,
                                    }}
                                    FormControlProps={{
                                        disabled: true,
                                        required: true,
                                    }}
                                />
                                <Select
                                    label="Typ"
                                    options={NotificationTypes}
                                    SelectProps={{
                                        value: type,
                                        onChange: (_, v) => { setType(v ?? 'AccountCreated'); }
                                    }}
                                    FormControlProps={{
                                        disabled: loading,
                                        required: true,
                                    }}
                                />
                                <Select
                                    label="Sprache"
                                    options={Object.keys(Languages) as Array<keyof typeof Languages>}
                                    renderOption={o => (
                                        <Option key={o} value={o}>{Languages[o]}</Option>
                                    )}
                                    SelectProps={{
                                        value: locale,
                                        onChange: (_, v) => { setLocale(v ?? 'de'); },
                                    }}
                                    FormControlProps={{
                                        disabled: loading,
                                        required: true,
                                    }}
                                />
                                <Box
                                    sx={{
                                        mt: 1,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Button type="submit" disabled={loading}>Absenden</Button>
                                </Box>
                                {success && (
                                    <Alert color="success">
                                        <Typography>Testbenachrichtigung wurde erfolgreich an den Empfänger gesendet.</Typography>
                                    </Alert>
                                )}
                                {error !== undefined && (
                                    <Alert color="danger">
                                        <Typography>Testbenachrichtigung könnte nicht versenden.</Typography>
                                    </Alert>
                                )}
                            </Stack>
                        </Sheet>
                    </Grid>
                </Grid>
            </Body>
        </AuthWrapper>
    );
}