import {Body, Breadcrumbs, DataTable, Title, useFlag, usePagination} from '@mlyngvo/common-ui';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    Avatar,
    Box,
    Button,
    Chip,
    Dropdown, FormControl, FormLabel, IconButton,
    List,
    ListDivider,
    ListItemButton,
    ListItemContent,
    ListItemDecorator, Menu, MenuButton, MenuItem, Option, Select, Stack,
    Typography
} from '@mui/joy';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import {useTableI18n, useLocalization} from '../../context/localization';
import {type ClubListView, ClubType, useClubApi} from '../../data/club';
import {StatusChip} from '../../component/status-chip';
import {ClubTypeChip} from './club-type-chip';
import {handleExplicitClick, notBlank} from '../../utils';
import {ClubCreator} from './creator';
import {RouterLink} from '../../component/link';

const StorageKey = 'page-clubs';

export function ClubList() {
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {list} = useClubApi();

    const [creatorMode, setCreatorMode, clearCreatorMode] = useFlag(false);

    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('clubs.title') }
                    ]}
                />
            )}
            title={(
                <Title
                    title={t('clubs.title')}
                    actions={(
                        <Button
                            color="primary"
                            startDecorator={<AddRoundedIcon />}
                            size="sm"
                            onClick={setCreatorMode}
                        >
                            {t('clubs.addNew')}
                        </Button>
                    )}
                />
            )}
        >
            <DataTable
                {...usePagination({
                    paginationKey: StorageKey,
                    fetch: list
                })}
                stickyLastColumn
                i18n={{
                    ...useTableI18n(),
                    searchLabel: t('clubs.search')
                }}
                headers={[
                    { label: t('common.status'), sortKey: ['enabled'], width: 100 },
                    { label: 'L/T/A', sortKey: ['lta.land', 'lta.type', 'lta.clubNr'] as any, width: 140 },
                    { label: t('common.name'), sortKey: ['name'], width: 380 },
                    { label: t('common.address'), sortKey: ['address.country', 'address.addressLine1', 'address.addressLine2', 'address.zipCode', 'address.city'], width: 300 },
                    { label: '', width: 60 },
                ]}
                renderTableRow={club => <TableRow key={club.id} {...{club}} />}
                renderListRow={club => <ListRow key={club.id} {...{club}} />}
                renderFilter={(onFilter, filter) => <Filter {...{ onFilter, filter }} />}
            />

            <ClubCreator
                open={creatorMode}
                onCancel={clearCreatorMode}
            />
        </Body>
    );
}

function TableRow({club: {id, name, address, type, lta, enabled: pEnabled, logoUrl}}: { club: ClubListView }) {
    const {setEnabled: setEnabledApi} = useClubApi();

    const [enabled, setEnabled] = useState(pEnabled);

    function handleSetEnabled(value: boolean) {
        setEnabledApi(id, value)
            .then(() => { setEnabled(value); })
            .catch(console.error);
    }

    return (
        <tr>
            <td style={{width: 100}}>
                <StatusChip {...{enabled}} />
            </td>
            <td style={{width: 140}}>
                <RouterLink href={`/clubs/${id}`}>
                    <Chip
                        size="sm"
                        variant="outlined"
                        sx={theme => ({
                            minWidth: 80,
                            textAlign: 'center',
                            fontFamily: theme.fontFamily.code,
                            fontSize: '0.63rem'
                        })}
                    >
                        {lta}
                    </Chip>
                </RouterLink>
            </td>
            <td style={{width: 380}}>
                <RouterLink
                    href={`/clubs/${id}`}
                    sx={{
                        gap: 2,
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Avatar size="sm" src={logoUrl} />
                    <Stack direction="column">
                        <Typography level="body-xs"><strong>{name}</strong></Typography>
                        <ClubTypeChip type={type}/>
                    </Stack>
                </RouterLink>
            </td>
            <td style={{width: 300}}>
                <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                    <Avatar size="sm" variant="outlined">{address?.country}</Avatar>
                    <div>
                        <Typography level="body-xs">{[address?.addressLine1, address?.addressLine2].filter(notBlank).join(', ')}</Typography>
                        <Typography level="body-xs">{[address?.zipCode, address?.city].filter(notBlank).join(', ')}</Typography>
                    </div>
                </Box>
            </td>
            <td
                onClick={event_ => {
                    event_.stopPropagation();
                }}
            >
                <ItemMenu
                    {...{
                        id,
                        enabled,
                        onEnable: () => { handleSetEnabled(true); },
                        onDisable: () => { handleSetEnabled(false); },
                    }}
                />
            </td>
        </tr>
    );
}

function ListRow({club: {id, name, address, type, lta, enabled, logoUrl}}: { club: ClubListView }) {
    const navigate = useNavigate();

    return (
        <List
            size="sm"
            sx={{
                '--ListItem-paddingX': 0,
            }}
        >
            <ListItemButton
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    '&:hover': {
                        background: 'none !important'
                    }
                }}
                onClick={() => { navigate(`/clubs/${  id}`); }}
            >
                <ListItemContent sx={{ display: 'flex', gap: 2, alignItems: 'start' }}>
                    <ListItemDecorator>
                        <Avatar size="sm" src={logoUrl} />
                    </ListItemDecorator>
                    <div>
                        <Typography fontWeight={600}>
                            {name}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <ClubTypeChip type={type}/>
                            <StatusChip {...{enabled}} />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'space-between',
                                gap: 0.5,
                                my: 1,
                            }}
                        >
                            <Typography level="body-xs">{[address?.addressLine1, address?.addressLine2, address?.zipCode, address?.city, address?.country].filter(notBlank).join(', ')}</Typography>
                        </Box>
                    </div>
                </ListItemContent>
                <Chip
                    size="sm"
                    variant="outlined"
                    sx={theme => ({
                        minWidth: 80,
                        textAlign: 'center',
                        fontFamily: theme.fontFamily.code,
                        fontSize: '0.63rem'
                    })}
                >
                    {lta}
                </Chip>
            </ListItemButton>
            <ListDivider />
        </List>
    );
}

function Filter({onFilter, filter = {}}: { onFilter: (filter: Record<string, any>) => void, filter: Record<string, any>|undefined }) {
    const {t} = useLocalization();

    const [status, setStatus] = useState('');
    const [type, setType] = useState('');
    const [country, setCountry] = useState('');

    useEffect(() =>
        {
            if (typeof filter.status === 'string'
                && filter.status !== status
            ) {
                setStatus(filter.status);
            }
            if (typeof filter.type === 'string'
                && filter.type !== type
            ) {
                setType(filter.type);
            }
            if (typeof filter.country === 'string'
                && filter.country !== type
            ) {
                setCountry(filter.country);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filter]);

    function handleStatus(s: string) {
        setStatus(s);
        onFilter({
            ...filter,
            status: s
        });
    }

    function handleType(ft: string) {
        setType(ft);
        onFilter({
            ...filter,
            type: ft
        });
    }

    function handleCountry(c: string) {
        setCountry(c);
        onFilter({
            ...filter,
            country: c
        });
    }

    return (
        <>
            <FormControl size="sm">
                <FormLabel>{t('common.status')}</FormLabel>
                <Select
                    size="sm"
                    slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                    value={status}
                    onChange={(_, newValue) => { handleStatus(newValue ?? ''); }}
                >
                    <Option value="">{t('common.all')}</Option>
                    <Option value="1">{t('common.enabled')}</Option>
                    <Option value="0">{t('common.disabled')}</Option>
                </Select>
            </FormControl>
            <FormControl size="sm">
                <FormLabel>{t('common.type')}</FormLabel>
                <Select
                    size="sm"
                    value={type}
                    onChange={(_, newValue) => { handleType(newValue ?? ''); }}
                >
                    <Option value="">{t('common.all')}</Option>
                    {Object.values(ClubType).map(ft => (
                        <Option key={ft} value={ft}>{t(`clubs.types.${ft}`)}</Option>
                    ))}
                </Select>
            </FormControl>
            <FormControl size="sm">
                <FormLabel>{t('common.country')}</FormLabel>
                <Select
                    size="sm"
                    value={country}
                    onChange={(_, newValue) => { handleCountry(newValue ?? ''); }}
                >
                    <Option value="">{t('common.all')}</Option>
                    {/* eslint-disable @typescript-eslint/no-unsafe-argument */}
                    {['AT', 'DE', 'IT', 'FR'].map(c => (
                        <Option key={c} value={c}>
                            <ListItemDecorator sx={{ position: 'relative', top: 1 }}>
                                {`${t(`countries.${c}.flag` as any)} `}
                            </ListItemDecorator>
                            {t(`countries.${c}.name` as any)}
                        </Option>
                    ))}
                    {/* eslint-enable @typescript-eslint/no-unsafe-argument */}

                </Select>
            </FormControl>
        </>
    );
}

interface ItemMenuProperties {
    id: string;
    enabled: boolean;
    onEnable: () => void;
    onDisable: () => void;
}

function ItemMenu({id, enabled, onEnable, onDisable}: ItemMenuProperties) {
    const {t} = useLocalization();
    const navigate = useNavigate();

    return (
        <Dropdown>
            <MenuButton
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
            >
                <MoreHorizRoundedIcon />
            </MenuButton>
            <Menu size="sm" sx={{ minWidth: 140 }}>
                <MenuItem
                    onClick={event_ =>
                        { handleExplicitClick(event_, () => { navigate(`/clubs/${id}`); }); }
                    }
                >
                    <ListItemDecorator>
                        <VisibilityRoundedIcon />
                    </ListItemDecorator>
                    {t('actions.view')}
                </MenuItem>
                <MenuItem
                    onClick={event_ =>
                        { handleExplicitClick(event_, () => { navigate(`/clubs/${id}/edit`); }); }
                    }
                >
                    <ListItemDecorator>
                        <EditRoundedIcon />
                    </ListItemDecorator>
                    {t('actions.edit')}
                </MenuItem>
                <MenuItem
                    color="success"
                    disabled={enabled}
                    onClick={event_ =>
                        { handleExplicitClick(event_, () => { onEnable(); }); }
                    }
                >
                    <ListItemDecorator>
                        <CheckRoundedIcon />
                    </ListItemDecorator>
                    {t('actions.enable')}
                </MenuItem>
                <MenuItem
                    color="danger"
                    disabled={!enabled}
                    onClick={event_ =>
                        { handleExplicitClick(event_, () => { onDisable(); }); }
                    }
                >
                    <ListItemDecorator>
                        <CloseRoundedIcon />
                    </ListItemDecorator>
                    {t('actions.disable')}
                </MenuItem>
            </Menu>
        </Dropdown>
    );
}