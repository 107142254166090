import {Chip, type ChipProps} from '@mui/joy';
import React, {type ReactElement} from 'react';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import type {SxProps} from '@mui/joy/styles/types';

import StarsRoundedIcon from '@mui/icons-material/StarsRounded';
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import {UserRole} from '../../data/user';

export function UserRoleChip({role, size = 'sm', ...properties}: ChipProps & { role: UserRole }) {
    let icon: ReactElement = <QuestionMarkRoundedIcon />;
    const sx: SxProps = {
        backgroundColor: undefined,
        color: 'white'
    };
    switch (role) {
        case UserRole.Root: {
            sx.backgroundColor = '#BE123C';
            icon = <StarsRoundedIcon />;
            break;
        }
        case UserRole.Admin: {
            sx.backgroundColor = '#D97706';
            icon = <SupervisedUserCircleRoundedIcon />;
            break;
        }
        case UserRole.User: {
            sx.backgroundColor = '#14B8A6';
            icon = <AccountCircleRoundedIcon />;
            break;
        }
        default: {
            break;
        }
    }
    return <Chip {...{sx, size, props: properties}} startDecorator={icon}>{role}</Chip>;
}