import {Chip, type ChipProps, useTheme} from '@mui/joy';
import React, {type ReactElement} from 'react';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import MeetingRoomRoundedIcon from '@mui/icons-material/MeetingRoomRounded';
import SportsGolfRoundedIcon from '@mui/icons-material/SportsGolfRounded';
import AvTimerRoundedIcon from '@mui/icons-material/AvTimerRounded';
import type {SxProps} from '@mui/joy/styles/types';
import {DeviceType} from '../../data/device';

export function DeviceTypeChip({type, size = 'sm', ...properties}: ChipProps & { type: DeviceType }) {
    const theme = useTheme();
    let sx: SxProps = {
        backgroundColor: undefined,
        color: 'white',
    };
    let icon: ReactElement = <QuestionMarkRoundedIcon />;
    switch (type) {
        case DeviceType.Entrance: {
            sx.backgroundColor = '#16A34A';
            icon = <MeetingRoomRoundedIcon />;
            break;
        }
        case DeviceType.BallVendingMachine: {
            sx.backgroundColor = '#9333EA';
            icon = <SportsGolfRoundedIcon />;
            break;
        }
        case DeviceType.Timer: {
            sx.backgroundColor = '#F59E0B';
            icon = <AvTimerRoundedIcon />;
            break;
        }
        default: {
            break;
        }
    }
    sx = {
        ...sx,
        '& .MuiChip-startDecorator svg': {
            fontSize: theme.fontSize.md
        }
    };
    return <Chip {...{sx, size, ...properties}} startDecorator={icon}>{type}</Chip>;
}