import {ForgotPasswordDialog, type ForgotPasswordFormData, useFlag} from '@mlyngvo/common-ui';
import React from 'react';
import {useParams} from 'react-router';
import {useAsyncCallback} from 'react-async-hook';
import {Logo} from '../../assets/logo';
import {useLocalization} from '../../context/localization';
import {useAppApi} from '../../data/app';
import {SHA3} from "crypto-js";

export function AccountPasswordReset() {
    const {t} = useLocalization();
    const {token = ''} = useParams<{ token: string }>();
    const {resetAccountPassword} = useAppApi();

    const {loading, error, execute} = useAsyncCallback(resetAccountPassword);
    const [success, setSuccess] = useFlag(false);

    async function handleSubmit({newPassword, confirmNewPassword}: ForgotPasswordFormData) {
        if (newPassword !== undefined && newPassword === confirmNewPassword) {
            await execute(token, SHA3(newPassword).toString());
            setSuccess();
        }
    }

    return (
        <ForgotPasswordDialog
            appTitle="Pin Point Smart App"
            logo={<Logo />}
            onSubmit={handleSubmit}
            verificationMode={false}
            newPasswordMode
            i18n={{
                form: t('auth.resetPassword'),
                email: t('common.email'),
                code: t('auth.verificationCode'),
                newPassword: t('users.newPassword'),
                confirmPassword: t('users.confirmPassword'),
                submit: t('auth.resetPassword'),
            }}
            loading={loading}
            error={error === undefined ? undefined : 'Failed to set new password.'}
            success={success}
        />
    );
}