import React from 'react';
import {Body, Breadcrumbs, Title} from '@mlyngvo/common-ui';
import {useNavigate} from 'react-router-dom';
import {useAsync} from 'react-async-hook';
import {
    Box,
    Divider,
    Grid,
    Sheet,
    Stack,
    Typography
} from '@mui/joy';
import dayjs from 'dayjs';
import {useLocalization} from '../../context/localization';
import {useStatisticApi} from '../../data/statistic';
import {RecentTransactions} from './recent-transactions';
import {RecentActivities} from './recent-activities';
import {AmountDisplay} from './amount-display';
import {AuthWrapper, useAuthContext} from '../../context/auth';
import {UserRole} from '../../data/user';

export function Dashboard() {
    return (
        <AuthWrapper>
            <ContentView />
        </AuthWrapper>
    );
}

function ContentView() {
    const {role} = useAuthContext();

    return (
        <>
            {role === UserRole.Root && (
                <RootView />
            )}

            {role !== UserRole.Root && (
                <ClubView />
            )}
        </>
    );
}

function RootView() {
    const navigate = useNavigate();
    const {t, language} = useLocalization();
    const {computeKpis} = useStatisticApi();

    const {result: kpis, error, loading} = useAsync(computeKpis, []);

    const now = dayjs().locale(language);
    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('dashboard.title') }
                    ]}
                />
            )}
            title={(
                <Title
                    title={t('dashboard.title')}
                />
            )}
            {...{ error, loading }}
        >
            {kpis !== undefined && (
                <Stack
                    direction="column"
                    rowGap={4}
                >
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={6} md={4}>
                            <Sheet
                                variant="outlined"
                                sx={{p: 2}}
                            >
                                <Typography level="title-md"><strong>{t('accounts.title')}</strong></Typography>
                                <Box my={2} mx={-2}><Divider /></Box>
                                <Stack
                                    direction="column"
                                    gap={1}
                                >
                                    <div>
                                        <Typography level="body-sm" color="neutral">{t('common.total')}</Typography>
                                        <Typography level="title-lg">{kpis.accountsCount}</Typography>
                                    </div>
                                    <div>
                                        <Typography level="body-sm" color="neutral">{t('common.balance')} ({t('clubs.singular')})</Typography>
                                        <Typography level="title-lg">€ <AmountDisplay amount={kpis.totalClubWalletBalance}/></Typography>
                                    </div>
                                    <div>
                                        <Typography level="body-sm" color="neutral">{t('common.balance')} (Intl.)</Typography>
                                        <Typography level="title-lg">€ <AmountDisplay amount={kpis.totalInternationWalletBalance}/></Typography>
                                    </div>
                                </Stack>
                            </Sheet>
                        </Grid>
                        <Grid xs={12} sm={6} md={4}>
                            <Sheet
                                variant="outlined"
                                sx={{p: 2}}
                            >
                                <Typography level="title-md"><strong>{t('clubs.title')}</strong></Typography>
                                <Box my={2} mx={-2}><Divider /></Box>
                                <Stack
                                    direction="column"
                                    gap={1}
                                >
                                    <div>
                                        <Typography level="body-sm" color="neutral">{t('common.total')}</Typography>
                                        <Typography level="title-lg">{kpis.clubsCount}</Typography>
                                    </div>
                                    <div>
                                        <Typography level="body-sm" color="neutral">{t('stats.registrations')}</Typography>
                                        <Typography level="title-lg">{kpis.clubRegistrationsCount}</Typography>
                                    </div>
                                </Stack>
                            </Sheet>
                            <Box my={2} />
                            <Sheet
                                variant="outlined"
                                sx={{p: 2}}
                            >
                                <Typography level="title-md"><strong>{t('devices.title')}</strong></Typography>
                                <Box my={2} mx={-2}><Divider /></Box>
                                <Stack
                                    direction="column"
                                    gap={1}
                                >
                                    <div>
                                        <Typography level="body-sm" color="neutral">{t('devices.types.BallVendingMachine')}</Typography>
                                        <Typography level="title-lg">{kpis.bvmDeviceCount}</Typography>
                                    </div>
                                    <div>
                                        <Typography level="body-sm" color="neutral">{t('devices.types.Entrance')}</Typography>
                                        <Typography level="title-lg">{kpis.entranceDeviceCount}</Typography>
                                    </div>
                                </Stack>
                            </Sheet>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Sheet
                                variant="outlined"
                                sx={{p: 2}}
                            >
                                <Typography level="title-md"><strong>{t('stats.types.Transactions')}</strong></Typography>
                                <Box my={2} mx={-2}><Divider /></Box>
                                <Stack
                                    direction="column"
                                    gap={1}
                                >
                                    <Typography gutterBottom>{now.format('MMMM YYYY')}</Typography>
                                    <div>
                                        <Typography level="body-sm" color="neutral">{t('common.total')}</Typography>
                                        <Typography level="title-lg">{kpis.transactionsCount}</Typography>
                                    </div>
                                    <div>
                                        <Typography level="body-sm" color="neutral">{t('transactions.directions.BookIn')} ({t('clubs.singular')})</Typography>
                                        <Typography level="title-lg">€ <AmountDisplay amount={kpis.totalClubBookInAmount} /></Typography>
                                    </div>
                                    <div>
                                        <Typography level="body-sm" color="neutral">{t('transactions.directions.BookOut')} ({t('clubs.singular')})</Typography>
                                        <Typography level="title-lg">€ <AmountDisplay amount={kpis.totalClubBookOutAmount} /></Typography>
                                    </div>
                                    <div>
                                        <Typography level="body-sm" color="neutral">{t('transactions.directions.BookIn')} (Intl)</Typography>
                                        <Typography level="title-lg">€ <AmountDisplay amount={kpis.totalInternationalBookInAmount} /></Typography>
                                    </div>
                                    <div>
                                        <Typography level="body-sm" color="neutral">{t('transactions.directions.BookOut')} (Intl)</Typography>
                                        <Typography level="title-lg">€ <AmountDisplay amount={kpis.totalInternationalBookOutAmount} /></Typography>
                                    </div>
                                </Stack>
                            </Sheet>
                        </Grid>
                    </Grid>
                    <RecentTransactions transactions={kpis.recentTransactions} />
                    <RecentActivities activities={kpis.recentActivities} />
                </Stack>
            )}
        </Body>
    );
}

function ClubView() {
    const navigate = useNavigate();
    const {t, language} = useLocalization();
    const {clubId} = useAuthContext();
    const {computeClubKpis} = useStatisticApi();

    const {result: kpis, error, loading} = useAsync(async () =>
        (clubId === undefined)
            ? undefined
            : await computeClubKpis(clubId)
        , [clubId]);

    const now = dayjs().locale(language);
    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('dashboard.title') }
                    ]}
                />
            )}
            title={(
                <Title
                    title={t('dashboard.title')}
                />
            )}
            {...{ error, loading }}
        >
            {kpis !== undefined && (
                <Stack
                    direction="column"
                    rowGap={4}
                >
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={6}>
                            <Sheet
                                variant="outlined"
                                sx={{p: 2}}
                            >
                                <Typography level="title-md"><strong>{t('common.overview')}</strong></Typography>
                                <Box my={2} mx={-2}><Divider /></Box>
                                <Stack
                                    direction="column"
                                    gap={1}
                                >
                                    <div>
                                        <Typography level="body-sm" color="neutral">{t('stats.registrations')}</Typography>
                                        <Typography level="title-lg">{kpis.clubRegistrationsCount}</Typography>
                                    </div>
                                    <div>
                                        <Typography level="body-sm" color="neutral">{t('devices.title')} ({t('devices.types.Entrance')})</Typography>
                                        <Typography level="title-lg">{kpis.entranceDeviceCount}</Typography>
                                    </div>
                                    <div>
                                        <Typography level="body-sm" color="neutral">{t('devices.title')} ({t('devices.types.BallVendingMachine')})</Typography>
                                        <Typography level="title-lg">{kpis.bvmDeviceCount}</Typography>
                                    </div>
                                    <div>
                                        <Typography level="body-sm" color="neutral">{t('common.total')} {t('common.balance')}</Typography>
                                        <Typography level="title-lg">€ <AmountDisplay amount={kpis.totalClubWalletBalance}/></Typography>
                                    </div>
                                </Stack>
                            </Sheet>
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <Sheet
                                variant="outlined"
                                sx={{p: 2}}
                            >
                                <Typography level="title-md"><strong>{t('stats.types.Transactions')}</strong></Typography>
                                <Box my={2} mx={-2}><Divider /></Box>
                                <Stack
                                    direction="column"
                                    gap={1}
                                >
                                    <Typography gutterBottom>{now.format('MMMM YYYY')}</Typography>
                                    <div>
                                        <Typography level="body-sm" color="neutral">{t('common.total')}</Typography>
                                        <Typography level="title-lg">{kpis.transactionsCount}</Typography>
                                    </div>
                                    <div>
                                        <Typography level="body-sm" color="neutral">{t('transactions.directions.BookIn')} ({t('clubs.singular')})</Typography>
                                        <Typography level="title-lg">€ <AmountDisplay amount={kpis.totalClubBookInAmount} /></Typography>
                                    </div>
                                    <div>
                                        <Typography level="body-sm" color="neutral">{t('transactions.directions.BookOut')} ({t('clubs.singular')})</Typography>
                                        <Typography level="title-lg">€ <AmountDisplay amount={kpis.totalClubBookOutAmount} /></Typography>
                                    </div>
                                </Stack>
                            </Sheet>
                        </Grid>
                    </Grid>
                    <RecentTransactions transactions={kpis.recentTransactions} />
                    <RecentActivities activities={kpis.recentActivities} />
                </Stack>
            )}
        </Body>
    );
}