import React from 'react';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {useAsync} from 'react-async-hook';
import {Body, Breadcrumbs, Title} from '@mlyngvo/common-ui';
import {Button} from '@mui/joy';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import {useClubApi} from '../../data/club';
import {useLocalization} from '../../context/localization';
import {ClubForm} from './club-form';


export function ClubEditor() {
    const {id } = useParams<{id: string}>();
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {find} = useClubApi();

    const {result: club, error: loadError, loading} = useAsync(async () =>
            (id === undefined) ? undefined : await find(id)
        , [id]);

    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('clubs.title'), onClick: () => { navigate('/clubs'); } },
                        { label: club === undefined ? t('common.details') : club.name, onClick: () => { navigate(`/clubs/${id}`); } },
                        { label: t('actions.edit') }
                    ]}
                />
            )}
            title={(
                <Title
                    title={club === undefined ? t('clubs.singular') : club.name}
                    actions={(
                        <Button
                            variant="outlined"
                            color="neutral"
                            startDecorator={<ArrowBackIosRoundedIcon />}
                            size="sm"
                            onClick={() => { navigate(`/clubs/${club?.id}`); }}
                        >
                            {t('actions.back')}
                        </Button>
                    )}
                />
            )}
            loading={loading}
            error={loadError}
        >
            {club !== undefined && (
                <ClubForm
                    club={club}
                    onSave={() => { navigate(`/clubs/${club.id}`); }}
                    onCancel={() => { navigate(`/clubs/${club.id}`); }}
                />
            )}
        </Body>
    );
}