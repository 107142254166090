import React, {useState} from 'react';
import {Divider, Grid, Option, Typography} from '@mui/joy';
import {useNavigate} from 'react-router-dom';
import {type ClubRequest, ClubType, useClubApi} from '../../data/club';
import {useLocalization} from '../../context/localization';
import {FormModal} from '../../component/form-modal';
import {ClubTypeChip} from './club-type-chip';
import {Select} from '../../component/select';
import {Input} from '../../component/input';

interface FormElements extends HTMLFormControlsCollection {
    name: HTMLInputElement;
}

interface ClubCreatorFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

interface ClubCreatorProperties {
    open: boolean;
    onCancel: () => void;
}

export function ClubCreator({open, onCancel}: ClubCreatorProperties) {
    const {t} = useLocalization();
    const navigate = useNavigate();
    const {create} = useClubApi();

    const [type, setType] = useState<ClubType>();

    async function handleSubmit(event: React.FormEvent<ClubCreatorFormElement>) {
        event.preventDefault();

        if (type !== undefined) {
            const formElements = event.currentTarget.elements;

            const data: ClubRequest = {
                name: formElements.name.value,
                type,
                intlWalletEnabled: false,
                automaticallyAccountActivationEnabled: false,
            };

            const club = await create(data);
            navigate(`/clubs/${club?.id}`);
        }
    }

    return (
        <FormModal
            open={open}
            onCancel={onCancel}
            onSave={handleSubmit}
        >
            <Typography level="title-lg">
                {t('clubs.addNew')}
            </Typography>
            <Divider />
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <Input
                        label={t('common.name')}
                        FormControlProps={{
                            required: true
                        }}
                        InputProps={{
                            name: 'name',
                        }}
                    />
                </Grid>
                <Grid xs={12}>
                    <Select
                        label={t('common.type')}
                        options={Object.values(ClubType)}
                        renderOption={o => (
                            <Option key={o} value={o}><ClubTypeChip type={o}/></Option>
                        )}
                        SelectProps={{
                            placeholder: t('hints.select'),
                            value: type,
                            onClear: () => { setType(undefined); },
                            onChange: (_, value) => { setType(value ?? undefined); },
                            renderValue: o =>
                                o === null
                                    ? ''
                                    : <ClubTypeChip type={o.value}/>
                        }}
                        FormControlProps={{
                            required: true
                        }}
                    />
                </Grid>
            </Grid>
        </FormModal>
    );
}