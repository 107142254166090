import React, {useState} from 'react';
import {LoginDialog, type LoginFormData} from '@mlyngvo/common-ui';
import {Logo} from '../../assets/logo';
import {useLocalization} from '../../context/localization';
import {useAuthApi} from '../../data/auth';
import {authStore} from '../../store/auth';
import {useAuthContext} from '../../context/auth';
import {hashPassword, notBlank, notNull} from '../../utils';

export function AppLogin() {
    const {t} = useLocalization();
    const {setAuthenticated} = useAuthContext();
    const {authenticate} = useAuthApi();

    const [error, setError] = useState<string>();

    async function handleLogin({email, password, persistent}: LoginFormData) {
        if (!notBlank(email) || !notBlank(password)) {
            setError(t('auth.invalidForm'));
            return;
        }
        try {
            const response = (await authenticate({
                email,
                password: hashPassword(password),
                persistent
            }));
            if (notNull(response)) {
                authStore.authData = response;
                setAuthenticated(true);
                window.location.replace('/');
                return;
            }
        } catch (error_) {
            console.error('Cannot authenticate', error_);
        }

        setError(t('auth.authError'));
    }

    return (
        <LoginDialog
            appTitle="Pin Point Center"
            logo={<Logo />}
            onSubmit={handleLogin}
            i18n={{
                form: t('auth.login'),
                email: t('common.email'),
                password: t('common.password'),
                persistent: t('auth.loginPersistent'),
                forgotPassword: t('auth.forgotPassword'),
                submit: t('actions.login')
            }}
            error={error}
            forgotPasswordUrl="/forgot-password"
        />
    );
}