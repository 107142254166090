import React from 'react';
import {Box} from '@mui/joy';
import {notBlank} from '../utils';

const MAX_LENGTH = 40;
const LONG_TEXT_OFFSET = 10;

function fillSpace(length: number) {
    return Array.from({length}).fill(' ').join('');
}

interface PaddedTextProperties {
    text?: string;
    leadingText?: string;
    trailingText?: string;
    bold?: boolean;
    left?: boolean;
    right?: boolean;

}

export function PaddedText({text, leadingText, trailingText, bold = false, left = false, right = false}: PaddedTextProperties) {
    const parsed = [];
    if (text !== undefined) {
        if (text.length > MAX_LENGTH - LONG_TEXT_OFFSET) {
            const chunk = text.split(' ');
            let accumulator: string[] = [];
            let block = '';
            while (chunk.length > 0) {
                block = chunk.shift() ?? '';
                const joined = [...accumulator, block].join(' ');
                if (joined.length >= MAX_LENGTH - LONG_TEXT_OFFSET) {
                    parsed.push(accumulator.join(' '));
                    accumulator = [block];
                } else {
                    accumulator.push(block);
                }
            }
            if (accumulator.length > 0) {
                parsed.push(accumulator.join(' '));
            }
        }
        else {
            parsed.push(text);
        }
    }

    if (typeof leadingText === 'string' && notBlank(leadingText) && typeof trailingText === 'string' && notBlank(trailingText)) {
        if ((leadingText + trailingText).length <= MAX_LENGTH) {
            const spacing = MAX_LENGTH - (leadingText.length + trailingText.length);
            parsed.push((leadingText + fillSpace(spacing) + trailingText));
        } else {
            parsed.push('MAX_LENGTH_EXCEEDED');
        }
    }

    return (
        parsed.map(line => {
            const spacing = MAX_LENGTH - line.length >= 0 ? MAX_LENGTH - line.length : 0;
            const leftPad = left ? 0 : (right ? spacing : Math.floor(spacing / 2));
            const rightPad = right ? 0 : (left ? spacing : spacing - leftPad);
            return (
                <Box
                    key={line}
                    component="pre"
                    sx={theme => ({
                        fontSize: theme.fontSize.xs,
                        fontWeight: bold ? 'bold' : theme.fontWeight.xs,
                        margin: 0,
                        padding: 0,
                        display: 'inline-block',
                        // border: '1px solid black'
                    })}
                >
                    {fillSpace(leftPad)}
                    {line}
                    {fillSpace(rightPad)}
                </Box>
            );
        })
    );
}