import React, {type PropsWithChildren} from 'react';
import {Link} from 'react-router-dom';
import {Link as MuiLink, type LinkProps as MuiLinkProperties} from '@mui/joy';

export function RouterLink({href, children, sx, ...properties}: PropsWithChildren<MuiLinkProperties & { href: string }>) {
    return (
        <MuiLink
            {...properties}
            sx={theme => ({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                ...(sx),
                color: theme.palette.text.primary,
                '&:hover': {
                    textDecoration: 'none !important',
                    '&, & .MuiTypography-root': {
                        color: theme.palette.primary[500],
                    }
                }
            } as any)}
            component={Link}
            to={href}
        >
            {children}
        </MuiLink>
    );
}