import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Divider, Grid, Typography} from '@mui/joy';
import type {Dayjs} from 'dayjs';
import {useLocalization} from '../../context/localization';
import {FormModal} from '../../component/form-modal';
import {Input} from '../../component/input';
import {ClubSelect} from '../../component/club-select';
import {type AccountGroupRequest, useAccountGroupApi} from '../../data/account-group';
import {IsoDateFormat} from '../../utils';
import {DatePicker} from '../../component/date-picker';
import {TimePicker} from '../../component/time-picker';
import {AuthWrapper, useAuthContext} from '../../context/auth';
import {UserRole} from '../../data/user';
import {Checkbox} from '../../component/checkbox';

interface AccountGroupCreatorProperties {
    open: boolean;
    onCancel: () => void;
}

export function AccountGroupCreator({open, onCancel}: AccountGroupCreatorProperties) {
    return (
        <AuthWrapper>
            <ContentView {...{ open, onCancel }} />
        </AuthWrapper>
    );
}

interface FormElements extends HTMLFormControlsCollection {
    name: HTMLInputElement;
    defaultOnSignup: HTMLInputElement;
    customRangefeeAmount: HTMLInputElement;
}

interface AccountGroupCreatorFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

function ContentView({open, onCancel}: AccountGroupCreatorProperties) {
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role, clubId: authClubId } = useAuthContext();
    const {create} = useAccountGroupApi();

    const [clubId, setClubId] = useState(authClubId);

    /* eslint-disable unicorn/no-null */
    const [expirationDate, setExpirationDate] = useState<Dayjs|null>(null);
    const [startHour, setStartHour] = useState<Dayjs|null>(null);
    const [endHour, setEndHour] = useState<Dayjs|null>(null);
    /* eslint-enable unicorn/no-null */

    async function handleSubmit(event: React.FormEvent<AccountGroupCreatorFormElement>) {
        event.preventDefault();

        if (clubId !== undefined) {
            const formElements = event.currentTarget.elements;

            const data: AccountGroupRequest = {
                name: formElements.name.value,
                expirationDate: expirationDate?.format(IsoDateFormat.Date),
                startHour: startHour?.format(IsoDateFormat.Time),
                endHour: endHour?.format(IsoDateFormat.Time),
                defaultOnSignup: formElements.defaultOnSignup.checked,
                customRangefeeAmount: formElements.customRangefeeAmount.valueAsNumber,
                clubId,
            };

            const group = await create(data);
            navigate(`/account-groups/${group?.id}`);
        }
    }

    return (
        <FormModal
            open={open}
            onCancel={onCancel}
            onSave={handleSubmit}
        >
            <Typography level="title-lg">
                {t('accounts.addNew')}
            </Typography>
            <Divider />
            <Grid container spacing={2}>
                {role === UserRole.Root && (
                    <Grid xs={12}>
                        <ClubSelect
                            required
                            defaultClubId={clubId}
                            onChange={setClubId}
                        />
                    </Grid>
                )}
                <Grid xs={12}>
                    <Input
                        label={t('common.name')}
                        InputProps={{
                            name: 'name',
                        }}
                        FormControlProps={{
                            required: true
                        }}
                    />
                </Grid>
                <Grid xs={12}>
                    <Input
                        label={t('transactions.customRangefeeAmount')}
                        InputProps={{
                            name: 'customRangefeeAmount',
                            startDecorator: '€',
                            type: 'number',
                            inputMode: 'numeric'

                        }}
                    />
                </Grid>
                <Grid xs={12}>
                    <Checkbox
                        CheckboxProps={{
                            label: t('accountGroups.defaultOnSignup'),
                            name: 'defaultOnSignup',

                        }}
                        helperText={t('accountGroups.defaultOnSignupHint')}
                    />
                </Grid>
                <Grid xs={12}>
                    <DatePicker
                        label={t('accountGroups.expirationDate')}
                        PickerProps={{
                            value: expirationDate,
                            onChange: setExpirationDate
                        }}
                    />
                </Grid>
                <Grid xs={12}>
                    <Typography>{t('accountGroups.activeTimeframe')}</Typography>
                </Grid>
                <Grid xs={12} md={6}>
                    <TimePicker
                        label={t('common.from')}
                        PickerProps={{
                            value: startHour,
                            onChange: setStartHour
                        }}
                    />
                </Grid>
                <Grid xs={12} md={6}>
                    <TimePicker
                        label={t('common.until')}
                        PickerProps={{
                            value: endHour,
                            onChange: setEndHour
                        }}
                    />
                </Grid>
            </Grid>
        </FormModal>
    );
}