import {utils, writeFile} from 'xlsx';
import React from 'react';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import {Button} from '@mui/joy';
import dayjs, {type Dayjs} from 'dayjs';
import {type TransactionStatistic} from '../../data/statistic';
import {useLocalization} from '../../context/localization';
import {displayDate, displayDatetime, IsoDateFormat, notBlank} from '../../utils';
import {WalletType} from '../../data/wallet';
import {computeColumnLengths, formatCellAsNumber} from '../../excels';
import {useClubApi} from '../../data/club';

function formatAmount(value?: number) {
    return value?.toFixed(2) ?? '0';
}

export function TransactionExportButton({clubId, from: pFrom, until: pUntil, fetch}: { clubId: string, from: Dayjs, until: Dayjs, fetch: () => Promise<TransactionStatistic|undefined> }) {
    const {t} = useLocalization();
    const {find} = useClubApi();

    async function exportToExcel() {
        let club;
        let data;
        try {
            club = await find(clubId);
            data = await fetch();
        } catch (error) {
            console.error('Failed to get data', error);
            return;
        }

        if (club === undefined || data === undefined) {
            console.error('Empty data return.');
            return;
        }

        const from = pFrom.toISOString();
        const until = pUntil.toISOString();

        const workbook = utils.book_new();

        const worksheet = utils.json_to_sheet([]);
        utils.book_append_sheet(workbook, worksheet, '');

        utils.sheet_add_aoa(worksheet, [[`${t('stats.title')} - ${t('stats.types.Transactions')}`]], { origin: 'A1' });
        utils.sheet_add_aoa(worksheet, [[`${t('clubs.singular')}: ${  club.name}`]], { origin: 'A2' });
        utils.sheet_add_aoa(worksheet, [[`${t('common.timeframe')}: ${displayDate(from)} - ${displayDate(until)}`]], { origin: 'A3' });

        const headers = [t('common.timestamp'), t('transactions.clearedAt'), t('accounts.singular'), t('wallets.singular'), t('common.type'), t('transactions.purpose'), t('devices.singular'), 'Port #', t('common.quantity'), t('devices.quantity.Balls'), t('transactions.clerk'), `${t('common.amount')} (${t('clubs.singular')})`, `${t('common.amount')} (Intl.)`, t('transactions.prevBalance'), t('transactions.newBalance'), t('common.note')];
        const rows: Array<Record<string, string>> = data.record.map(at => ({
            timestamp: displayDatetime(at.timestamp),
            clearedAt: at.clearedAt === undefined ? '' : displayDatetime(at.clearedAt),
            account: [at.billing.lastName, at.billing.firstName].filter(notBlank).join(', '),
            wallet: at.walletType === WalletType.Club ? t('wallets.types.Club') : t('wallets.types.International'),
            type: t(`transactions.types.${at.type}`),
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            purpose: notBlank(at.data.purpose) ? t(`transactions.purposes.${at.data.purpose!}`) : '',
            device: at.deviceName ?? '',
            port: at.data.portName ?? '',
            value: String(at.data.value ?? ''),
            balls: String(at.data.ballQuantity ?? ''),
            clerk: at.clerkName ?? '',
            amountClub: at.walletType === WalletType.Club ? formatAmount(at.data.amount) : '',
            amountIntl: at.walletType === WalletType.International ? formatAmount(at.data.amount) : '',
            previousBalance: formatAmount(at.previousAmount),
            newBalance: formatAmount(at.newAmount),
            note: at.note ?? ''
        }));

        utils.sheet_add_json(worksheet, rows, { origin: 'A5' });
        utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });

        formatCellAsNumber(worksheet, 5, 8, rows.length);
        formatCellAsNumber(worksheet, 5, 9, rows.length);
        formatCellAsNumber(worksheet, 5, 11, rows.length, '0.00');
        formatCellAsNumber(worksheet, 5, 12, rows.length, '0.00');
        formatCellAsNumber(worksheet, 5, 13, rows.length, '0.00');
        formatCellAsNumber(worksheet, 5, 14, rows.length, '0.00');

        worksheet['!cols'] = computeColumnLengths(rows);

        let nextRow = rows.length + 1 + 3 + 2;
        const {bookIn, bookOut, serviceFee, serviceFeePercentage, payout, totalBallQty} = data;
        const footerNodes = [
            { key: `${t('common.total')} ${t('transactions.directions.BookIn')} (${t('wallets.types.Club')})`, value: bookIn.clubSum },
            { key: `${t('common.total')} ${t('transactions.directions.BookIn')} (${t('wallets.types.International')})`, value: bookIn.intlSum },
            { key: `${t('common.total')} ${t('transactions.directions.BookIn')}`, value: bookIn.totalSum },
            { key: `${t('common.total')} ${t('transactions.directions.BookOut')} (${t('wallets.types.Club')})`, value: bookOut.clubSum },
            { key: `${t('common.total')} ${t('transactions.directions.BookOut')} (${t('wallets.types.International')})`, value: bookOut.intlSum },
            { key: `${t('common.total')} ${t('transactions.directions.BookOut')}`, value: bookOut.totalSum },
            { key: `${t('transactions.totalBallsOutput')}`, value: totalBallQty, format: '0' },
            { key: `Pin Point ${t('clubs.serviceFee')} (${serviceFeePercentage ?? '0'} %)`, value: serviceFee },
            { key: 'Payout', value: payout },
        ];

        const merges = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 11 } },
            { s: { r: 1, c: 0 }, e: { r: 1, c: 11 } },
            { s: { r: 2, c: 0 }, e: { r: 2, c: 11 } },
        ];

        // eslint-disable-next-line no-restricted-syntax
        for (const node of footerNodes) {
            nextRow += 1;
            utils.sheet_add_aoa(worksheet, [[node.key]], { origin: { r: nextRow, c: 0 } });
            utils.sheet_add_aoa(worksheet, [[formatAmount(node.value)]], { origin: { r: nextRow, c: 9 } });

            formatCellAsNumber(worksheet, nextRow, 9, 1, node.format ?? '0.00');

            merges.push({ s: { r: nextRow, c: 0 }, e: { r: nextRow, c: 8 } }, { s: { r: nextRow, c: 9 }, e: { r: nextRow, c: 11 } });
        }

        worksheet['!merges'] = merges;

        writeFile(workbook, `${t('stats.title')} - ${t('stats.types.Transactions')} - ${club.name} - ${dayjs(from).format(IsoDateFormat.Date)} - ${dayjs(until).format(IsoDateFormat.Date)}.xlsx`, { compression: true });
    }

    function handleExport() {
        exportToExcel()
            .catch(console.error);
    }

    return (
        <Button
                variant="outlined"
                startDecorator={<IosShareRoundedIcon />}
                onClick={handleExport}
            >
                {t('actions.export')}
            </Button>
    );
}