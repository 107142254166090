import {Alert, Box, Button, Divider, Grid, Option, Sheet, Stack, Typography} from '@mui/joy';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React, {useEffect, useState} from 'react';
import {Input} from '../../component/input';
import {Select} from '../../component/select';
import {type Club, type ClubRequest, ClubType, useClubApi} from '../../data/club';
import {ClubTypeChip} from './club-type-chip';
import {Checkbox} from '../../component/checkbox';
import {useLocalization} from '../../context/localization';
import {useAuthContext} from '../../context/auth';
import {UserRole} from '../../data/user';

interface ClubEditorFormElement extends HTMLFormElement {
    readonly elements: HTMLFormControlsCollection & {
        name: HTMLInputElement;
        ltaCountry: HTMLInputElement;
        ltaType: HTMLInputElement;
        ltaClub: HTMLInputElement;
        addressAddressLine1: HTMLInputElement;
        addressAddressLine2: HTMLInputElement;
        addressZipCode: HTMLInputElement;
        addressCity: HTMLInputElement;
        addressCountry: HTMLInputElement;
        contactPerson: HTMLInputElement;
        contactEmail: HTMLInputElement;
        contactPhone: HTMLInputElement;
        supportEmail: HTMLInputElement;
        supportPhone: HTMLInputElement;
        offlineSessionDuration: HTMLInputElement;
        rangefee: HTMLInputElement;
        pinpointServiceFee: HTMLInputElement;
        maxScanDuration: HTMLInputElement;
        intlWalletEnabled: HTMLInputElement;
        automaticallyAccountActivationEnabled: HTMLInputElement;
    };
}

interface ClubFormProperties {
    club: Club;
    onSave: () => void;
    onCancel?: () => void;
}

export function ClubForm({club, onSave, onCancel}: ClubFormProperties) {
    const {t} = useLocalization();
    const {put} = useClubApi();
    const {role} = useAuthContext();

    const [type, setType] = useState<ClubType>();
    const [error, setError] = useState<Error>();

    useEffect(() =>
        {
            if (club?.type !== undefined && club?.type !== type) setType(club.type);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [club?.type]);

    function handleFormSubmit(event: React.FormEvent<ClubEditorFormElement>) {
        event.preventDefault();

        if (club !== undefined) {
            const formElements = event.currentTarget.elements;

            const data: ClubRequest = {
                name: formElements.name.value,
                type: role === UserRole.Root
                    ? (type ?? club.type)
                    : club.type,
                address: {
                    addressLine1: formElements.addressAddressLine1.value,
                    addressLine2: formElements.addressAddressLine2.value,
                    zipCode: formElements.addressZipCode.value,
                    city: formElements.addressCity.value,
                    country: formElements.addressCountry.value,
                },
                geoLocation: {
                    latitude: 0,
                    longitude: 0,
                },
                contactPerson: formElements.contactPerson.value,
                contact: {
                    email: formElements.contactEmail.value,
                    phone: formElements.contactPhone.value,
                },
                support: {
                    email: formElements.supportPhone.value,
                    phone: formElements.supportEmail.value,
                },
                intlWalletEnabled: formElements.intlWalletEnabled.checked,
                automaticallyAccountActivationEnabled: formElements.automaticallyAccountActivationEnabled.checked,
                rangefee: formElements.rangefee.valueAsNumber,
                lta: role === UserRole.Root
                    ? `${formElements.ltaCountry.value}/${formElements.ltaType.value}/${formElements.ltaClub.value}`
                    : club.lta,
                offlineSessionDuration: formElements.offlineSessionDuration.valueAsNumber,
                pinpointServiceFee: role === UserRole.Root
                    ? formElements.pinpointServiceFee.valueAsNumber
                    : club.pinpointServiceFee,
                maxScanDuration: formElements.maxScanDuration.valueAsNumber,
            };

            put(club.id, data)
                .then(onSave)
                .catch(error_ => {
                    console.error('Failed to put club data', error_);
                    setError(new Error('Failed to update.'));
                });
        }
    }

    return (
        <form onSubmit={handleFormSubmit}>
            <Grid container spacing={3}>
                <Grid xs={12} lg={8}>
                    <Sheet
                        variant="outlined"
                        sx={{
                            p: 2
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Typography level="title-lg">{t('common.identity')}</Typography>
                            </Grid>
                            <Grid xs={12} md={12} xl={6}>
                                <Input
                                    label={t('common.name')}
                                    FormControlProps={{
                                        required: true
                                    }}
                                    InputProps={{
                                        name: 'name',
                                        defaultValue: club.name
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} md={4} xl={2}>
                                <Input
                                    label={`LTA (${t('common.country')})`}
                                    InputProps={{
                                        type: 'number',
                                        inputMode: 'numeric',
                                        name: 'ltaCountry',
                                        defaultValue: club.lta?.split('/')[0]
                                    }}
                                    FormControlProps={{
                                        disabled: role !== UserRole.Root
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} md={4} xl={2}>
                                <Input
                                    label={`LTA (${t('common.type')})`}
                                    InputProps={{
                                        type: 'number',
                                        inputMode: 'numeric',
                                        name: 'ltaType',
                                        defaultValue: club.lta?.split('/')[1]
                                    }}
                                    FormControlProps={{
                                        disabled: role !== UserRole.Root
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} md={4} xl={2}>
                                <Input
                                    label={`LTA (${t('clubs.singular')})`}
                                    InputProps={{
                                        type: 'number',
                                        inputMode: 'numeric',
                                        name: 'ltaClub',
                                        defaultValue: club.lta?.split('/')[2]
                                    }}
                                    FormControlProps={{
                                        disabled: role !== UserRole.Root
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Divider sx={{my: 3, mx: -2}}/>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Typography level="title-lg">{t('common.details')}</Typography>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <Input
                                    label={t('common.addresses.line1')}
                                    InputProps={{
                                        name: 'addressAddressLine1',
                                        defaultValue: club.address?.addressLine1
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} md={6}>
                                <Input
                                    label={t('common.addresses.line2')}
                                    InputProps={{
                                        name: 'addressAddressLine2',
                                        defaultValue: club.address?.addressLine2
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <Input
                                    label={t('common.zipcode')}
                                    InputProps={{
                                        type: 'number',
                                        inputMode: 'numeric',
                                        name: 'addressZipCode',
                                        defaultValue: club.address?.zipCode
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <Input
                                    label={t('common.city')}
                                    InputProps={{
                                        name: 'addressCity',
                                        defaultValue: club.address?.city
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <Input
                                    label={t('common.country')}
                                    InputProps={{
                                        name: 'addressCountry',
                                        defaultValue: club.address?.country
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Divider sx={{my: 3, mx: -2}}/>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Typography level="title-lg">{t('common.communication')}</Typography>
                            </Grid>
                            <Grid xs={12} sm={12} md={6}>
                                <Input
                                    label={t('clubs.contactPerson')}
                                    InputProps={{
                                        name: 'contactPerson',
                                        defaultValue: club.contactPerson
                                    }}
                                />
                            </Grid>
                            <Grid
                                md={6}
                                sx={theme => ({[theme.breakpoints.down('md')]: {display: 'none'}})}
                            />
                            <Grid xs={12} sm={6}>
                                <Typography level="title-md" gutterBottom>{t('common.contact')}</Typography>
                                <Input
                                    label={t('common.email')}
                                    InputProps={{
                                        startDecorator: <EmailRoundedIcon fontSize="inherit"/>,
                                        type: 'email',
                                        inputMode: 'email',
                                        name: 'contactEmail',
                                        defaultValue: club.contact?.email
                                    }}
                                />
                                <Box my={2}/>
                                <Input
                                    label={t('common.phone')}
                                    InputProps={{
                                        startDecorator: <LocalPhoneRoundedIcon fontSize="inherit"/>,
                                        name: 'contactPhone',
                                        defaultValue: club.contact?.phone
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <Typography level="title-md" gutterBottom>{t('common.support')}</Typography>
                                <Input
                                    label={t('common.email')}
                                    InputProps={{
                                        startDecorator: <EmailRoundedIcon fontSize="inherit"/>,
                                        type: 'email',
                                        inputMode: 'email',
                                        name: 'supportEmail',
                                        defaultValue: club.support?.email
                                    }}
                                />
                                <Box my={2}/>
                                <Input
                                    label={t('common.phone')}
                                    InputProps={{
                                        startDecorator: <LocalPhoneRoundedIcon fontSize="inherit"/>,
                                        name: 'supportPhone',
                                        defaultValue: club.support?.phone
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Sheet>
                </Grid>
                <Grid xs={12} lg={4}>
                    <Sheet
                        variant="outlined"
                        sx={{
                            p: 2
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Typography level="title-lg">{t('common.settings')}</Typography>
                            </Grid>
                            <Grid xs={12}>
                                <Select
                                    label={t('common.type')}
                                    options={Object.values(ClubType)}
                                    renderOption={o => (
                                        <Option key={o} value={o}><ClubTypeChip type={o}/></Option>
                                    )}
                                    SelectProps={{
                                        placeholder: t('hints.select'),
                                        value: type,
                                        onClear: () => {
                                            setType(undefined);
                                        },
                                        onChange: (_, value) => {
                                            setType(value ?? undefined);
                                        },
                                        renderValue: o =>
                                            o === null
                                                ? ''
                                                : <ClubTypeChip type={o.value}/>
                                    }}
                                    FormControlProps={{
                                        required: true,
                                        disabled: role !== UserRole.Root
                                    }}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Checkbox
                                    CheckboxProps={{
                                        label: t('clubs.intWalletEnabled'),
                                        name: 'intlWalletEnabled',
                                        defaultChecked: club.intlWalletEnabled,
                                    }}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Checkbox
                                    CheckboxProps={{
                                        label: t('clubs.autoAccountActivation'),
                                        name: 'automaticallyAccountActivationEnabled',
                                        defaultChecked: club.automaticallyAccountActivationEnabled,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Divider sx={{my: 3, mx: -2}}/>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Input
                                    label={t('clubs.serviceFee')}
                                    InputProps={{
                                        endDecorator: '%',
                                        type: 'number',
                                        inputMode: 'numeric',
                                        name: 'pinpointServiceFee',
                                        defaultValue: club.pinpointServiceFee
                                    }}
                                    FormControlProps={{
                                        disabled: role !== UserRole.Root
                                    }}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Input
                                    label="Rangefee"
                                    InputProps={{
                                        startDecorator: '€',
                                        type: 'number',
                                        inputMode: 'numeric',
                                        name: 'rangefee',
                                        defaultValue: club.rangefee
                                    }}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Input
                                    label={t('clubs.offlineSessionDuration')}
                                    InputProps={{
                                        endDecorator: t('timeUnits.Hours'),
                                        type: 'number',
                                        inputMode: 'numeric',
                                        name: 'offlineSessionDuration',
                                        defaultValue: club.offlineSessionDuration
                                    }}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Input
                                    label={t('clubs.maxScanDuration')}
                                    InputProps={{
                                        endDecorator: t('timeUnits.Milliseconds'),
                                        type: 'number',
                                        inputMode: 'numeric',
                                        name: 'maxScanDuration',
                                        defaultValue: club.maxScanDuration
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Sheet>
                </Grid>
            </Grid>

            {error !== undefined && (
                <Alert color="danger">
                    {error.message}
                </Alert>
            )}

            <Divider sx={{my: 2}}/>
            <Stack
                direction={{sm: 'column', md: 'row'}}
                gap={2}
            >
                <Button
                    startDecorator={<SaveRoundedIcon/>}
                    type="submit"
                >
                    {t('actions.save')}
                </Button>
                {onCancel !== undefined && (
                    <Button
                        startDecorator={<CloseRoundedIcon/>}
                        variant="outlined"
                        color="neutral"
                        onClick={onCancel}
                    >
                        {t('actions.cancel')}
                    </Button>
                )}

            </Stack>
        </form>
    );
}