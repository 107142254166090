import React from 'react';
import {Button, type ColorPaletteProp, Divider, Modal, ModalClose, ModalDialog, Stack, Typography} from '@mui/joy';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {useLocalization} from '../context/localization';

interface ConfirmDialogProperties {
    open: boolean;
    title: string;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
    color?: ColorPaletteProp;
}

export function ConfirmDialog({open, title, message, onConfirm, onCancel, color}: ConfirmDialogProperties) {
    const {t} = useLocalization();

    return (
        <Modal open={open} onClose={onCancel}>
            <ModalDialog
                minWidth="sm"
            >
                <ModalClose />
                <Typography level="title-lg" color={color}>{title}</Typography>
                <Divider />
                <Typography>{message}</Typography>
                <Divider />
                <Stack
                    sx={{ pt: 1 }}
                    direction="row"
                    justifyContent="flex-end"
                    gap={2}
                >
                    <Button
                        color={color}
                        onClick={onConfirm}
                        startDecorator={<CheckRoundedIcon />}
                    >
                        {t('actions.confirm')}
                    </Button>
                    <Button
                        variant="outlined"
                        color="neutral"
                        startDecorator={<CloseRoundedIcon />}
                        onClick={onCancel}
                    >
                        {t('actions.cancel')}
                    </Button>
                </Stack>
            </ModalDialog>
        </Modal>
    );
}