import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Divider, Grid, Option, Typography} from '@mui/joy';
import {useAsync} from 'react-async-hook';
import {useLocalization} from '../../context/localization';
import {type CenterAccountCreateRequest, useAccountApi} from '../../data/account';
import {FormModal} from '../../component/form-modal';
import {Input} from '../../component/input';
import {Select} from '../../component/select';
import {ClubSelect} from '../../component/club-select';
import {type AccountGroupOptionView, useAccountGroupApi} from '../../data/account-group';
import {Checkbox} from '../../component/checkbox';
import {AuthWrapper, useAuthContext} from '../../context/auth';
import {UserRole} from '../../data/user';

interface AccountCreatorProperties {
    open: boolean;
    onCancel: () => void;
}

export function AccountCreator({open, onCancel}: AccountCreatorProperties) {
    return (
        <AuthWrapper>
            <ContentView {...{ open, onCancel }} />
        </AuthWrapper>
    );
}

interface FormElements extends HTMLFormControlsCollection {
    title: HTMLInputElement;
    firstName: HTMLInputElement;
    lastName: HTMLInputElement;
    email: HTMLInputElement;
    phone: HTMLInputElement;
    addressAddressLine1: HTMLInputElement;
    addressAddressLine2: HTMLInputElement;
    addressZipCode: HTMLInputElement;
    addressCity: HTMLInputElement;
    addressCountry: HTMLInputElement;
    memberNumber: HTMLInputElement;
    associationNumber: HTMLInputElement;
    rangefeeEnabled: HTMLInputElement;
}

interface AccountCreatorFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

function ContentView({open, onCancel}: AccountCreatorProperties) {
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role, clubId: authClubId} = useAuthContext();
    const {create} = useAccountApi();
    const {listOptions} = useAccountGroupApi();

    const [clubId, setClubId] = useState(authClubId);
    const [accountGroup, setAccountGroup] = useState<AccountGroupOptionView>();

    const {result: groups} = useAsync(async () =>
            (clubId === undefined)
                ? undefined
                : await listOptions(clubId)
        , [clubId]);

    function handleClubIdChange(id: string|undefined) {
        setClubId(id);
        setAccountGroup(undefined);
    }

    async function handleSubmit(event: React.FormEvent<AccountCreatorFormElement>) {
        event.preventDefault();

        if (clubId !== undefined) {
            const formElements = event.currentTarget.elements;

            const data: CenterAccountCreateRequest = {
                email: formElements.email.value,
                title: formElements.title.value,
                firstName: formElements.firstName.value,
                lastName: formElements.lastName.value,
                phone: formElements.phone.value,
                address: {
                    addressLine1: formElements.addressAddressLine1.value,
                    addressLine2: formElements.addressAddressLine2.value,
                    zipCode: formElements.addressZipCode.value,
                    city: formElements.addressCity.value,
                    country: formElements.addressCountry.value,
                },
                memberNumber: formElements.memberNumber.value,
                associationNumber: formElements.associationNumber.value,
                rangefeeEnabled: formElements.rangefeeEnabled.checked,
                accountGroupId: accountGroup?.id,
                clubId,
            };

            const account = await create(data);
            navigate(`/accounts/${account?.id}`);
        }
    }

    return (
        <FormModal
            open={open}
            onCancel={onCancel}
            onSave={handleSubmit}
        >
            <Typography level="title-lg">
                {t('accounts.addNew')}
            </Typography>
            <Divider />
            <Grid container spacing={2}>
                {role === UserRole.Root && (
                    <Grid xs={12}>
                        <ClubSelect
                            required
                            defaultClubId={clubId}
                            onChange={handleClubIdChange}
                        />
                    </Grid>
                )}
                <Grid xs={12}>
                    <Select
                        label={t('accountGroups.singular')}
                        options={groups ?? []}
                        renderOption={o => (
                            <Option key={o.id} value={o}>{o.name}</Option>
                        )}
                        SelectProps={{
                            placeholder: t('hints.select'),
                            value: accountGroup,
                            onClear: () => { setAccountGroup(undefined); },
                            onChange: (_, value) => { setAccountGroup(value ?? undefined); },
                            renderValue: o =>
                                o === null
                                    ? ''
                                    : o.value.name
                        }}
                    />
                </Grid>
                <Grid xs={12} sm={4}>
                    <Input
                        label={t('common.title')}
                        InputProps={{
                            name: 'title',
                        }}
                    />
                </Grid>
                <Grid xs={12} sm={4}>
                    <Input
                        label={t('common.firstName')}
                        InputProps={{
                            name: 'firstName',
                        }}
                        FormControlProps={{
                            required: true
                        }}
                    />
                </Grid>
                <Grid xs={12} sm={4}>
                    <Input
                        label={t('common.lastName')}
                        InputProps={{
                            name: 'lastName',
                        }}
                        FormControlProps={{
                            required: true
                        }}
                    />
                </Grid>
                <Grid xs={12}>
                    <Input
                        label={t('common.email')}
                        InputProps={{
                            name: 'email',
                            type: 'email',
                            inputMode: 'email'
                        }}
                        FormControlProps={{
                            required: true
                        }}
                    />
                </Grid>
                <Grid xs={12}>
                    <Input
                        label={t('common.phone')}
                        InputProps={{
                            name: 'phone'
                        }}
                    />
                </Grid>
                <Grid xs={12}>
                    <Divider />
                </Grid>
                <Grid xs={12}>
                    <Typography level="title-md"><strong>{t('common.address')}</strong></Typography>
                </Grid>
                <Grid xs={12} md={6}>
                    <Input
                        label={t('common.addresses.line1')}
                        InputProps={{
                            name: 'addressAddressLine1',
                        }}
                        FormControlProps={{
                            required: true
                        }}
                    />
                </Grid>
                <Grid xs={12} md={6}>
                    <Input
                        label={t('common.addresses.line2')}
                        InputProps={{
                            name: 'addressAddressLine2',
                        }}
                    />
                </Grid>
                <Grid xs={12} md={4}>
                    <Input
                        label={t('common.zipcode')}
                        InputProps={{
                            type: 'number',
                            inputMode: 'numeric',
                            name: 'addressZipCode',
                        }}
                        FormControlProps={{
                            required: true
                        }}
                    />
                </Grid>
                <Grid xs={12} md={4}>
                    <Input
                        label={t('common.city')}
                        InputProps={{
                            name: 'addressCity',
                        }}
                        FormControlProps={{
                            required: true
                        }}
                    />
                </Grid>
                <Grid xs={12} md={4}>
                    <Input
                        label={t('common.country')}
                        InputProps={{
                            name: 'addressCountry',
                        }}
                        FormControlProps={{
                            required: true
                        }}
                    />
                </Grid>
                <Grid xs={12}>
                    <Divider />
                </Grid>
                <Grid xs={12}>
                    <Typography level="title-md"><strong>{t('common.settings')}</strong></Typography>
                </Grid>
                <Grid xs={12} sm={6}>
                    <Input
                        label={t('accounts.memberNumber')}
                        InputProps={{
                            name: 'memberNumber'
                        }}
                    />
                </Grid>
                <Grid xs={12} sm={6}>
                    <Input
                        label="ÖGV/DGV Nr."
                        InputProps={{
                            name: 'associationNumber'
                        }}
                    />
                </Grid>
                <Grid xs={12}>
                    <Checkbox
                        CheckboxProps={{
                            label: `Rangefee ${t('common.active')}`,
                            name: 'rangefeeEnabled'
                        }}
                    />
                </Grid>
            </Grid>
        </FormModal>
    );
}