import {useNavigate} from 'react-router-dom';
import {Body, Breadcrumbs, DataTable, type Pageable, Title, useFlag, usePagination} from '@mlyngvo/common-ui';
import {
    Button, Chip, Divider,
    Dropdown, FormControl, FormLabel,
    IconButton,
    List, ListDivider,
    ListItemButton,
    ListItemContent, ListItemDecorator,
    Menu,
    MenuButton,
    MenuItem, Option, Select, Stack,
    Typography
} from '@mui/joy';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import React, {useEffect, useState} from 'react';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {displayDate, handleExplicitClick, notBlank} from '../../utils';
import {ConfirmDialog} from '../../component/confirm-dialog';
import {
    AccountGroupFilterStatus,
    type AccountGroupListView,
    useAccountGroupApi
} from '../../data/account-group';
import {useLocalization, useTableI18n} from '../../context/localization';
import {AccountGroupTimeframe} from './account-group-timeframe';
import {AccountGroupExpirationChip} from './account-group-expiration-chip';
import {ClubSelect} from '../../component/club-select';
import {ClubLabel} from '../../component/club-label';
import {AccountGroupCreator} from './creator';
import {AuthWrapper, useAuthContext} from '../../context/auth';
import {UserRole} from '../../data/user';
import {RouterLink} from '../../component/link';

const StorageKey = 'page-account-groups';

export function AccountGroupList() {
    return (
        <AuthWrapper>
            <ContentView />
        </AuthWrapper>
    );
}

function ContentView() {
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role, clubId} = useAuthContext();
    const {list} = useAccountGroupApi();

    const [creatorMode, setCreatorMode, clearCreatorMode] = useFlag(false);

    const pagination = usePagination(({
        paginationKey: `${StorageKey}:${role}`,
        fetch: async (pageable: Pageable<AccountGroupListView>) => {
            const p = pageable;
            if (clubId !== undefined) {
                p.filter = {
                    ...p.filter,
                    clubId
                };
            }
            return await list(p);
        }
    }));

    async function handleDelete() {
        try {
            await pagination.onReload();
        } catch (error) {
            console.error('Failed to handle delete', error);
        }
    }

    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('accountGroups.title') }
                    ]}
                />
            )}
            title={(
                <Title
                    title={t('accountGroups.title')}
                    actions={(
                        <Button
                            color="primary"
                            startDecorator={<AddRoundedIcon />}
                            size="sm"
                            onClick={setCreatorMode}
                            disabled={role === UserRole.User}
                        >
                            {t('accountGroups.addNew')}
                        </Button>
                    )}
                />
            )}
        >
            <DataTable
                {...pagination}
                stickyLastColumn
                i18n={{
                    ...useTableI18n(),
                    searchLabel: t('accountGroups.search')
                }}
                headers={[
                    { label: t('common.name'), sortKey: ['name'], width: 140 },
                    { label: t('accountGroups.expirationDate'), sortKey: ['expirationDate'], width: 80 },
                    { label: t('accountGroups.activeTimeframe'), sortKey: ['startHour', 'endHour'], width: 80 },
                    { label: t('accounts.title'), width: 40 },
                    { label: t('clubs.singular'), width: 180 },
                    { label: '', width: 60 },
                ]}
                renderTableRow={group => <TableRow key={group.id} {...{ group }} onDelete={handleDelete} />}
                renderListRow={group => <ListRow key={group.id}  {...{ group }} />}
                renderFilter={(onFilter, filter) => <Filter {...{ onFilter, filter }} />}
            />

            <AccountGroupCreator
                open={creatorMode}
                onCancel={clearCreatorMode}
            />
        </Body>
    );
}

function TableRow({group: {id, name, expirationDate, startHour, endHour, clubId, clubName, totalAccounts, defaultOnSignup}, onDelete}: { group: AccountGroupListView, onDelete: () => Promise<void> }) {
    const {t} = useLocalization();
    const {deleteAccountGroup} = useAccountGroupApi();

    const [deletionPromptOpen, setDeletionPromptOpen, clearDeletionPromptOpen] = useFlag(false);

    function handleDelete() {
        deleteAccountGroup(id)
            .then(async () => {
                await onDelete();
                setDeletionPromptOpen();
            })
            .catch(console.error);
    }

    return (
        <>
            <tr>
                <td>
                    <RouterLink href={`/account-groups/${id}`}>
                        <Typography level="body-xs"><strong>{name}</strong></Typography>
                        <Stack
                            direction="row"
                            gap={1}
                        >
                            <AccountGroupExpirationChip {...{ expirationDate }} />
                            {defaultOnSignup && (
                                <Chip
                                    color="primary"
                                    size="sm"
                                    variant="solid"
                                >
                                    {t('common.default')}
                                </Chip>
                            )}
                        </Stack>
                    </RouterLink>
                </td>
                <td>
                    {notBlank(expirationDate) && <Typography level="body-xs">{displayDate(expirationDate ?? '')}</Typography>}
                </td>
                <td>
                    <Typography level="body-xs">
                        <AccountGroupTimeframe hideLabel {...{ startHour, endHour }} />
                    </Typography>
                </td>
                <td>
                    <Typography startDecorator={<GroupRoundedIcon />}>
                        {totalAccounts}
                    </Typography>
                </td>
                <td>
                    <ClubLabel label={clubName} clubId={clubId} noWrap />
                </td>
                <td onClick={event_ => {
                    event_.stopPropagation();
                }}>
                    <ItemMenu
                        {...{
                            id,
                            onDelete: setDeletionPromptOpen,
                        }}
                    />
                </td>
            </tr>
            <ConfirmDialog
                open={deletionPromptOpen}
                onCancel={clearDeletionPromptOpen}
                onConfirm={handleDelete}
                title={t('accountGroups.deleteTitle')}
                message={t('accountGroups.deletePrompt')}
                color="danger"
            />
        </>
    );
}

function ListRow({group: {id, name, expirationDate, startHour, endHour, totalAccounts, clubName, clubId, defaultOnSignup}}: { group: AccountGroupListView }) {
    const navigate = useNavigate();
    const {t} = useLocalization();

    return (
        <List
            size="sm"
            sx={{
                '--ListItem-paddingX': 0,
            }}
        >
            <ListItemButton
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    '&:hover': {
                        background: 'none !important'
                    }
                }}
                onClick={() => {
                    navigate(`/account-groups/${id}`);
                }}
            >
                <ListItemContent>
                    <Typography fontWeight={600} gutterBottom sx={{ mt: 0.5 }}>{name}</Typography>
                    <Typography level="body-sm" startDecorator={<GroupRoundedIcon />}>
                        {totalAccounts}
                    </Typography>
                    <ClubLabel label={clubName} clubId={clubId} />
                    {notBlank(expirationDate) && <Typography level="body-xs">{t('accountGroups.expirationDate')}: {displayDate(expirationDate ?? '')}</Typography>}
                    <AccountGroupTimeframe {...{ startHour, endHour }} />
                </ListItemContent>
                <AccountGroupExpirationChip {...{ expirationDate }} />
                {defaultOnSignup && (
                    <Chip
                        color="primary"
                        size="sm"
                        variant="solid"
                    >
                        {t('common.default')}
                    </Chip>
                )}
            </ListItemButton>
            <ListDivider />
        </List>
    );
}

function Filter({onFilter, filter = {}}: { onFilter: (filter: Record<string, any>) => void, filter: Record<string, any>|undefined }) {
    const {t} = useLocalization();
    const {role} = useAuthContext();

    const [status, setStatus] = useState('');

    useEffect(() =>
        {
            if (typeof filter.status === 'string'
                && filter.status !== status
            ) {
                setStatus(filter.status);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filter]);

    function handleStatus(s: string) {
        setStatus(s);
        onFilter({
            ...filter,
            status: s
        });
    }

    function handleClubId(clubId?: string) {
        onFilter({
            ...filter,
            clubId
        });
    }

    return (
        <>
            <FormControl size="sm">
                <FormLabel>{t('common.status')}</FormLabel>
                <Select
                    size="sm"
                    slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                    value={status}
                    onChange={(_, newValue) => { handleStatus(newValue ?? ''); }}
                >
                    <Option value="">{t('common.all')}</Option>
                    <Option value={AccountGroupFilterStatus.Active}>{t('common.active')}</Option>
                    <Option value={AccountGroupFilterStatus.Expired}>{t('common.expired')}</Option>
                </Select>
            </FormControl>
            {role === UserRole.Root && (
                <ClubSelect
                    small
                    defaultClubId={filter.clubId}
                    onChange={handleClubId}
                />
            )}
        </>
    );
}

interface ItemMenuProperties {
    id: string;
    onDelete: () => void;
}

function ItemMenu({id, onDelete}: ItemMenuProperties) {
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role} = useAuthContext();

    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <Dropdown open={menuOpen} onOpenChange={(event_, open) => { setMenuOpen(open); }}>
            <MenuButton
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
            >
                <MoreHorizRoundedIcon />
            </MenuButton>
            <Menu size="sm" sx={{ minWidth: 140 }} >
                <MenuItem
                    onClick={event_ =>
                    { handleExplicitClick(event_, () => { navigate(`/account-groups/${id}`); }); }
                    }
                >
                    <ListItemDecorator>
                        <VisibilityRoundedIcon />
                    </ListItemDecorator>
                    {t('actions.view')}
                </MenuItem>
                <MenuItem
                    onClick={event_ =>
                    { handleExplicitClick(event_, () => { navigate(`/account-groups/${id}/edit`); }); }
                    }
                    disabled={role === UserRole.User}
                >
                    <ListItemDecorator>
                        <EditRoundedIcon />
                    </ListItemDecorator>
                    {t('actions.edit')}
                </MenuItem>
                <Divider />
                <MenuItem
                    color="danger"
                    onClick={event_ =>
                    { handleExplicitClick(event_, () => { onDelete(); }); }
                    }
                    disabled={role === UserRole.User}
                >
                    <ListItemDecorator>
                        <DeleteRoundedIcon />
                    </ListItemDecorator>
                    {t('actions.delete')}
                </MenuItem>
            </Menu>
        </Dropdown>
    );
}