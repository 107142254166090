import {
    FormControl,
    type FormControlProps,
    FormLabel,
    Radio as MuiRadio,
    RadioGroup,
    type RadioGroupProps as MuiRadioGroupProperties,
    type RadioProps as MuiRadioProperties
} from '@mui/joy';
import React from 'react';

interface RadioInputProperties {
    label: string;
    options: Array<{ label: string; value: string }>
    RadioGroupProps?: MuiRadioGroupProperties;
    RadioProps?: Omit<MuiRadioProperties, 'checked'|'value'|'name'>;
    FormControlProps?: FormControlProps;
}

export function RadioInput({label, options, RadioGroupProps, RadioProps, FormControlProps}: RadioInputProperties) {
    return (
        <FormControl {...FormControlProps}>
            <FormLabel
                sx={{
                    typography: 'body-sm',
                    fontWeight: 600
                }}
            >
                {label}
            </FormLabel>
            <RadioGroup
                {...RadioGroupProps}
            >
                {options.map(o => (
                    <MuiRadio
                        key={o.value}
                        label={o.label}
                        value={o.value}
                        {...RadioProps}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
}