import React, {useState} from 'react';
import {Divider, Grid, Typography} from '@mui/joy';
import {useNavigate} from 'react-router-dom';
import {useLocalization} from '../../context/localization';
import {FormModal} from '../../component/form-modal';
import {Input} from '../../component/input';
import {type NotificationRequest, useNotificationApi} from '../../data/notification';
import {Textarea} from '../../component/textarea';
import {ClubSelect} from '../../component/club-select';
import {AuthWrapper, useAuthContext} from '../../context/auth';
import {UserRole} from '../../data/user';

interface NotificationCreatorProperties {
    open: boolean;
    onCancel: () => void;
}

export function NotificationCreator({open, onCancel}: NotificationCreatorProperties) {
    return (
        <AuthWrapper>
            <ContentView {...{ open, onCancel }} />
        </AuthWrapper>
    );
}

interface FormElements extends HTMLFormControlsCollection {
    title: HTMLInputElement;
    content: HTMLInputElement;
}

interface NotificationCreatorFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

function ContentView({open, onCancel}: NotificationCreatorProperties) {
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role, clubId: authClubId} = useAuthContext();
    const {create} = useNotificationApi();

    const [clubId, setClubId] = useState(authClubId);

    async function handleSubmit(event: React.FormEvent<NotificationCreatorFormElement>) {
        event.preventDefault();

        if (clubId !== undefined) {
            const formElements = event.currentTarget.elements;

            const data: NotificationRequest = {
                title: formElements.title.value,
                content: formElements.content.value,
                clubId
            };

            const club = await create(data);
            navigate(`/notifications/${club?.id}`);
        }
    }

    return (
        <FormModal
            open={open}
            onCancel={onCancel}
            onSave={handleSubmit}
        >
            <Typography level="title-lg">
                {t('notifications.addNew')}
            </Typography>
            <Divider />
            <Grid container spacing={2}>
                {role === UserRole.Root && (
                    <Grid xs={12}>
                        <ClubSelect
                            required
                            defaultClubId={clubId}
                            onChange={setClubId}
                        />
                    </Grid>
                )}
                <Grid xs={12}>
                    <Input
                        label={t('common.title')}
                        InputProps={{
                            name: 'title',
                        }}
                        FormControlProps={{
                            required: true
                        }}
                    />
                </Grid>
                <Grid xs={12}>
                    <Textarea
                        label={t('notifications.content')}
                        InputProps={{
                            name: 'content',
                            minRows: 7,
                            maxRows: 15,
                        }}
                        FormControlProps={{
                            required: true
                        }}
                    />
                </Grid>
            </Grid>
        </FormModal>
    );
}