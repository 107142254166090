import React from 'react';
import {Typography} from '@mui/joy';
import EuroRoundedIcon from '@mui/icons-material/EuroRounded';
import {displayAmount} from '../utils';

export function ColoredAmount({amount, negative = false, disabled = false}: { amount: number, negative?: boolean, disabled?: boolean }) {
    return (
        <Typography
            color={(disabled || amount === 0) ? 'neutral' : (negative ? 'danger' : 'success')}
            startDecorator={<EuroRoundedIcon />}
        >
            {negative ? '-' : '+'} {displayAmount(amount)}
        </Typography>
    );
}