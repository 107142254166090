import {useApi} from './api';

export interface AuthorizedUser {
    email: string,
    role: string,
    clubIds: string[],
}

export interface AuthenticationRequest {
    email: string;
    password: string;
    persistent: boolean;
}

export interface AuthenticationResponse {
    accessToken: string;
    refreshToken?: string;
}

interface RefreshTokenResponse {
    accessToken: string;
}

export function useAuthApi() {
    const {post, put, patch} = useApi(true);
    return {
        authenticate: async (request: AuthenticationRequest) =>
            await post<AuthenticationResponse>('/auth', request),
        refreshAccessToken: async (refreshToken: string) =>
            await post<RefreshTokenResponse>('/auth/refresh', { refreshToken }),
        confirmInvitation: async (token: string, password: string) =>
            await patch(`/auth/confirm/${token}`, { password }),
        requestVerification: async (email: string) =>
            await post('/auth/verification', { email }),
        verifyCode: async (email: string, verificationCode: string)=>
            await post('/auth/verification', { email, verificationCode }),
        resetPassword: async (email: string, verificationCode: string, newPassword: string) =>
            await put('/auth/reset-password', { email, verificationCode, newPassword }),
    };
}