import {createPageableParameters, type Page, type Pageable} from '@mlyngvo/common-ui';
import {useApi} from './api';
import {type AccountTransaction, type TransactionDirection} from './statistic';

export enum WalletType {

    Club = 'Club',
    International = 'International',
}

export interface Wallet {
    id: string;
    type: WalletType;
    amount: number;
    clubId?: string;
    clubName?: string;
    accountId?: string;
    accountName?: string;
    createdAt: string;
}

export interface WalletAmountRequest {
    direction: TransactionDirection;
    amount: number;
    note: string;
}

export enum WalletFilterState {
    ActiveBalance = 'ActiveBalance',
    BlankBalance = 'BlankBalance'
}

export function useWalletApi() {
    const {get, put} = useApi();
    return {
        list: async (pageable: Pageable<Wallet>, type: WalletType) =>
            await get<Page<Wallet>>(`/center/wallets?type=${type}&${createPageableParameters(pageable)}`),
        find: async (id: string) =>
            await get<Wallet>(`/center/wallets/${id}`),
        listTransactions: async (id: string, pageable: Pageable<AccountTransaction>) =>
            await get<Page<AccountTransaction>>(`/center/wallets/${id}/transactions?${createPageableParameters(pageable)}`),
        processWalletAmount: async (id: string, request: WalletAmountRequest) =>
            await put(`/center/wallets/${id}/amount`, request),
    };
}