import React, {useEffect, useState} from 'react';
import {useAsyncCallback} from 'react-async-hook';
import {debounce} from 'lodash';
import {Autocomplete, CircularProgress, FormControl, FormLabel} from '@mui/joy';
import {useLocalization} from '../context/localization';
import {type AccountOptionView, useAccountApi} from '../data/account';

interface AccountSelectProperties {
    onChange: (accountId?: string) => void;
    clubId?: string;
    defaultAccountId?: string;
    disabled?: boolean;
}

export function AccountSelect({onChange, clubId: pClubId, defaultAccountId, disabled = false}: AccountSelectProperties) {
    const {t} = useLocalization();
    const {listOptions, find} = useAccountApi();

    const [account, setAccount] = useState<AccountOptionView>();

    const {result: options, loading, execute} = useAsyncCallback(async (clubId?: string, needle?: string) => await listOptions(clubId, needle));

    useEffect(() => {
        if (!disabled) {
            execute(pClubId)
                .then(() => { setAccount(undefined); })
                .catch(console.error);
        }
    }, [pClubId, disabled, execute]);

    useEffect(() =>
        {
            if (!disabled
                && typeof defaultAccountId === 'string'
                && defaultAccountId !== account?.id) {
                if (defaultAccountId.length > 0) {
                    find(defaultAccountId)
                        .then(setAccount)
                        .catch(console.error);
                } else {
                    setAccount(undefined);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [pClubId, disabled, defaultAccountId]);

    const handleAccountSearch = debounce((needle: string) => {
        execute(pClubId, needle)
            .catch(console.error);
    }, 300);

    function handleAccountId(a: AccountOptionView|null) {
        setAccount(a ?? undefined);
        onChange(a?.id);
    }

    return (
        <FormControl disabled={disabled}>
            <FormLabel
                sx={{
                    typography: 'body-sm',
                    fontWeight: 600
                }}
            >
                {t('accounts.singular')}
            </FormLabel>
            <Autocomplete
                placeholder={t('hints.select')}
                // eslint-disable-next-line unicorn/no-null
                value={account ?? null}
                onChange={(_, value) => { handleAccountId(value); }}
                onInputChange={(_, value) => handleAccountSearch(value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name ?? ''}
                options={options ?? []}
                loading={loading}
                endDecorator={
                    loading
                        ? <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
                        : undefined
                }
                sx={{ bgcolor: 'background.body' }}
            />
        </FormControl>
    );
}