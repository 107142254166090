import React, {useState} from 'react';
import {Alert, Button, Card, CardContent, Container, Stack, Typography} from '@mui/joy';
import {Input, useFlag} from "@mlyngvo/common-ui";
import {useParams} from "react-router";
import {useAuthApi} from "../../data/auth";
import {useAsyncCallback} from "react-async-hook";
import {hashPassword} from "../../utils";

export function UserAccepts() {
    const {token = ''} = useParams<{ token: string }>();
    const {confirmInvitation} = useAuthApi();

    const {loading, error, execute} = useAsyncCallback(confirmInvitation);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [success, setSuccess] = useFlag(false);

    async function handleSubmit() {
        if (password.length > 3 && password === confirmPassword) {
            console.log('passowrd', password);
            await execute(token, hashPassword(password));
            setSuccess();
        }
    }

    return (
        <Container
            sx={{
                mt: 8
            }}
            maxWidth="sm"
        >
            {!success && (
                <Card>
                    <Typography level="title-md">Bitte geben Sie Ihr Passwort ein, um den Einladungsprozess abzuschließen.</Typography>
                    <CardContent>
                        <Stack
                            direction="column"
                            gap={2}
                        >
                            <Input
                                label="Passwort"
                                FormControlProps={{
                                    disabled: loading
                                }}
                                InputProps={{
                                    type: 'password',
                                    onChange: ev => setPassword(ev.target.value)
                                }}
                            />
                            <Input
                                label="Passwort bestätigen"
                                FormControlProps={{
                                    disabled: loading
                                }}
                                InputProps={{
                                    type: 'password',
                                    onChange: ev => setConfirmPassword(ev.target.value)
                                }}
                            />
                            <Button onClick={handleSubmit} disabled={loading}>Absenden</Button>
                            {error !== undefined && (
                                <Alert color="danger">{error.message ?? 'Error'}</Alert>
                            )}
                        </Stack>
                    </CardContent>
                </Card>
            )}
            {success && (
                <Card>
                    <Typography>Einladung bestätigt. Sie können diese Seite schließen.</Typography>
                </Card>
            )}
        </Container>
    );
}