import {Typography} from '@mui/joy';
import React from 'react';
import {displayAmount} from '../../utils';

export function AmountDisplay({amount}: { amount: number }) {
    const pattern = /(\d)()(?=(\d{3})+(?!\d))/g;
    return (
        <Typography
            component="span"
            sx={{
                fontFamily: 'monospace'
            }}
        >
            {displayAmount(amount).replaceAll(pattern, '$1 $2')}
        </Typography>
    );
}