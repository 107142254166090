import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {useAsync} from 'react-async-hook';
import React, {useEffect, useState} from 'react';
import {Body, Breadcrumbs, Title, useFlag} from '@mlyngvo/common-ui';
import {Box, Button, Chip, Divider, Grid, Option, Sheet, Stack, Typography} from '@mui/joy';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import SportsGolfRoundedIcon from '@mui/icons-material/SportsGolfRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MeetingRoomRoundedIcon from '@mui/icons-material/MeetingRoomRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {ConfirmDialog} from '../../component/confirm-dialog';
import {Input} from '../../component/input';
import {Select} from '../../component/select';
import {DeviceTypeChip} from './device-type-chip';
import {Checkbox} from '../../component/checkbox';
import {type DeviceRequest, DeviceType, useDeviceApi} from '../../data/device';
import {useLocalization} from '../../context/localization';
import {AuthWrapper, useAuthContext} from '../../context/auth';
import {UserRole} from '../../data/user';

export function DeviceEditor() {
    return (
        <AuthWrapper allowRoles={[ UserRole.Root, UserRole.Admin ]}>
            <ContentView />
        </AuthWrapper>
    );
}


interface FormElements extends HTMLFormControlsCollection {
    name: HTMLInputElement;
    rangefeeRequired: HTMLInputElement;
    offlineUsageAllowed: HTMLInputElement;
    openOnConnectEnabled: HTMLInputElement;
}

interface DeviceEditorFormElement extends HTMLFormElement {
    readonly elements: FormElements
}

function ContentView() {
    const {id} = useParams<{id: string}>();
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role} = useAuthContext();
    const {find, put, deleteDevice} = useDeviceApi();

    const {result: device, error: loadError, loading} = useAsync(async () =>
            (id === undefined) ? undefined : await find(id)
        , [id]);

    const [type, setType] = useState<DeviceType>();
    const [deletionPromptOpen, setDeletionPromptOpen, clearDeletionPromptOpen] = useFlag(false);
    const [error, setError] = useState<Error>();

    useEffect(() => {
        if (device !== undefined) {
            setType(device.type);
        }
    }, [device]);

    function handleFormSubmit(event: React.FormEvent<DeviceEditorFormElement>) {
        event.preventDefault();

        if (device !== undefined) {
            const formElements = event.currentTarget.elements;

            const data: DeviceRequest = {
                name: formElements.name.value,
                type: type ?? device.type,
                model: device.model,
                clubId: device.clubId,
                rangefeeRequired: formElements.rangefeeRequired.checked,
                offlineUsageAllowed: formElements.offlineUsageAllowed.checked,
                openOnConnectEnabled: formElements.openOnConnectEnabled.checked,
            };

            put(device.id, data)
                .then(() => { navigate(`/devices/${device.id}`); })
                .catch(error_ => {
                    console.error('Failed to put device data', error_);
                    setError(new Error('Failed to update.'));
                });
        }
    }

    function handleDelete() {
        if (device !== undefined) {
            deleteDevice(device.id)
                .then(() => { navigate('/devices'); })
                .catch(setError);
        }
    }

    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('devices.title'), onClick: () => { navigate('/devices'); } },
                        { label: device === undefined ? t('common.details') : device.name, onClick: () => { navigate(`/devices/${id}`); } },
                        { label: t('actions.edit') }
                    ]}
                />
            )}
            title={(
                <Title
                    title={device === undefined ? t('devices.singular') : device.name}
                    actions={(
                        <Button
                            variant="outlined"
                            color="neutral"
                            startDecorator={<ArrowBackIosRoundedIcon />}
                            size="sm"
                            onClick={() => { navigate(`/devices/${device?.id}`); }}
                        >
                            {t('actions.back')}
                        </Button>
                    )}
                />
            )}
            loading={loading}
            error={error ?? loadError}
        >
            {device !== undefined && (
                <>
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={3}>
                            <Grid xs={12} lg={8}>
                                <Sheet
                                    variant="outlined"
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: {
                                            xs: 'column',
                                            sm: 'row',
                                        },
                                        columnGap: 3,
                                        rowGap: 2
                                    }}
                                >
                                    <div>
                                        <Typography level="body-xs" gutterBottom><strong>ID</strong></Typography>
                                        <Chip
                                            size="sm"
                                            variant="outlined"
                                            sx={theme => ({
                                                minWidth: 80,
                                                textAlign: 'center',
                                                fontFamily: theme.fontFamily.code,
                                                fontSize: '0.63rem'
                                            })}
                                        >
                                            {id}
                                        </Chip>
                                    </div>
                                    <div>
                                        <Typography level="body-xs"
                                                    gutterBottom><strong>{t('devices.model')}</strong></Typography>
                                        {device.model}
                                    </div>
                                    <div>
                                        <Typography level="body-xs"
                                                    gutterBottom><strong>{t('devices.serialNumber')}</strong></Typography>
                                        {device.serialNumber}
                                    </div>
                                </Sheet>
                                <Box my={2}/>
                                <Sheet
                                    variant="outlined"
                                    sx={{
                                        p: 2
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid xs={12}>
                                            <Typography level="title-lg">{t('common.details')}</Typography>
                                        </Grid>
                                        <Grid xs={12} md={12} xl={6}>
                                            <Input
                                                label={t('common.name')}
                                                InputProps={{
                                                    name: 'name',
                                                    defaultValue: device.name
                                                }}
                                                FormControlProps={{
                                                    required: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={12} md={12} xl={6}>
                                            <Select
                                                label={t('common.type')}
                                                options={Object.values(DeviceType)}
                                                renderOption={o => (
                                                    <Option key={o} value={o}><DeviceTypeChip type={o}/></Option>
                                                )}
                                                SelectProps={{
                                                    placeholder: t('hints.select'),
                                                    value: type,
                                                    onChange: (_, value) => {
                                                        setType(value ?? undefined);
                                                    },
                                                    renderValue: o =>
                                                        o === null
                                                            ? ''
                                                            : <DeviceTypeChip type={o.value}/>
                                                }}
                                                FormControlProps={{
                                                    required: true,
                                                    disabled: role !== UserRole.Root
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Sheet>
                            </Grid>
                            <Grid xs={12} lg={4}>
                                <Sheet
                                    variant="outlined"
                                    sx={{
                                        p: 2
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid xs={12}>
                                            <Typography level="title-lg">{t('common.settings')}</Typography>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Stack
                                                direction="column"
                                                rowGap={1}
                                            >
                                                <Typography
                                                    level="body-md"
                                                    gutterBottom
                                                    startDecorator={<SportsGolfRoundedIcon />}
                                                >
                                                    {t('devices.bvmOptions')}
                                                </Typography>
                                                <Checkbox
                                                    CheckboxProps={{
                                                        label: t('devices.rangefeeRequired'),
                                                        name: 'rangefeeRequired',
                                                        defaultChecked: device.rangefeeRequired,
                                                    }}
                                                    FormControlProps={{
                                                        disabled: (type ?? device.type) !== DeviceType.BallVendingMachine
                                                    }}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid xs={12}>
                                            <Stack
                                                direction="column"
                                                rowGap={1}
                                            >
                                                <Typography
                                                    level="body-md"
                                                    gutterBottom
                                                    startDecorator={<MeetingRoomRoundedIcon />}
                                                >
                                                    {t('devices.entranceOptions')}
                                                </Typography>
                                                <Checkbox
                                                    CheckboxProps={{
                                                        label: t('devices.offlineUsageAllowed'),
                                                        name: 'offlineUsageAllowed',
                                                        defaultChecked: device.offlineUsageAllowed,
                                                    }}
                                                    FormControlProps={{
                                                        disabled: (type ?? device.type) !== DeviceType.Entrance
                                                    }}
                                                />
                                                <Checkbox
                                                    CheckboxProps={{
                                                        label: t('devices.openOnConnectEnabled'),
                                                        name: 'openOnConnectEnabled',
                                                        defaultChecked: device.openOnConnectEnabled,
                                                    }}
                                                    FormControlProps={{
                                                        disabled: (type ?? device.type) !== DeviceType.Entrance
                                                    }}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Sheet>
                            </Grid>
                        </Grid>
                        <Divider sx={{my: 2}}/>
                        <Stack
                            direction={{sm: 'column', md: 'row'}}
                            gap={2}
                        >
                            <Button
                                startDecorator={<SaveRoundedIcon/>}
                                type="submit"
                            >
                                {t('actions.save')}
                            </Button>
                            <Button
                                startDecorator={<CloseRoundedIcon/>}
                                variant="outlined"
                                color="neutral"
                                onClick={() => { navigate(`/devices/${device?.id}`); }}
                            >
                                {t('actions.cancel')}
                            </Button>
                            <Box flexGrow={1} sx={theme => ({[theme.breakpoints.down('md')]: {display: 'none'}})}/>
                            {role === UserRole.Root && (
                                <Button
                                    startDecorator={<DeleteRoundedIcon />}
                                    variant="outlined"
                                    color="danger"
                                    onClick={setDeletionPromptOpen}
                                >
                                    {t('actions.delete')}
                                </Button>
                            )}
                        </Stack>
                    </form>
                    <ConfirmDialog
                        open={deletionPromptOpen}
                        onCancel={clearDeletionPromptOpen}
                        onConfirm={handleDelete}
                        title={t('devices.deleteTitle')}
                        message={t('devices.deletePrompt')}
                        color="danger"
                    />
                </>
            )}
        </Body>
    );
}