import React, {type FormEvent, useMemo, useState} from 'react';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {useAsync} from 'react-async-hook';
import {AvatarEditor, Body, Breadcrumbs, Title, useFlag} from '@mlyngvo/common-ui';
import {
    Alert,
    AspectRatio, Avatar,
    Box,
    Divider, Grid,
    IconButton, Link, List, ListItem, ListItemContent, Modal,
    ModalClose,
    ModalDialog,
    Sheet,
    Stack,
    Typography
} from '@mui/joy';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import {type User, UserRole, useUserApi} from '../../data/user';
import {useLocalization} from '../../context/localization';
import {hashPassword, notBlank, notNull} from '../../utils';
import {UserRoleChip} from './user-role-chip';
import {useClubApi} from '../../data/club';
import {DropdownButton} from '../../component/dropdown-button';
import {FormModal} from '../../component/form-modal';
import {Input} from '../../component/input';
import {ConfirmDialog} from '../../component/confirm-dialog';
import {authStore} from '../../store/auth';
import {AuthWrapper, useAuthContext} from '../../context/auth';
import {ClubTypeChip} from '../club';
import {RouterLink} from '../../component/link';

export function UserDetails() {
    return (
        <AuthWrapper allowRoles={[ UserRole.Root, UserRole.Admin ]}>
            <ContentView />
        </AuthWrapper>
    );
}

interface FormElements extends HTMLFormControlsCollection {
    password: HTMLInputElement;
    confirmPassword: HTMLInputElement;
}

interface ChangePasswordForm extends HTMLFormElement {
    readonly elements: FormElements
}

function ContentView() {
    const {id} = useParams<{id: string}>();
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role} = useAuthContext();
    const {find, setAvatar, setPassword, takeover} = useUserApi();

    const {result: user, error, loading} = useAsync(async () =>
            (id === undefined) ? undefined : await find(id)
        , [id]);

    const [changePasswordMode, setChangePasswordMode, clearChangePasswordMode] = useFlag(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordError, setPasswordError] = useState<string>();

    const [takeoverMode, setTakeoverMode, clearTakeoverMode] = useFlag(false);

    async function handleSetAvatar(data: string) {
        if (id !== undefined) {
            try {
                await setAvatar(id, data.slice(22));
            } catch (error_) {
                console.error('Failed to set logo', error_);
            }
        }
    }

    async function handleChangePassword(event_: FormEvent<ChangePasswordForm>) {
        event_.preventDefault();

        if (user !== undefined) {
            const {elements} = event_.currentTarget;
            const password = elements.password.value;
            const confirmPasswords = elements.confirmPassword.value;

            if (password !== confirmPasswords) {
                setPasswordError(t('users.passwordMismatch'));
                return;
            }
                setPasswordError(undefined);


            await setPassword(user.id, hashPassword(password));
            clearChangePasswordMode();
        }
    }

    function handleTakeover() {
        if (user !== undefined) {
            takeover(user.id)
                .then(credentials => {
                    if (credentials !== undefined) authStore.startTakeover(credentials);
                })
                .catch(console.error);
        }
    }

    const name = useMemo(() => [user?.title, [user?.lastName, user?.firstName].filter(notNull).join(', ')].join(' '), [user]);
    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('users.title'), onClick: () => { navigate('/users'); } },
                        { label: notBlank(name) ? name : t('common.details') },
                    ]}
                />
            )}
            title={(
                <Title
                    title={notBlank(name) ? name : t('users.singular')}
                    actions={(
                        <DropdownButton
                            items={[
                                { label: t('actions.edit'), startDecorator: <EditRoundedIcon />, onClick: () => { navigate(`/users/${user?.id}/edit`); } },
                                { label: t('users.changePassword'), onClick: () => { setChangePasswordMode(); } },
                                ...(role === UserRole.Root
                                        ? [{ label: t('users.loginAsUser'), onClick: () => { setTakeoverMode(); } }]
                                        : []
                                )
                            ]}
                        />
                    )}
                />
            )}
            {...{ error, loading }}
        >
            {user !== undefined && (
                <>
                    <Box
                        mt={2}
                    >
                        <Stack
                            rowGap={3}
                        >
                            <UserMeta
                                {...user}
                                onAvatarChange={handleSetAvatar}
                            />
                            <Stack
                                direction={{ sm: 'column', md: 'row' }}
                                rowGap={3}
                                columnGap={3}
                            >
                                <UserInfos {...user} />
                                <UserClubs {...user} />
                            </Stack>
                        </Stack>
                    </Box>
                    <FormModal
                        open={changePasswordMode}
                        onCancel={clearChangePasswordMode}
                        onSave={handleChangePassword}
                        successMessage={t('users.passwordChangedMessage')}
                    >
                        <Typography level="title-lg">
                            {t('users.changePassword')}
                        </Typography>
                        <Divider />
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Input
                                    label={t('users.newPassword')}
                                    FormControlProps={{
                                        required: true
                                    }}
                                    InputProps={{
                                        name: 'password',
                                        type: passwordVisible ? 'text' : 'password',
                                        endDecorator: (
                                            <IconButton
                                                onClick={() => { setPasswordVisible(value => !value); }}
                                            >
                                                {passwordVisible
                                                    ? <VisibilityOffRoundedIcon />
                                                    : <VisibilityRoundedIcon />
                                                }
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Input
                                    label={t('users.confirmPassword')}
                                    FormControlProps={{
                                        required: true
                                    }}
                                    InputProps={{
                                        name: 'confirmPassword',
                                        type: passwordVisible ? 'text' : 'password'
                                    }}
                                />
                            </Grid>
                            {passwordError !== undefined && (
                                <Grid xs={12}>
                                    <Alert color="danger">
                                        {passwordError}
                                    </Alert>
                                </Grid>
                            )}
                        </Grid>
                    </FormModal>
                    <ConfirmDialog
                        open={takeoverMode}
                        title={t('users.loginAsUser')}
                        message={t('users.loginAsUserPrompt')}
                        onConfirm={handleTakeover}
                        onCancel={() => { clearTakeoverMode(); }}
                    />
                </>
            )}
        </Body>
    );
}

type UserMetaProperties = Pick<User, 'id'|'role'|'avatarUrl'|'position'> & {
    onAvatarChange: (data: string) => Promise<void>
}

function UserMeta({id, role, avatarUrl,  position, onAvatarChange}: UserMetaProperties) {
    const {t} = useLocalization();

    const [counter, setCounter] = useState(0);
    const [avatarEditorOpen, setAvatarEditorOpen, clearAvatarEditorOpen] = useFlag(false);
    const [previewUrl, setPreviewUrl] = useState(avatarUrl);

    function handleAvatar(data: string) {
        onAvatarChange(data)
            .then(() => {
                clearAvatarEditorOpen();
                setPreviewUrl(`/api/users/${id}/avatar`);
                setCounter(c => c + 1);
            })
            .catch(console.error);
    }

    return (
        <Stack
            direction={{ xs: 'column', sm: 'row' }}
            gap={3}
        >
            <Stack
                direction="column"
                spacing={1}
                sx={{ position: 'relative' }}
            >
                <AspectRatio
                    ratio="1"
                    maxHeight={150}
                    variant="outlined"
                    objectFit="contain"
                    sx={{
                        flex: 1,
                        minWidth: 100,
                        borderRadius: '7px',
                        bgcolor: 'common.white'
                    }}
                >
                    {notBlank(previewUrl)
                        ? (
                            <img
                                src={`${previewUrl}?s=${counter}`}
                                loading="lazy"
                                alt={id}
                            />
                        )
                        : <div><AccountBoxRoundedIcon sx={{ fontSize: '3rem', opacity: 0.5 }} /></div>
                    }
                </AspectRatio>
                <IconButton
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    sx={theme => ({
                        bgcolor: 'background.body',
                        position: 'absolute',
                        zIndex: 2,
                        borderRadius: '50%',
                        right: -7,
                        bottom: -5,
                        boxShadow: 'sm',
                        [theme.breakpoints.down('sm')]: {
                            display: 'none'
                        }
                    })}
                    onClick={setAvatarEditorOpen}
                >
                    <EditRoundedIcon />
                </IconButton>
            </Stack>
            <Sheet
                variant="outlined"
                sx={{
                    p: 2,
                    flexGrow: 1
                }}
            >
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    height="100%"
                    rowGap={2}
                    columnGap={3}
                >
                    <div>
                        <Typography level="body-xs" gutterBottom><strong>{t('users.role')}</strong></Typography>
                        <UserRoleChip role={role} />
                    </div>
                    <div>
                        <Typography level="body-xs" gutterBottom><strong>{t('users.position')}</strong></Typography>
                        {notBlank(position) ? position : '-'}
                    </div>
                </Stack>
            </Sheet>
            <Modal open={avatarEditorOpen} onClose={clearAvatarEditorOpen}>
                <ModalDialog>
                    <ModalClose />
                    <Typography level="title-lg">{t('users.editAvatar')}</Typography>
                    <Divider />
                    <AvatarEditor
                        defaultImageUrl={previewUrl ?? undefined}
                        onSave={handleAvatar}
                        onCancel={clearAvatarEditorOpen}
                        i18n={{
                            uploadFile: t('actions.uploadPhoto'),
                            save: t('actions.save'),
                            cancel: t('actions.cancel')
                        }}
                    />
                </ModalDialog>
            </Modal>
        </Stack>
    );
}

type UserInfosProperties = Pick<User, 'title'|'firstName'|'lastName'|'email'|'phone'>

function UserInfos({title, firstName, lastName, email, phone}: UserInfosProperties) {
    const {t} = useLocalization();

    return (
        <Sheet
            variant="outlined"
            sx={{ flex: 1 }}
        >
            <Box p={2}>
                <Typography level="title-md">{t('common.details')}</Typography>
                <Typography level="body-sm">{t('users.detailsDescription')}</Typography>
            </Box>
            <Divider />
            <Stack
                p={2}
                flexDirection="column"
                rowGap={2}
            >
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('common.name')}</strong></Typography>
                    <Typography>{[title, lastName, firstName].filter(notNull).join(' ')}</Typography>
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('common.email')}</strong></Typography>
                    <Typography startDecorator={<EmailRoundedIcon fontSize="inherit" />}><Link href={`mailto:${email}`}>{email}</Link></Typography>
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('common.phone')}</strong></Typography>
                    <Typography startDecorator={<LocalPhoneRoundedIcon fontSize="inherit" />}><Link href={`tel:${phone}`}>{phone}</Link></Typography>
                </div>
            </Stack>
        </Sheet>
    );
}

type UserClubsProperties = Pick<User, 'clubs'>

function UserClubs({clubs}: UserClubsProperties) {
    const {t} = useLocalization();
    const {find} = useClubApi();

    const {result: list} = useAsync(async () =>
        await Promise.all(Object.keys(clubs)
            .map(async id => await find(id)))
        , [clubs]);

    return (
        <Sheet
            variant="outlined"
            sx={{ flex: 1 }}
        >
            <Box p={2}>
                <Typography level="title-md">{t('clubs.title')}</Typography>
                <Typography level="body-sm">{t('users.clubsDescription')}</Typography>
            </Box>
            <Divider />
            <Stack
                p={2}
                flexDirection="column"
                rowGap={2}
            >
                <List>
                    {list?.filter(notNull).map(f => (
                        <ListItem
                            key={f?.id}
                            component={RouterLink}
                            href={`/clubs/${f?.id}`}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 2,
                                borderRadius: 7,
                                py: 1,
                            }}
                        >
                            <Avatar size="sm" src={f?.logoUrl} />
                            <ListItemContent
                                component={Stack}
                                direction="column"
                                gap={0.5}
                            >
                                <Typography level="body-xs">{f?.name}</Typography>
                                {f !== undefined && <ClubTypeChip type={f.type}/>}
                            </ListItemContent>
                            <KeyboardArrowRightRoundedIcon />
                        </ListItem>
                    ))}
                </List>
            </Stack>
        </Sheet>
    );
}