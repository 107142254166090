import {useApi} from './api';
import {type User, type UserRequest} from './user';

export interface AuthorizedUser {
    id: string;
    nameShort?: string;
    position?: string;
    role: string;
    clubIds: string[];
}

export function useCenterApi() {
    const {get, put, patch} = useApi();
    return {
        me: async () =>
            await get<AuthorizedUser>('/center'),
        getProfile: async () =>
            await get<User>('/center/profile'),
        updateProfile: async (request: UserRequest) =>
            await put('/center/profile', request),
        setProfileAvatar: async (data: string) =>
            await put('/center/profile/avatar', { data }),
        setProfilePassword: async (password: string) =>
            await patch('/center/profile/password', { password })
    };
}