import React from 'react';
import {FormControl, FormLabel, Textarea as MuiTextarea, type TextareaProps as MuiTextareaProperties, type FormControlProps} from '@mui/joy';

interface TextareaProperties {
    label: string;
    InputProps?: MuiTextareaProperties;
    FormControlProps?: FormControlProps;
}
export function Textarea({label, InputProps: {sx, ...inputProperties} = {}, FormControlProps}: TextareaProperties) {
    return (
        <FormControl {...FormControlProps}>
            <FormLabel
                sx={{
                    typography: 'body-sm',
                    fontWeight: 600
                }}
            >
                {label}
            </FormLabel>
            <MuiTextarea
                sx={{
                    bgcolor: 'background.body',
                    ...sx
                }}
                {...inputProperties}
            />
        </FormControl>
    );
}