import dayjs from 'dayjs';
import React from 'react';
import {StatusChip} from '../../component/status-chip';
import {useLocalization} from '../../context/localization';
import {notBlank} from '../../utils';

export function NotificationPublishedChip({publishedAt}: { publishedAt?: string }) {
    const {t} = useLocalization();

    return (
        <StatusChip
            enabled={notBlank(publishedAt) && dayjs(publishedAt).isBefore(dayjs())}
            trueLabel={t('common.published')}
            falseLabel={t('common.pending')}
        />
    );
}