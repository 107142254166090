import {useNavigate} from 'react-router-dom';
import {Body, Breadcrumbs, DataTable, type Pageable, Title, useFlag, usePagination} from '@mlyngvo/common-ui';
import React, {useEffect, useState} from 'react';
import {
    Chip,
    Divider,
    Dropdown, FormControl, FormLabel,
    IconButton, List, ListDivider,
    ListItemButton, ListItemContent,
    ListItemDecorator,
    Menu,
    MenuButton,
    MenuItem, Option, Select,
    Typography
} from '@mui/joy';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {ConfirmDialog} from '../../component/confirm-dialog';
import {handleExplicitClick} from '../../utils';
import {DeviceTypeChip} from './device-type-chip';
import {type DeviceListView, DeviceType, useDeviceApi} from '../../data/device';
import {useLocalization, useTableI18n} from '../../context/localization';
import {ClubSelect} from '../../component/club-select';
import {ClubLabel} from '../../component/club-label';
import {AuthWrapper, useAuthContext} from '../../context/auth';
import {UserRole} from '../../data/user';
import {RouterLink} from '../../component/link';

const StorageKey = 'page-devices';

export function DeviceList() {
    return (
        <AuthWrapper>
            <ContentView />
        </AuthWrapper>
    );
}

function ContentView() {
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role, clubId} = useAuthContext();
    const {list} = useDeviceApi();

    const pagination = usePagination({
        paginationKey: `${StorageKey}:${role}`,
        fetch: async (pageable: Pageable<DeviceListView>) => {
            const p = pageable;
            if (clubId !== undefined) {
                p.filter = {
                    ...p.filter,
                    clubId
                };
            }
            return await list(p);
        }
    });

    async function handleDelete() {
        try {
            await pagination.onReload();
        } catch (error) {
            console.error('Failed to handle delete', error);
        }
    }

    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('devices.title') }
                    ]}
                />
            )}
            title={(
                <Title
                    title={t('devices.title')}
                />
            )}
        >
            <DataTable
                {...pagination}
                stickyLastColumn
                i18n={{
                    ...useTableI18n(),
                    searchLabel: t('devices.search')
                }}
                headers={[
                    { label: 'ID', sortKey: ['id'], width: 100 },
                    { label: t('common.name'), sortKey: ['name'], width: 300 },
                    { label: t('devices.model'), sortKey: ['model'], width: 100 },
                    { label: t('devices.serialNumber'), sortKey: ['serialNumber'], width: 150 },
                    ...(role === UserRole.Root
                            ? [{ label: t('clubs.singular'), sortKey: ['club.name'] as any, width: 180 }]
                            : []
                    ),
                    { label: '', width: 60 },
                ]}
                renderTableRow={device => <TableRow key={device.id} {...{device}} onDelete={handleDelete} />}
                renderListRow={device => <ListRow key={device.id} {...{device}} />}
                renderFilter={(onFilter, filter) => <Filter {...{ onFilter, filter }} />}
            />
        </Body>
    );
}

function TableRow({device: {id, name, type, model, serialNumber, clubName, clubId}, onDelete}: { device: DeviceListView, onDelete: () => Promise<void> }) {
    const {t} = useLocalization();
    const {deleteDevice} = useDeviceApi();
    const {role} = useAuthContext();

    const [deletionPromptOpen, setDeletionPromptOpen, clearDeletionPromptOpen] = useFlag(false);

    function handleDelete() {
        deleteDevice(id)
            .then(async () => {
                await onDelete();
                setDeletionPromptOpen();
            })
            .catch(console.error);
    }

    return (
        <>
            <tr>
                <td>
                    <RouterLink href={`/devices/${id}`}>
                        <Chip
                            size="sm"
                            variant="outlined"
                            sx={theme => ({
                                minWidth: 80,
                                textAlign: 'center',
                                fontFamily: theme.fontFamily.code,
                                fontSize: '0.63rem'
                            })}
                        >
                            {id}
                        </Chip>
                    </RouterLink>
                </td>
                <td>
                    <RouterLink href={`/devices/${id}`}>
                        <Typography level="body-xs" gutterBottom><strong>{name}</strong></Typography>
                        <DeviceTypeChip type={type}/>
                    </RouterLink>
                </td>
                <td>
                    <Typography level="body-xs">{model}</Typography>
                </td>
                <td>
                    <Typography level="body-xs">{serialNumber}</Typography>
                </td>
                {role === UserRole.Root && (
                    <td>
                        <ClubLabel label={clubName} clubId={clubId} noWrap/>
                    </td>
                )}
                <td
                    onClick={event_ => {
                        event_.stopPropagation();
                    }}
                >
                    <ItemMenu
                        {...{
                            id,
                            onDelete: setDeletionPromptOpen,
                        }}
                    />
                </td>
            </tr>
            <ConfirmDialog
                open={deletionPromptOpen}
                onCancel={clearDeletionPromptOpen}
                onConfirm={handleDelete}
                title={t('devices.deleteTitle')}
                message={t('devices.deletePrompt')}
                color="danger"
            />
        </>
    );
}

function ListRow({device: {id, name, type, clubName, clubId}}: { device: DeviceListView }) {
    const navigate = useNavigate();
    const {role} = useAuthContext();

    return (
        <List
            size="sm"
            sx={{
                '--ListItem-paddingX': 0,
            }}
        >
            <ListItemButton
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    '&:hover': {
                        background: 'none !important'
                    }
                }}
                onClick={() => {
                    navigate(`/devices/${id}`);
                }}
            >
                <ListItemContent>
                    <DeviceTypeChip type={type} />
                    <Typography fontWeight={600} gutterBottom sx={{ mt: 1 }}>{name}</Typography>
                    {role === UserRole.Root && <ClubLabel label={clubName} clubId={clubId} />}
                </ListItemContent>
                <Chip
                    size="sm"
                    variant="outlined"
                    sx={theme => ({
                        minWidth: 80,
                        textAlign: 'center',
                        fontFamily: theme.fontFamily.code,
                        fontSize: '0.63rem'
                    })}
                >
                    {id}
                </Chip>
            </ListItemButton>
            <ListDivider />
        </List>
    );
}

function Filter({onFilter, filter = {}}: { onFilter: (filter: Record<string, any>) => void, filter: Record<string, any>|undefined }) {
    const {t} = useLocalization();
    const {role} = useAuthContext();

    const [type, setType] = useState('');

    useEffect(() =>
        {
            if (typeof filter.type === 'string'
                && filter.type !== type
            ) {
                setType(filter.type);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filter]);

    function handleType(value: string) {
        setType(value);
        onFilter({
            ...filter,
            type: value
        });
    }

    function handleClubId(clubId?: string) {
        onFilter({
            ...filter,
            clubId
        });
    }

    return (
        <>
            <FormControl size="sm">
                <FormLabel>{t('common.type')}</FormLabel>
                <Select
                    size="sm"
                    slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                    value={type}
                    onChange={(_, newValue) => { handleType(newValue ?? ''); }}
                >
                    <Option value="">{t('common.all')}</Option>
                    {Object.values(DeviceType).map(r => (
                        <Option key={r} value={r}>{t(`devices.types.${r}`)}</Option>
                    ))}
                </Select>
            </FormControl>
            {role === UserRole.Root && (
                <ClubSelect
                    small
                    defaultClubId={filter.clubId}
                    onChange={handleClubId}
                />
            )}
        </>
    );
}

interface ItemMenuProperties {
    id: string;
    onDelete: () => void;
}

function ItemMenu({id, onDelete}: ItemMenuProperties) {
    const {t} = useLocalization();
    const navigate = useNavigate();
    const {role} = useAuthContext();

    const [active, setActive] = useState(false);

    return (
        <Dropdown open={active} onOpenChange={(_, open) => { setActive(open); }}>
            <MenuButton
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
            >
                <MoreHorizRoundedIcon />
            </MenuButton>
            <Menu size="sm" sx={{ minWidth: 140 }} >
                <MenuItem
                    onClick={event_ =>
                    { handleExplicitClick(event_, () => { navigate(`/devices/${id}`); }); }
                    }
                >
                    <ListItemDecorator>
                        <VisibilityRoundedIcon />
                    </ListItemDecorator>
                    {t('actions.view')}
                </MenuItem>
                <MenuItem
                    onClick={event_ =>
                    { handleExplicitClick(event_, () => { navigate(`/devices/${id}/edit`); }); }
                    }
                    disabled={role === UserRole.User}
                >
                    <ListItemDecorator>
                        <EditRoundedIcon />
                    </ListItemDecorator>
                    {t('actions.edit')}
                </MenuItem>
                {role === UserRole.Root && (
                    <>
                        <Divider />
                        <MenuItem
                            color="danger"
                            onClick={event_ =>
                            { handleExplicitClick(event_, () => { onDelete(); }); }
                            }
                        >
                            <ListItemDecorator>
                                <DeleteRoundedIcon />
                            </ListItemDecorator>
                            {t('actions.delete')}
                        </MenuItem>
                    </>
                )}
            </Menu>
        </Dropdown>
    );
}