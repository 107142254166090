import React, {useEffect} from 'react';
import {CircularProgress, Modal, ModalDialog, Stack, Typography} from '@mui/joy';
import {useAuthContext} from '../../context/auth';
import {notBlank} from '../../utils';

export function AppAuthRefresh() {
    const {authenticated} = useAuthContext();

    useEffect(() => {
        if (authenticated) {
            const parameters = new URLSearchParams(window.location.search);
            // eslint-disable-next-line no-restricted-syntax
            for (const [k, v] of parameters.entries()) {
                if (k === 'redirectPath' && notBlank(v)) {
                    window.location.replace(v);
                    return;
                }
            }
            window.location.replace('/');
        }
    }, [authenticated]);

    return (
        <Modal open>
            <ModalDialog>
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={2}
                >
                    <CircularProgress />
                    <Typography>Authorization...</Typography>
                </Stack>
            </ModalDialog>
        </Modal>
    );
}