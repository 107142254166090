import React from 'react';
import {Stack, Typography, useTheme} from '@mui/joy';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

export function UserLabel({label, noWrap = false}: { label: string, noWrap?: boolean }) {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            alignItems="center"
            gap={1}
        >
            <PersonRoundedIcon sx={{ color: theme.palette.text.tertiary, fontSize: theme.fontSize.md }}/>
            <Typography
                level="body-xs"
                noWrap={noWrap}
            >
                {label}
            </Typography>
        </Stack>
    );
}