import React, {type ReactElement} from 'react';
import {Chip, type ChipProps, useTheme} from '@mui/joy';
import type {SxProps} from '@mui/joy/styles/types';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import ControlPointDuplicateRoundedIcon from '@mui/icons-material/ControlPointDuplicateRounded';
import QueuePlayNextRoundedIcon from '@mui/icons-material/QueuePlayNextRounded';
import RemoveFromQueueRoundedIcon from '@mui/icons-material/RemoveFromQueueRounded';
import ShoppingBagRoundedIcon from '@mui/icons-material/ShoppingBagRounded';
import {TransactionType} from '../../data/statistic';
import {useLocalization} from '../../context/localization';

export function TransactionTypeChip({type, size = 'sm', ...properties}: ChipProps & { type: TransactionType }) {
    const theme = useTheme();
    const {t} = useLocalization();
    const label = t(`transactions.types.${type}`);
    let icon: ReactElement = <QuestionMarkRoundedIcon />;
    let sx: SxProps = {
        backgroundColor: undefined,
        color: 'white'
    };
    switch (type) {
        case TransactionType.PurchaseCredit: {
            sx.backgroundColor = '#65A30D';
            icon = <ControlPointDuplicateRoundedIcon />;
            break;
        }
        case TransactionType.ReceiveCredit: {
            sx.backgroundColor = '#0284C7';
            icon = <QueuePlayNextRoundedIcon />;
            break;
        }
        case TransactionType.SubtractCredit: {
            sx.backgroundColor = '#D97706';
            icon = <RemoveFromQueueRoundedIcon />;
            break;
        }
        case TransactionType.ConsumeCredit: {
            sx.backgroundColor = '#F43F5E';
            icon = <ShoppingBagRoundedIcon />;
            break;
        }
        default: { break;
        }
    }
    sx = {
        ...sx,
        '& .MuiChip-endDecorator svg': {
            fontSize: theme.fontSize.md
        }
    };
    return <Chip {...{sx, size, props: properties}} endDecorator={icon}>{label}</Chip>;
}