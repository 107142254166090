import {useNavigate} from 'react-router-dom';
import {Body, Breadcrumbs, DataTable, type Pageable, Title, usePagination} from '@mlyngvo/common-ui';
import React, {useEffect, useState} from 'react';
import {
    Chip,
    FormControl,
    FormLabel,
    List, ListDivider,
    ListItemButton,
    ListItemContent,
    Option,
    Select, Stack,
    Typography
} from '@mui/joy';
import EuroRoundedIcon from '@mui/icons-material/EuroRounded';
import {type AccountTransaction, TransactionPurpose} from '../../data/statistic';
import {useLocalization, useTableI18n} from '../../context/localization';
import {usePaymentApi} from '../../data/payment';
import {ClubSelect} from '../../component/club-select';
import {displayAmount, displayDatetime, notBlank} from '../../utils';
import {TransactionStatusChip} from '../statistic/transaction-status-chip';
import {TransactionPurposeChip} from '../statistic/transaction-purpose-chip';
import {ClubLabel} from '../../component/club-label';
import {TransactionModal} from '../statistic/transaction-modal';
import {AuthWrapper, useAuthContext} from '../../context/auth';
import {UserRole} from '../../data/user';

const StorageKey = 'page-payments';

export function PaymentList() {
    return (
        <AuthWrapper>
            <ContentView />
        </AuthWrapper>
    );
}

function ContentView() {
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role, clubId} = useAuthContext();
    const {list} = usePaymentApi();

    const pagination = usePagination({
        paginationKey: `${StorageKey}:${clubId}`,
        fetch: async (pageable: Pageable<AccountTransaction>) => {
            const p = pageable;
            if (clubId !== undefined) {
                p.filter = {
                    ...p.filter,
                    clubId
                };
            }
            return await list(p);
        }
    });

    const [transaction, setTransaction] = useState<AccountTransaction>();

    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('payments.title') }
                    ]}
                />
            )}
            title={(
                <Title
                    title={t('payments.title')}
                />
            )}
        >
            <DataTable
                {...pagination}
                i18n={{
                    ...useTableI18n(),
                    searchLabel: t('payments.search')
                }}
                headers={[
                    { label: 'ID', sortKey: ['data.id'] as any, width: 90 },
                    { label: t('common.timestamp'), sortKey: ['timestamp'], width: 140 },
                    { label: t('common.amount'), sortKey: ['data.amount'], width: 80 },
                    { label: t('transactions.purpose'), sortKey: ['data.purpose'] as any, width: 160 },
                    { label: t('accounts.singular'), sortKey: ['wallet.account.lastName', 'wallet.account.firstName'] as any, width: 140 },
                    ...(role === UserRole.Root
                        ? [{ label: t('clubs.singular'), sortKey: ['club.name'] as any, width: 120 }]
                        : []
                    )
                ]}
                renderTableRow={payment => <TableRow key={payment.uuid} {...{payment}} onClick={() => { setTransaction(payment); }} />}
                renderListRow={payment => <ListRow key={payment.uuid} {...{payment}} onClick={() => { setTransaction(payment); }} />}
                renderFilter={(onFilter, filter) => <Filter {...{ onFilter, filter }} />}
            />
            {transaction !== undefined && <TransactionModal transaction={transaction} onClose={() => { setTransaction(undefined); }} />}
        </Body>
    );
}

function TableRow({payment: {timestamp, clearedAt, data, note, accountName, clubName, clubId}, onClick}: { payment: AccountTransaction, onClick: () => void }) {
    const {role} = useAuthContext();

    return (
        <tr
            onClick={onClick}
        >
            <td>
                <Chip size="sm" variant="solid" color="primary" sx={{ fontFamily: 'monospace' }}><strong>{data.id}</strong></Chip>
            </td>
            <td>
                <Typography level="body-xs">{displayDatetime(timestamp)}</Typography>
                <TransactionStatusChip clearedAt={clearedAt}/>
            </td>
            <td>
                <Typography
                    level="body-md"
                    startDecorator={<EuroRoundedIcon fontSize="inherit" />}
                >
                    {displayAmount(data.amount)}
                </Typography>
            </td>
            <td>
                <Typography level="body-xs" gutterBottom>{note}</Typography>
                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                {notBlank(data.purpose) && <TransactionPurposeChip purpose={data.purpose!}/>}
            </td>
            <td>
                <Typography level="body-xs">{accountName}</Typography>
            </td>
            {role === UserRole.Root && (
                <td>
                    {notBlank(clubName) && notBlank(clubId) && <ClubLabel label={clubName as string} clubId={clubId as string} noWrap/>}
                </td>
            )}
        </tr>
    );
}

function ListRow({payment: {data, accountName, timestamp, clearedAt, clubName, clubId}, onClick}: { payment: AccountTransaction, onClick: () => void }) {
    const {role} = useAuthContext();

    return (
        <List
            size="sm"
            sx={{
                '--ListItem-paddingX': 0,
            }}
        >
            <ListItemButton
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    '&:hover': {
                        background: 'none !important'
                    }
                }}
                onClick={onClick}
            >
                <ListItemContent>
                    <Typography level="body-xs">{displayDatetime(timestamp)}</Typography>
                    <Typography fontWeight={600} gutterBottom sx={{ mt: 1 }}>{accountName}</Typography>
                    <Typography
                        level="body-md"
                        startDecorator={<EuroRoundedIcon fontSize="inherit" />}
                        gutterBottom
                    >
                        {displayAmount(data.amount)}
                    </Typography>
                    <Stack
                        direction="row"
                        gap={1}
                        mb={1}
                    >
                        {notBlank(data.purpose) && <TransactionPurposeChip purpose={data.purpose as TransactionPurpose}/>}
                        <TransactionStatusChip clearedAt={clearedAt}/>
                    </Stack>
                    {(notBlank(clubName) && notBlank(clubId) && role === UserRole.Root) && <ClubLabel label={clubName as string} clubId={clubId as string} />}
                </ListItemContent>
                <Chip
                    size="sm"
                    variant="solid"
                    color="primary"
                    sx={{ fontFamily: 'monospace' }}
                >
                    <strong>{data.id}</strong>
                </Chip>
            </ListItemButton>
            <ListDivider />
        </List>
    );
}

function Filter({onFilter, filter = {}}: { onFilter: (filter: Record<string, any>) => void, filter: Record<string, any>|undefined }) {
    const {t} = useLocalization();
    const {role} = useAuthContext();

    const [purpose, setPurpose] = useState('');

    useEffect(() => {
            if (typeof filter.purpose === 'string'
                && filter.purpose !== purpose
            ) {
                setPurpose(filter.purpose);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filter]);

    function handlePurpose(value: string) {
        setPurpose(value);
        onFilter({
            ...filter,
            purpose: value
        });
    }

    function handleClubId(clubId?: string) {
        onFilter({
            ...filter,
            clubId
        });
    }

    return (
        <>
            <FormControl size="sm">
                <FormLabel>{t('transactions.purpose')}</FormLabel>
                <Select
                    size="sm"
                    slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                    value={purpose}
                    onChange={(_, newValue) => { handlePurpose(newValue ?? ''); }}
                >
                    <Option value="">{t('common.all')}</Option>
                    {Object.values(TransactionPurpose).map(p => (
                        <Option key={p} value={p}>{t(`transactions.purposes.${p}`)}</Option>
                    ))}
                </Select>
            </FormControl>
            {role === UserRole.Root && (
                <ClubSelect
                    small
                    defaultClubId={filter.clubId}
                    onChange={handleClubId}
                />
            )}
        </>
    );
}