import {FormControl, type FormControlProps, FormLabel, useTheme} from '@mui/joy';
import React from 'react';
import {CommonDateTimePicker, type CommonDateTimePickerProperties} from '@mlyngvo/common-ui';
import {useLocalization} from '../context/localization';

interface DateTimePickerProperties {
    label: string;
    PickerProps?: CommonDateTimePickerProperties;
    FormControlProps?: FormControlProps;
}

export function DateTimePicker({label, PickerProps: { sx, ...pickerProperties} = {}, FormControlProps}: DateTimePickerProperties) {
    const theme = useTheme();
    const {language} = useLocalization();
    return (
        <FormControl {...FormControlProps}>
            <FormLabel
                sx={{
                    typography: 'body-sm',
                    fontWeight: 600
                }}
            >
                {label}
            </FormLabel>
            <CommonDateTimePicker
                sx={{
                    background: theme.palette.background.body,
                    ...sx
                }}
                locale={language}
                {...pickerProperties}
            />
        </FormControl>
    );
}