import {createPageableParameters, type Page, type Pageable} from '@mlyngvo/common-ui';
import {useApi} from './api';
import {type Address, type ContactDetails, type GeoLocation} from './common';
import {type TransactionDirection} from './statistic';

export interface TransactionBookmark {
    amount?: number;
    message?: string;
    customAmountGranted: boolean;
    customMessageGranted: boolean;
    direction: TransactionDirection;
    inAppPaymentExclusive: boolean;
}

export enum ClubType {
    TestClub = 'TestClub',
    GolfClub = 'GolfClub',
    GolfCentral = 'GolfCentral',
    GolfResort = 'GolfResort',
    GolfClubCleaning = 'GolfClubCleaning',
    SailingClub = 'SailingClub',
    BilliardClub = 'BilliardClub',
    TennisClub = 'TennisClub',
    ParkingHouse = 'ParkingHouse',
    ParkingZone = 'ParkingZone',
}

export interface Club {
    id: string;
    name: string;
    address?: Address;
    geoLocation?: GeoLocation;
    contactPerson?: string;
    contact?: ContactDetails;
    support?: ContactDetails;
    intlWalletEnabled: boolean;
    rangefee?: number;
    lta?: string;
    type: ClubType;
    enabled: boolean;
    offlineSessionDuration: number;
    pinpointServiceFee?: number;
    automaticallyAccountActivationEnabled: boolean;
    maxScanDuration?: number
    logoUrl: string;
    transactionBookmarks: TransactionBookmark[];
}

export interface ClubRequest {
    name: string;
    type: ClubType;
    address?: Address;
    geoLocation?: GeoLocation;
    contactPerson?: string;
    contact?: ContactDetails;
    support?: ContactDetails;
    intlWalletEnabled: boolean;
    automaticallyAccountActivationEnabled: boolean;
    rangefee?: number;
    lta?: string;
    offlineSessionDuration?: number;
    pinpointServiceFee?: number;
    maxScanDuration?: number;
}

export type ClubListView = Pick<Club, 'id'|'name'|'address'|'lta'|'type'|'enabled'|'logoUrl'>;
export type ClubOptionView = Pick<Club, 'id'|'name'>;

export function useClubApi() {
    const {get, post, put, patch} = useApi();
    return {
        list: async (pageable: Pageable<ClubListView>) =>
            await get<Page<ClubListView>>(`/center/clubs?${createPageableParameters(pageable)}`),
        listOptions: async (needle?: string) =>
            await get<ClubOptionView[]>(`/center/clubs/options?needle=${needle ?? ''}`),
        create: async (request: ClubRequest) =>
            await post<Club>('/center/clubs', request),
        put: async (id: string, request: ClubRequest) =>
            await put(`/center/clubs/${id}`, request),
        find: async (id: string) =>
            await get<Club>(`/center/clubs/${id}`),
        setEnabled: async (id: string, enabled: boolean) =>
            await patch(`/center/clubs/${id}/enabled`, { enabled }),
        setLogo: async (id: string, data: string) =>
            await put(`/center/clubs/${id}/logo`, { data }),
        listTransactionBookmarks: async (id: string) =>
            await get<TransactionBookmark[]>(`/center/clubs/${id}/transaction-bookmarks`),
        setTransactionBookmarks: async (id: string, request: TransactionBookmark[]) =>
            await put(`/center/clubs/${id}/transaction-bookmarks`, request)
    };
}