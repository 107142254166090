import {Body, Breadcrumbs, DataTable, type Pageable, Title, useFlag, usePagination} from '@mlyngvo/common-ui';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    Avatar,
    Button,
    Divider,
    Dropdown, FormControl, FormLabel,
    IconButton,
    List, ListDivider,
    ListItemButton, ListItemContent,
    ListItemDecorator, Menu,
    MenuButton, MenuItem, Option, Select,
    Typography
} from '@mui/joy';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import {handleExplicitClick, notBlank} from '../../utils';
import {useLocalization, useTableI18n} from '../../context/localization';
import {ConfirmDialog} from '../../component/confirm-dialog';
import {type User, UserRole, useUserApi} from '../../data/user';
import {UserRoleChip} from './user-role-chip';
import {ClubSelect} from '../../component/club-select';
import {ClubLabel} from '../../component/club-label';
import {UserCreator} from './creator';
import {AuthWrapper, useAuthContext} from '../../context/auth';
import {authStore} from '../../store/auth';
import {RouterLink} from '../../component/link';

const StorageKey = 'page-users';

export function UserList() {
    return (
        <AuthWrapper allowRoles={[ UserRole.Root, UserRole.Admin ]}>
            <ContentView />
        </AuthWrapper>
    );
}

function ContentView() {
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role, clubId} = useAuthContext();
    const {list} = useUserApi();

    const [creatorMode, setCreatorMode, clearCreatorMode] = useFlag(false);

    const pagination = usePagination({
        paginationKey: `${StorageKey}:${role}`,
        fetch: async (pageable: Pageable<User>) => {
            const p = pageable;
            if (clubId !== undefined) {
                p.filter = {
                    ...p.filter,
                    clubId
                };
            }
            return await list(p);
        }
    });

    async function handleDelete() {
        try {
            await pagination.onReload();
        } catch (error) {
            console.error('Failed to handle delete', error);
        }
    }

    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('users.title') }
                    ]}
                />
            )}
            title={(
                <Title
                    title={t('users.title')}
                    actions={(
                        <Button
                            color="primary"
                            startDecorator={<AddRoundedIcon />}
                            size="sm"
                            onClick={setCreatorMode}
                        >
                            {t('users.addNew')}
                        </Button>
                    )}
                />
            )}
        >
            <DataTable
                {...pagination}
                stickyLastColumn
                i18n={{
                    ...useTableI18n(),
                    searchLabel: t('users.search')
                }}
                headers={[
                    { label: t('users.role'), sortKey: ['role'], width: 80 },
                    { label: t('common.name'), sortKey: ['lastName', 'firstName'], width: 120 },
                    { label: t('common.email'), sortKey: ['email'], width: 140 },
                    { label: t('common.phone'), sortKey: ['phone'], width: 120 },
                    ...(role === UserRole.Root
                            ? [{ label: t('clubs.singular'), width: 180 }]
                            : []
                    ),
                    { label: '', width: 60 },
                ]}
                renderTableRow={user => <TableRow key={user.id} {...{ user }} onDelete={handleDelete} />}
                renderListRow={user => <ListRow key={user.id}  {...{ user }} />}
                renderFilter={(onFilter, filter) => <Filter {...{ onFilter, filter }} />}
            />

            <UserCreator
                open={creatorMode}
                onCancel={clearCreatorMode}
            />
        </Body>
    );
}

function TableRow({user: {id, firstName, lastName, email, phone, role, clubs, avatarUrl}, onDelete}: { user: User, onDelete: () => Promise<void> }) {
    const {t} = useLocalization();
    const {role: authRole} = useAuthContext();
    const {deleteUser, takeover} = useUserApi();

    const [deletionPromptOpen, setDeletionPromptOpen, clearDeletionPromptOpen] = useFlag(false);
    const [takeoverMode, setTakeoverMode, clearTakeoverMode] = useFlag(false);

    function handleDelete() {
        deleteUser(id)
            .then(async () => {
                await onDelete();
                setDeletionPromptOpen();
            })
            .catch(console.error);
    }

    function handleTakeover() {
        takeover(id)
            .then(credentials => {
                if (credentials !== undefined) authStore.startTakeover(credentials);
            })
            .catch(console.error);
    }
    return (
        <>
            <tr>
                <td style={{width: 80}}>
                    <RouterLink
                        href={`/users/${id}`}
                    >
                        <UserRoleChip {...{role}} />
                    </RouterLink>
                </td>
                <td style={{width: 120}}>
                    <RouterLink
                        href={`/users/${id}`}
                        sx={{
                            gap: 2,
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar size="sm" src={avatarUrl} />
                        <Typography level="body-xs" noWrap>
                            <strong>{[lastName, firstName].filter(notBlank).join(', ')}</strong>
                        </Typography>
                    </RouterLink>
                </td>
                <td style={{width: 140}}>
                    <Typography
                        component={RouterLink}
                        href={`/users/${id}`}
                        level="body-xs"
                        noWrap
                    >
                        {email}
                    </Typography>
                </td>
                <td style={{width: 120}}>
                    <Typography
                        component={RouterLink}
                        href={`/users/${id}`}
                        level="body-xs"
                        noWrap
                    >
                        {phone}
                    </Typography>
                </td>
                {authRole === UserRole.Root && (
                    <td style={{width: 180}}>
                        {Object.entries(clubs).map(entry => (
                            <ClubLabel key={entry[0]} label={entry[1]} clubId={entry[0]} noWrap/>
                        ))}
                    </td>
                )}
                <td onClick={event_ => {
                    event_.stopPropagation();
                }}>
                    <ItemMenu
                        {...{
                            id,
                            onTakeover: setTakeoverMode,
                            onDelete: setDeletionPromptOpen,
                        }}
                    />
                </td>
            </tr>
            <ConfirmDialog
                open={takeoverMode}
                title={t('users.loginAsUser')}
                message={t('users.loginAsUserPrompt')}
                onConfirm={handleTakeover}
                onCancel={() => { clearTakeoverMode(); }}
            />
            <ConfirmDialog
                open={deletionPromptOpen}
                onCancel={clearDeletionPromptOpen}
                onConfirm={handleDelete}
                title={t('users.deleteTitle')}
                message={t('users.deletePrompt')}
                color="danger"
            />
        </>
    );
}

function ListRow({user: {id, firstName, lastName, role, clubs, avatarUrl}}: { user: User }) {
    const navigate = useNavigate();
    const {role: authRole} = useAuthContext();

    return (
        <List
            size="sm"
            sx={{
                '--ListItem-paddingX': 0,
            }}
        >
            <ListItemButton
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    '&:hover': {
                        background: 'none !important'
                    }
                }}
                onClick={() => {
                    navigate(`/users/${id}`);
                }}
            >
                <ListItemContent sx={{display: 'flex', gap: 2, alignItems: 'start'}}>
                    <ListItemDecorator>
                        <Avatar size="sm" src={avatarUrl}/>
                    </ListItemDecorator>
                    <div>
                        <Typography fontWeight={600} gutterBottom>
                            {[lastName, firstName].filter(notBlank).join(', ')}
                        </Typography>
                        {authRole === UserRole.Root && Object.entries(clubs).map((entry) => (
                            <ClubLabel key={entry[0]} label={entry[1]} clubId={entry[0]} />
                        ))}
                    </div>
                </ListItemContent>
                <UserRoleChip {...{ role }} />
            </ListItemButton>
            <ListDivider />
        </List>
    );
}

function Filter({onFilter, filter = {}}: { onFilter: (filter: Record<string, any>) => void, filter: Record<string, any>|undefined }) {
    const {t} = useLocalization();
    const {role: authRole} = useAuthContext();

    const [role, setRole] = useState('');

    useEffect(() =>
        {
            if (typeof filter.role === 'string'
                && filter.role !== role
            ) {
                setRole(filter.role);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filter]);

    function handleRole(r: string) {
        setRole(r);
        onFilter({
            ...filter,
            role: r
        });
    }

    function handleClubId(clubId?: string) {
        onFilter({
            ...filter,
            clubId
        });
    }

    return (
        <>
            <FormControl size="sm">
                <FormLabel>{t('users.role')}</FormLabel>
                <Select
                    size="sm"
                    slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                    value={role}
                    onChange={(_, newValue) => { handleRole(newValue ?? ''); }}
                >
                    <Option value="">{t('common.all')}</Option>
                    {Object.values(UserRole).map(r => (
                        <Option key={r} value={r}>{r}</Option>
                    ))}
                </Select>
            </FormControl>
            {authRole === UserRole.Root && (
                <ClubSelect
                    small
                    defaultClubId={filter.clubId}
                    onChange={handleClubId}
                />
            )}
        </>
    );
}

interface ItemMenuProperties {
    id: string;
    onTakeover: () => void;
    onDelete: () => void;
}

function ItemMenu({id, onTakeover, onDelete}: ItemMenuProperties) {
    const {t} = useLocalization();
    const navigate = useNavigate();

    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <Dropdown open={menuOpen} onOpenChange={(event_, open) => { setMenuOpen(open); }}>
            <MenuButton
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
            >
                <MoreHorizRoundedIcon />
            </MenuButton>
            <Menu size="sm" sx={{ minWidth: 140 }} >
                <MenuItem
                    onClick={event_ =>
                    { handleExplicitClick(event_, () => { navigate(`/users/${id}`); }); }
                    }
                >
                    <ListItemDecorator>
                        <VisibilityRoundedIcon />
                    </ListItemDecorator>
                    {t('actions.view')}
                </MenuItem>
                <MenuItem
                    onClick={event_ =>
                    { handleExplicitClick(event_, () => { navigate(`/users/${id}/edit`); }); }
                    }
                >
                    <ListItemDecorator>
                        <EditRoundedIcon />
                    </ListItemDecorator>
                    {t('actions.edit')}
                </MenuItem>
                <MenuItem
                    onClick={event_ =>
                    { handleExplicitClick(event_, () => { onTakeover(); }); }
                    }
                >
                    <ListItemDecorator>
                        <LoginRoundedIcon />
                    </ListItemDecorator>
                    {t('users.loginAsUser')}
                </MenuItem>
                <Divider />
                <MenuItem
                    color="danger"
                    onClick={event_ =>
                    { handleExplicitClick(event_, () => { onDelete(); }); }
                    }
                >
                    <ListItemDecorator>
                        <DeleteRoundedIcon />
                    </ListItemDecorator>
                    {t('actions.delete')}
                </MenuItem>
            </Menu>
        </Dropdown>
    );
}