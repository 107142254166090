import {createPageableParameters, type Page, type Pageable} from '@mlyngvo/common-ui';
import {type Address, type CostAdjustmentItem, type CostAdjustmentRequest, type DevicePermissionItem} from './common';
import {createQueryString, useApi} from './api';
import {type Wallet} from './wallet';
import {type AccountActivity, type AccountTransaction, type RangefeeSession} from './statistic';

export interface AccountClubSummary {
    memberNumber?: string;
    associationNumber?: string;
    enabled: boolean;
    rangefeeEnabled: boolean;
    clubId: string;
    clubName: string;
    createdAt: string;
    accountId: string;
    accountName: string;
    accountGroupId?: string;
    accountGroupName?: string;
    overrideGroupAttr: boolean;
    wallet: Wallet;
    customRangefeeAmount?: number;
    activeRangefee?: RangefeeSession;
}

export interface Account {
    id: string;
    title?: string;
    firstName?: string;
    lastName?: string;
    name?: string;
    address?: Address;
    email?: string;
    phone?: string;
    permissions: Record<string, DevicePermissionItem[]>
    costAdjustments: Record<string, CostAdjustmentItem[]>
    clubs: Record<string, AccountClubSummary>;
    intlWallet?: Wallet;
    avatarUrl?: string;
    createdAt: string;
}

export interface AccountRequest {
    email: string;
    password?: string;
    title?: string;
    firstName?: string;
    lastName?: string;
    address?: Address;
    phone?: string;
}

export interface AccountClubRequest {
    memberNumber?: string;
    associationNumber?: string;
    enabled: boolean;
    rangefeeEnabled: boolean;
    accountGroupId?: string;
    customRangefeeAmount?: number;
}

export interface AccountRangefeeRequest {
    from: string;
    until: string;
}

export interface CenterAccountCreateRequest {
    email: string;
    title?: string;
    firstName: string;
    lastName: string;
    address: Address;
    phone?: string;
    memberNumber?: string;
    associationNumber?: string;
    rangefeeEnabled: boolean;
    accountGroupId?: string;
    clubId: string;
}

export enum AccountFilterState {
    WithClub = 'WithClub',
    WithoutClub = 'WithoutClub'
}

export enum AccountFilterGroupState {
    WithGroup = 'WithGroup',
    WithoutGroup = 'WithoutGroup'
}

export enum AccountFilterClubState {
    Active = 'Active',
    Inactive = 'Inactive'
}

export type AccountClubSummaryListView = Pick<AccountClubSummary, 'enabled'|'clubId'|'clubName'|'accountGroupId'|'accountGroupName'|'createdAt'|'wallet'>
export type AccountListView = Pick<Account, 'id'|'title'|'firstName'|'lastName'|'address'|'email'|'phone'|'avatarUrl'|'createdAt'|'intlWallet'> & { clubs: Record<string, AccountClubSummaryListView> }
export type AccountOptionView = Pick<Account, 'id'|'name'>

export function useAccountApi() {
    const {get, post, put, patch, del} = useApi();
    return {
        list: async (pageable: Pageable<AccountListView>) =>
            await get<Page<AccountListView>>(`/center/accounts?${createPageableParameters(pageable)}`),
        listOptions: async (clubId?: string, needle?: string) =>
            await get<AccountOptionView[]>(`/center/accounts/options?${createQueryString({ clubId, needle })}`),
        create: async (request: CenterAccountCreateRequest) =>
            await post<Account>('/center/accounts', request),
        find: async (id: string) =>
            await get<Account>(`/center/accounts/${id}`),
        put: async (id: string, request: AccountRequest) =>
            await put(`/center/accounts/${id}`, request),
        setAvatar: async (id: string, data: string) =>
            await put(`/center/accounts/${id}/avatar`, { data }),
        setPermission: async (id: string, deviceId: string, enabled: boolean) =>
            await patch(`/center/accounts/${id}/devices/${deviceId}/permissions`, { enabled }),
        setAccountCostAdjustment: async (id: string, deviceId: string, request: CostAdjustmentRequest)=>
            await put(`/center/accounts/${id}/devices/${deviceId}/cost-adjustments`, request),
        deleteAccount: async (id: string) =>
            await del(`/center/accounts/${id}`),
        listAccountClubActivities: async (id: string, clubId: string, pageable: Pageable<AccountActivity>) =>
            await get<Page<AccountActivity>>(`/center/accounts/${id}/clubs/${clubId}/activities?${createPageableParameters(pageable)}`),
        listAccountClubTransactions: async (id: string, clubId: string, pageable: Pageable<AccountTransaction>) =>
            await get<Page<AccountTransaction>>(`/center/accounts/${id}/clubs/${clubId}/transactions?${createPageableParameters(pageable)}`),
        putAccountClub: async (id: string, clubId: string, request: AccountClubRequest) =>
            await put(`/center/accounts/${id}/clubs/${clubId}`, request),
        setAccountAttrOverride: async (id: string, clubId: string, enabled: boolean) =>
            await patch(`/center/accounts/${id}/clubs/${clubId}/attr`, { enabled }),
        createRangefee: async (id: string, clubId: string, request: AccountRangefeeRequest) =>
            await post(`/center/accounts/${id}/clubs/${clubId}/rangefee`, request),
    };
}