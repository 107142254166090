import {createPageableParameters, type Page, type Pageable} from '@mlyngvo/common-ui';
import {createQueryString, useApi} from './api';
import {type AccountClubSummary} from './account';
import {type CostAdjustmentItem, type CostAdjustmentRequest, type DevicePermissionItem} from './common';

export interface AccountGroup {
    id: string;
    name: string;
    expirationDate?: string;
    startHour?: string;
    endHour?: string;
    defaultOnSignup: boolean;
    customRangefeeAmount?: number;
    clubId: string;
    clubName: string;
    totalAccounts: number;
    accounts: AccountClubSummary[];
    devices: DevicePermissionItem[];
    costAdjustments: CostAdjustmentItem[];
}

export interface AccountGroupRequest {
    name: string;
    expirationDate?: string;
    startHour?: string;
    endHour?: string;
    defaultOnSignup: boolean;
    customRangefeeAmount?: number;
    clubId: string;
}

export enum AccountGroupFilterStatus {
    Active = 'Active',
    Expired = 'Expired'
}

export type AccountGroupOptionView = Pick<AccountGroup, 'id'|'name'>
export type AccountGroupListView = Pick<AccountGroup, 'id'|'name'|'expirationDate'|'startHour'|'endHour'|'clubId'|'clubName'|'totalAccounts'|'defaultOnSignup'>

export function useAccountGroupApi() {
    const {get, post, put, patch, del} = useApi();
    return {
        list: async (pageable: Pageable<AccountGroupListView>) =>
            await get<Page<AccountGroupListView>>(`/center/account-groups?${createPageableParameters(pageable)}`),
        listOptions: async (clubId: string) =>
            await get<AccountGroupOptionView[]>(`/center/account-groups/options?${createQueryString({ clubId })}`),
        create: async (request: AccountGroupRequest) =>
            await post<AccountGroup>('/center/account-groups', request),
        find: async (id: string) =>
            await get<AccountGroup>(`/center/account-groups/${id}`),
        put: async (id: string, request: AccountGroupRequest) =>
            await put(`/center/account-groups/${id}`, request),
        putAccounts: async (id: string, accountIds: string[]) =>
            await put(`/center/account-groups/${id}/accounts`, accountIds),
        setPermission: async (id: string, deviceId: string, enabled: boolean) =>
            await patch(`/center/account-groups/${id}/devices/${deviceId}`, { enabled }),
        setAccountCostAdjustment: async (id: string, deviceId: string, request: CostAdjustmentRequest)=>
            await put(`/center/account-groups/${id}/devices/${deviceId}/cost-adjustments`, request),
        deleteAccountGroup: async (id: string) =>
            await del(`/center/account-groups/${id}`),
    };
}