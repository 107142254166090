import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Divider, Grid, Option, Typography} from '@mui/joy';
import {useLocalization} from '../../context/localization';
import {type UserRequest, UserRole, useUserApi} from '../../data/user';
import {FormModal} from '../../component/form-modal';
import {Input} from '../../component/input';
import {Select} from '../../component/select';
import {UserRoleChip} from './user-role-chip';
import {ClubSelect} from '../../component/club-select';

interface FormElements extends HTMLFormControlsCollection {
    title: HTMLInputElement;
    firstName: HTMLInputElement;
    lastName: HTMLInputElement;
    email: HTMLInputElement;
}

interface UserCreatorFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

interface UserCreatorProperties {
    open: boolean;
    onCancel: () => void;
}

export function UserCreator({open, onCancel}: UserCreatorProperties) {
    const {t} = useLocalization();
    const navigate = useNavigate();
    const {create} = useUserApi();

    const [clubId, setClubId] = useState<string>();
    const [role, setRole] = useState<UserRole>();

    async function handleSubmit(event: React.FormEvent<UserCreatorFormElement>) {
        event.preventDefault();

        if (role !== undefined && clubId !== undefined) {
            const formElements = event.currentTarget.elements;

            const data: UserRequest = {
                email: formElements.email.value,
                role,
                title: formElements.title.value,
                firstName: formElements.firstName.value,
                lastName: formElements.lastName.value,
                clubId,
            };

            const user = await create(data);
            navigate(`/users/${user?.id}`);
        }
    }

    return (
        <FormModal
            open={open}
            onCancel={onCancel}
            onSave={handleSubmit}
        >
            <Typography level="title-lg">
                {t('users.addNew')}
            </Typography>
            <Divider />
            <Grid container spacing={2}>
                <Grid xs={12} sm={4}>
                    <Input
                        label={t('common.title')}
                        InputProps={{
                            name: 'title',
                        }}
                    />
                </Grid>
                <Grid xs={12} sm={4}>
                    <Input
                        label={t('common.firstName')}
                        InputProps={{
                            name: 'firstName',
                        }}
                    />
                </Grid>
                <Grid xs={12} sm={4}>
                    <Input
                        label={t('common.lastName')}
                        InputProps={{
                            name: 'lastName',
                        }}
                    />
                </Grid>
                <Grid xs={12}>
                    <Input
                        label={t('common.email')}
                        InputProps={{
                            name: 'email',
                            type: 'email',
                            inputMode: 'email'
                        }}
                        FormControlProps={{
                            required: true
                        }}
                    />
                </Grid>
                <Grid xs={12}>
                    <Select
                        label={t('users.role')}
                        options={Object.values(UserRole)}
                        renderOption={o => (
                            <Option key={o} value={o}><UserRoleChip role={o}/></Option>
                        )}
                        SelectProps={{
                            placeholder: t('hints.select'),
                            value: role,
                            onClear: () => { setRole(undefined); },
                            onChange: (_, value) => { setRole(value ?? undefined); },
                            renderValue: o =>
                                o === null
                                    ? ''
                                    : <UserRoleChip role={o.value}/>
                        }}
                        FormControlProps={{
                            required: true
                        }}
                    />
                </Grid>
                <Grid xs={12}>
                    <ClubSelect
                        required
                        defaultClubId={clubId}
                        onChange={setClubId}
                    />
                </Grid>
            </Grid>
        </FormModal>
    );
}