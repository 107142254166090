import {Body, Breadcrumbs, storage, Title} from '@mlyngvo/common-ui';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAsyncCallback} from 'react-async-hook';
import dayjs, {type Dayjs} from 'dayjs';
import {Box, Button, Divider, Grid, LinearProgress, Option, Sheet, Stack, Table, Typography} from '@mui/joy';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded';
import DeveloperBoardRoundedIcon from '@mui/icons-material/DeveloperBoardRounded';
import {useLocalization} from '../../context/localization';
import {
    type AccountActivity,
    type ActivitiesStatisticFilter, ActivityType,
    useStatisticApi
} from '../../data/statistic';
import {displayDatetime, IsoDateFormat, notBlank} from '../../utils';
import {ClubSelect} from '../../component/club-select';
import {DatePicker} from '../../component/date-picker';
import {DeviceSelect} from '../../component/device-select';
import {AccountSelect} from '../../component/account-select';
import {Select} from '../../component/select';
import {AuthWrapper, useAuthContext} from '../../context/auth';
import {UserRole} from '../../data/user';

export function ActivityStatisticView() {
    return (
        <AuthWrapper>
            <ContentView />
        </AuthWrapper>
    );
}

function ContentView() {
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role, clubId: authClubId} = useAuthContext();
    const {computeActivities} = useStatisticApi();

    const StorageKey = `filter-activities-statistics:${role}`;

    const {result: data, loading, execute, reset} = useAsyncCallback(computeActivities);

    const [clubId, setClubId] = useState(authClubId);
    const [deviceId, setDeviceId] = useState<string>();
    const [accountId, setAccountId] = useState<string>();

    /* eslint-disable unicorn/no-null */
    const [from, setFrom] = useState<Dayjs|null>(null);
    const [until, setUntil] = useState<Dayjs|null>(null);
    /* eslint-enable unicorn/no-null */

    const [type, setType] = useState('');

    useEffect(() =>
        {
            const stored = storage.get<ActivitiesStatisticFilter>(StorageKey);
            if (stored !== undefined) {
                setClubId(role === UserRole.Root ? stored.clubId : authClubId);
                setFrom(dayjs(stored.from, IsoDateFormat.Date));
                setUntil(dayjs(stored.until, IsoDateFormat.Date));
                setType(stored.type ?? '');

                execute(stored)
                    .catch(console.error);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [authClubId]
    );

    function handleReset() {
        setClubId(authClubId);
        /* eslint-disable unicorn/no-null */
        setFrom(null);
        setUntil(null);
        /* eslint-enable unicorn/no-null */
        setDeviceId(undefined);
        setAccountId(undefined);
        setType('');
        reset();
        storage.delete(StorageKey);
    }

    function handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (clubId !== undefined
            && from !== null
            && until !== null
        ) {
            const filter: ActivitiesStatisticFilter = {
                clubId,
                from: from.format(IsoDateFormat.Date),
                until: until.format(IsoDateFormat.Date),
                type: type as ActivityType
            };
            execute({
                ...filter,
                deviceId,
                accountId,
            })
                .then(() => { storage.save(StorageKey, filter); })
                .catch(console.error);
        }
    }

    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('stats.title'), onClick: () => { navigate('/statistics/activities'); } },
                        { label: t('stats.types.Activities') },
                    ]}
                />
            )}
            title={(
                <Title
                    title={`${t('stats.title')} - ${t('stats.types.Activities')}`}
                />
            )}
        >
            <Box
                mt={2}
                rowGap={2}
            >
                <Sheet
                    variant="outlined"
                >
                    <form onSubmit={handleFormSubmit}>
                        <Box p={2}>
                            <Typography level="title-md">{t('table.filterLabel')}</Typography>
                            <Typography level="body-sm">{t('stats.filterDescription')}</Typography>
                        </Box>
                        <Divider/>
                        <Box p={2}>
                            <Grid container spacing={2}>
                                {role === UserRole.Root && (
                                    <Grid xs={12} sm={4} md={6}>
                                        <ClubSelect
                                            defaultClubId={clubId}
                                            onChange={setClubId}
                                        />
                                    </Grid>
                                )}
                                <Grid xs={12} sm={role === UserRole.Root ? 4 : 6} md={role === UserRole.Root ? 3 : 6}>
                                    <DatePicker
                                        label={t('common.from')}
                                        PickerProps={{
                                            value: from,
                                            onChange: setFrom
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} sm={role === UserRole.Root ? 4 : 6} md={role === UserRole.Root ? 3 : 6}>
                                    <DatePicker
                                        label={t('common.until')}
                                        PickerProps={{
                                            value: until,
                                            onChange: setUntil
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} sm={4} md={3}>
                                    <DeviceSelect
                                        disabled={!notBlank(clubId)}
                                        clubId={clubId}
                                        defaultDeviceId={deviceId}
                                        onChange={setDeviceId}
                                    />
                                </Grid>
                                <Grid xs={12} sm={4} md={3}>
                                    <AccountSelect
                                        disabled={!notBlank(clubId)}
                                        clubId={clubId}
                                        defaultAccountId={accountId}
                                        onChange={setAccountId}
                                    />
                                </Grid>
                                <Grid xs={12} sm={4} md={6}>
                                    <Select
                                        emptyValue
                                        label={t('common.type')}
                                        options={Object.values(ActivityType)}
                                        renderOption={o => (
                                            <Option key={o}
                                                    value={o}>{t(`activities.types.${o}`)}</Option>
                                        )}
                                        SelectProps={{
                                            placeholder: t('hints.select'),
                                            value: type as ActivityType,
                                            onChange: (_, value) => {
                                                setType(value ?? '');
                                            },
                                            onClear: () => {
                                                setType('');
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Divider/>
                        <Stack
                            sx={{p: 2}}
                            direction="row"
                            justifyContent="flex-end"
                            gap={2}
                        >
                            <Button
                                type="submit"
                                startDecorator={<FilterAltRoundedIcon/>}
                            >
                                {t('actions.submit')}
                            </Button>
                            <Button
                                variant="outlined"
                                color="neutral"
                                startDecorator={<RestartAltRoundedIcon/>}
                                onClick={handleReset}
                            >
                                {t('actions.reset')}
                            </Button>
                        </Stack>
                    </form>
                </Sheet>

                {loading && (
                    <Box my={2}>
                        <LinearProgress />
                    </Box>
                )}

                {data !== undefined && (
                    <>
                        <Box my={3} />
                        <Sheet
                            variant="outlined"
                        >
                            <Table>
                                <thead>
                                <tr>
                                    <th style={{width: 30}}>#</th>
                                    <th style={{width: 100}}>{t('common.timestamp')}</th>
                                    <th style={{width: 100}}>{t('accounts.singular')}</th>
                                    <th style={{width: 100}}>{t('common.type')}</th>
                                    <th style={{width: 100}}>{t('devices.singular')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.record.map((r, index) => (
                                    <TableRow
                                        key={r.id}
                                        accountActivity={r}
                                        index={index}
                                    />
                                ))}
                                </tbody>
                            </Table>
                        </Sheet>
                    </>
                )}

            </Box>
        </Body>
    );
}

function TableRow({accountActivity: {timestamp, accountName, type, deviceName}, index}: { accountActivity: AccountActivity, index: number }) {
    return (
        <tr>
            <td>
                <Typography level="body-xs">{index + 1}</Typography>
            </td>
            <td>
                <Typography level="body-xs" gutterBottom>{displayDatetime(timestamp)}</Typography>
            </td>
            <td>
                <Typography level="body-xs">{accountName}</Typography>
            </td>
            <td>
                <Typography level="body-xs">{type}</Typography>
            </td>
            <td>
                {notBlank(deviceName) && (
                    <Typography
                        level="body-xs"
                        startDecorator={<DeveloperBoardRoundedIcon/>}
                    >
                        {deviceName}
                    </Typography>
                )}
            </td>
        </tr>
    );
}