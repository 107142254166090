import React from 'react';
import {Typography, type TypographyProps} from '@mui/joy';
import {useLocalization} from '../../context/localization';
import {notBlank} from '../../utils';

interface AccountGroupTimeframeProperties extends TypographyProps {
    startHour?: string;
    endHour?: string;
    hideLabel?: boolean;
}

export function AccountGroupTimeframe({startHour, endHour, hideLabel = false, level = 'body-xs', ...properties}: AccountGroupTimeframeProperties) {
    const {t} = useLocalization();

    let timeframe: string|undefined;
    if (notBlank(startHour) && notBlank(endHour)) timeframe = `${startHour} - ${endHour}`;
    else if (notBlank(startHour)) timeframe = `${t('common.from')} ${startHour}`;
    else if (notBlank(endHour)) timeframe = `${t('common.until')} ${endHour}`;

    return (timeframe !== undefined) && <Typography {...{ level, ...properties }}>{!hideLabel && `${t('accountGroups.activeTimeframe')  }: `}{timeframe}</Typography>;
}