import {createPageableParameters, type Page, type Pageable} from '@mlyngvo/common-ui';
import {type TimeUnit} from './common';
import {createQueryString, useApi} from './api';

export type PortNumber = 0|1|2|3

export enum DeviceType {

    Entrance = 'Entrance',
    BallVendingMachine = 'BallVendingMachine',
    Timer = 'Timer',
}

export interface DevicePortConfig {
    name: string;
    active: boolean;
    switchingTime: number;
    switchingTimeUnit: TimeUnit;
    switchDelay: number;
    switchDelayUnit: TimeUnit,
    amount?: number;
    amountIntlWallet?: number;
    quantity: number;
}

export interface Device {
    id: string;
    name: string;
    type: DeviceType;
    model: string;
    serialNumber: string;
    clubId: string;
    clubName: string;
    rangefeeRequired: boolean;
    offlineUsageAllowed: boolean;
    openOnConnectEnabled: boolean;
    port0: DevicePortConfig;
    port1: DevicePortConfig;
    port2: DevicePortConfig;
    port3: DevicePortConfig;
}

export interface DeviceRequest {
    name: string;
    model: string;
    type: DeviceType;
    clubId: string;
    rangefeeRequired: boolean;
    offlineUsageAllowed: boolean;
    openOnConnectEnabled: boolean;
}

export interface DevicePortRequest {
    name: string;
    switchingTime: number;
    switchingTimeUnit: TimeUnit;
    switchDelay: number;
    switchDelayUnit: TimeUnit,
    amount?: number;
    amountIntlWallet?: number;
    quantity: number;
}

export type DeviceListView = Pick<Device, 'id'|'name'|'type'|'model'|'serialNumber'|'clubName'|'clubId'|'port0'|'port1'|'port2'|'port3'>;
export type DeviceOptionView = Pick<Device, 'id'|'name'>;

export function useDeviceApi() {
    const {get, put, patch, del} = useApi();
    return {
        list: async (pageable: Pageable<DeviceListView>) =>
            await get<Page<DeviceListView>>(`/center/devices?${createPageableParameters(pageable)}`),
        listOptions: async (clubId?: string, type?: DeviceType, needle?: string) =>
            await get<DeviceOptionView[]>(`/center/devices/options?${createQueryString({ clubId, type, needle })}`),
        find: async (id: string) =>
            await get<Device>(`/center/devices/${id}`),
        put: async (id: string, request: DeviceRequest) =>
            await put(`/center/devices/${id}`, request),
        updatePort: async (id: string, portNr: PortNumber, request: DevicePortRequest) =>
            await put(`/center/devices/${id}/ports/${portNr}`, request),
        setPortActive: async (id: string, portNr: PortNumber, active: boolean) =>
            await patch(`/center/devices/${id}/ports/${portNr}/active`, { active }),
        deleteDevice: async (id: string) =>
            await del(`/center/devices/${id}`)
    };
}