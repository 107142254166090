import {useApi} from './api';

interface InternalNotificationRequest {
    type: string;
    recipient: string;
    locale: string;
}

export function useInternalApi() {
    const {post} = useApi();
    return {
        sendNotification: async (request: InternalNotificationRequest) =>
            await post('/center/internal/send-notification', request),
    };
}