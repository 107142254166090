import React from 'react';

export function Logo() {
    return (
        <svg
            width="30px"
            height="30px"
            viewBox="0 0 250 250"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
        >
            <path
                d="M250,25c0,-13.798 -11.202,-25 -25,-25l-200,-0c-13.798,-0 -25,11.202 -25,25l-0,200c-0,13.798 11.202,25 25,25l200,0c13.798,0 25,-11.202 25,-25l0,-200Z"
                style={{ fill: '#171A1C' }}
            />
            <g id="Layer_5">
                <path
                    d="M70.452,40.932c-1.962,0 -3.585,1.623 -3.585,3.585l0,146.058c0,1.962 1.623,3.585 3.585,3.585c1.963,0 3.586,-1.623 3.586,-3.585l-0,-146.058c-0,-1.962 -1.623,-3.585 -3.586,-3.585Z"
                    style={{ fill: '#fff', fillRule: 'nonzero' }}
                />
                <path
                    d="M161.785,77.163c0,-1.132 -0.943,-2.075 -2.075,-2.075l-78.162,-29.401c-0.302,-0.264 -0.679,-0.415 -1.094,-0.415c-0.944,0 -1.699,0.755 -1.699,1.699l0,60.008c0,0.943 0.755,1.698 1.699,1.698c0.415,0 0.792,-0.151 1.094,-0.415l78.162,-29.023c1.132,0 2.075,-0.943 2.075,-2.076Z"
                    // style={{ fill: '#ff5e43', fillRule: 'nonzero' }}
                    style={{ fill: '#fff', fillRule: 'nonzero' }}
                />
                <path
                    d="M133.291,99.619c-1.962,0 -3.585,1.623 -3.585,3.586l-0,102.278c-0,1.962 1.623,3.585 3.585,3.585c1.963,-0 3.585,-1.623 3.585,-3.585l0,-102.278c0,-1.963 -1.622,-3.586 -3.585,-3.586Z"
                    style={{ fill: '#fff', fillRule: 'nonzero' }}
                />
                <path
                    d="M197.451,123.773l-52.989,-19.814c-0.302,-0.264 -0.679,-0.415 -1.094,-0.415c-0.944,0 -1.698,0.755 -1.698,1.699l-0,40.609c-0,0.943 0.754,1.698 1.698,1.698c0.415,0 0.755,-0.151 1.057,-0.377l53.026,-19.248c1.132,-0 2.075,-0.944 2.075,-2.076c0,-1.132 -0.943,-2.076 -2.075,-2.076Z"
                    // style={{ fill: '#fad16e', fillRule: 'nonzero' }}
                    style={{ fill: '#fff', fillRule: 'nonzero' }}
                />
            </g>
        </svg>
    );
}