import React, {type FormEvent, useState} from 'react';
import {AvatarEditor, Body, Breadcrumbs, Tabs, Title, useFlag, useTabs} from '@mlyngvo/common-ui';
import {
    AspectRatio,
    Box,
    Divider,
    Grid,
    IconButton, Modal,
    ModalClose,
    ModalDialog, Snackbar, Stack,
    Typography
} from '@mui/joy';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import {useNavigate} from 'react-router-dom';
import {useAsync} from 'react-async-hook';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import {AuthWrapper, useAuthContext} from '../../context/auth';
import {type User, UserRole} from '../../data/user';
import {useLocalization} from '../../context/localization';
import {type Club, useClubApi} from '../../data/club';
import {Input} from '../../component/input';
import {hashPassword, notBlank} from '../../utils';
import {useCenterApi} from '../../data/center';
import {FormSheet} from '../../component/form-sheet';
import {ClubForm, ClubTransactionBookmarks} from '../club';

export function Settings() {
    return (
        <AuthWrapper>
            <ContentView />
        </AuthWrapper>
    );
}

const TabsKey = 'settings_tabs';

function ContentView() {
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role, clubId} = useAuthContext();
    const {find} = useClubApi();
    const {getProfile} = useCenterApi();

    const {result: [club, user] = [], loading, error, execute} = useAsync(async () =>
        await Promise.all([
            (role !== UserRole.Root && clubId !== undefined)
                ? find(clubId)
                : undefined,
            getProfile()
        ])
        , [role, clubId]);

    const {tab, changeTab} = useTabs(`${TabsKey}:${role}`);

    async function handleBookmarksChange() {
        await execute();
    }

    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('settings.title') }
                    ]}
                />
            )}
            title={(
                <Title
                    title={t('settings.title')}
                />
            )}
            {...{ error, loading }}
        >
            <Tabs
                value={tab}
                onChange={changeTab}
                items={[
                    <Typography key="tab-0" level="body-sm" noWrap><strong>{t('settings.profile.title')}</strong></Typography>,
                    ...(role === UserRole.Root
                        ? []
                        : [
                            <Typography key="tab-1" level="body-sm" noWrap sx={{ opacity: role === UserRole.User ? 0.5 : 1 }}><strong>{t('clubs.singular')}</strong></Typography>,
                            <Typography key="tab-3" level="body-sm" noWrap><strong>{t('payments.bookmarks.title')}</strong></Typography>,
                        ]
                    )
                ]}
                expandMx={{
                    xs: -2,
                    md: -6
                }}
            />

            <Box
                mt={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                {(tab === 0 && user !== undefined) && (
                    <SettingsProfile
                        user={user}
                    />
                )}

                {(tab === 1 && club !== undefined) && (
                    <SettingsClub
                        club={club}
                    />
                )}

                {(tab === 2 && club !== undefined) && (
                    <SettingsClubTransactionBookmarks
                        club={club}
                        onUpdate={handleBookmarksChange}
                    />
                )}
            </Box>
        </Body>
    );
}

interface ProfileForm extends HTMLFormElement {
    readonly elements: HTMLFormControlsCollection & {
        title: HTMLInputElement;
        position: HTMLInputElement;
        firstName: HTMLInputElement;
        lastName: HTMLInputElement;
        email: HTMLInputElement;
        phone: HTMLInputElement;
    }
}

interface PasswordForm extends HTMLFormElement {
    readonly elements: HTMLFormControlsCollection & {
        password: HTMLInputElement;
        confirmPassword: HTMLInputElement;
    }
}

interface SettingsProfileProperties {
    user: User;
}

function SettingsProfile({user: {id, avatarUrl, firstName, lastName, email, phone, title, role, position}}: SettingsProfileProperties) {
    const {t} = useLocalization();
    const {setProfileAvatar, updateProfile, setProfilePassword} = useCenterApi();

    const [counter, setCounter] = useState(0);
    const [previewUrl, setPreviewUrl] = useState(avatarUrl);
    const [avatarEditorOpen, setAvatarEditorOpen, clearAvatarEditorOpen] = useFlag(false);

    function handleAvatar(data: string) {
        setProfileAvatar(data.slice(22))
            .then(() => {
                clearAvatarEditorOpen();
                setPreviewUrl(`/api/users/${id}/avatar`);
                setCounter(c => c + 1);
            })
            .catch(console.error);
    }

    async function handleSaveProfile(event_: FormEvent<ProfileForm>) {
        event_.preventDefault();

        const {elements} = event_.currentTarget;

        await updateProfile({
            title: elements.title.value,
            position: elements.position.value,
            firstName: elements.firstName.value,
            lastName: elements.lastName.value,
            email: elements.email.value,
            phone: elements.phone.value,
            role
        });
    }

    async function handleSavePassword(event_: FormEvent<PasswordForm>) {
        event_.preventDefault();

        const {elements} = event_.currentTarget;
        const password = elements.password.value;
        const confirmPassword = elements.confirmPassword.value;

        if (!notBlank(password) || confirmPassword !== password) {
            throw new Error('Invalid password.');
        }

        await setProfilePassword(hashPassword(password));
    }

    return (
        <Stack
            direction="column"
            gap={3}
        >
            <FormSheet
                onSave={handleSaveProfile}
                successMessage={t('hints.dataSaved')}
                SheetProps={{
                    sx: {
                        width: '100%',
                        maxWidth: 'sm'
                    }
                }}
            >
                <Typography level="title-md">{t('settings.profile.personalInfo')}</Typography>
                <Typography level="body-sm">{t('settings.profile.personalInfoDescription')}</Typography>
                <Box my={2} mx={-2}>
                    <Divider />
                </Box>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems="flex-start"
                    gap={3}
                >
                    <Stack
                        direction="column"
                        spacing={1}
                        sx={{ position: 'relative' }}
                    >
                        <AspectRatio
                            ratio="1"
                            maxHeight={150}
                            variant="outlined"
                            objectFit="contain"
                            sx={{
                                minWidth: 100,
                                borderRadius: '50%',
                                bgcolor: 'common.white'
                            }}
                        >
                            {notBlank(previewUrl)
                                ? (
                                    <img
                                        src={`${previewUrl}?s=${counter}`}
                                        loading="lazy"
                                        alt={id}
                                    />
                                )
                                : <div><AccountBoxRoundedIcon sx={{ fontSize: '3rem', opacity: 0.5 }} /></div>
                            }
                        </AspectRatio>
                        <IconButton
                            size="sm"
                            variant="outlined"
                            color="neutral"
                            sx={theme => ({
                                bgcolor: 'background.body',
                                position: 'absolute',
                                zIndex: 2,
                                borderRadius: '50%',
                                right: 0,
                                bottom: -2,
                                boxShadow: 'sm',
                                [theme.breakpoints.down('sm')]: {
                                    display: 'none'
                                }
                            })}
                            onClick={setAvatarEditorOpen}
                        >
                            <EditRoundedIcon />
                        </IconButton>
                    </Stack>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={4}>
                            <Input
                                label={t('common.title')}
                                InputProps={{
                                    name: 'title',
                                    defaultValue: title
                                }}
                            />
                        </Grid>
                        <Grid xs={12} sm={4}>
                            <Input
                                label={t('common.firstName')}
                                InputProps={{
                                    name: 'firstName',
                                    defaultValue: firstName
                                }}
                                FormControlProps={{
                                    required: true
                                }}
                            />
                        </Grid>
                        <Grid xs={12} sm={4}>
                            <Input
                                label={t('common.lastName')}
                                InputProps={{
                                    name: 'lastName',
                                    defaultValue: lastName
                                }}
                                FormControlProps={{
                                    required: true
                                }}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <Input
                                label={t('users.position')}
                                InputProps={{
                                    name: 'position',
                                    defaultValue: position
                                }}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <Input
                                label={t('common.email')}
                                InputProps={{
                                    name: 'email',
                                    defaultValue: email
                                }}
                                FormControlProps={{
                                    required: true
                                }}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <Input
                                label={t('common.phone')}
                                InputProps={{
                                    name: 'phone',
                                    defaultValue: phone
                                }}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </FormSheet>
            <FormSheet
                onSave={handleSavePassword}
                successMessage={t('hints.dataSaved')}
                SheetProps={{
                    sx: {
                        width: '100%',
                        maxWidth: 'sm'
                    }
                }}
            >
                <Typography level="title-md">{t('settings.profile.security')}</Typography>
                <Typography level="body-sm">{t('settings.profile.securityDescription')}</Typography>
                <Box my={2} mx={-2}>
                    <Divider />
                </Box>
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Input
                            label={t('users.newPassword')}
                            InputProps={{
                                name: 'password',
                                type: 'password'
                            }}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <Input
                            label={t('users.confirmPassword')}
                            InputProps={{
                                name: 'confirmPassword',
                                type: 'password'
                            }}
                        />
                    </Grid>
                </Grid>
            </FormSheet>
            <Modal open={avatarEditorOpen} onClose={clearAvatarEditorOpen}>
                <ModalDialog>
                    <ModalClose/>
                    <Typography level="title-lg">{t('accounts.editAvatar')}</Typography>
                    <Divider />
                    <AvatarEditor
                        defaultImageUrl={previewUrl ?? undefined}
                        onSave={handleAvatar}
                        onCancel={clearAvatarEditorOpen}
                        i18n={{
                            uploadFile: t('actions.uploadPhoto'),
                            save: t('actions.save'),
                            cancel: t('actions.cancel')
                        }}
                    />
                </ModalDialog>
            </Modal>
        </Stack>
    );
}


function SettingsClub({club}: { club: Club }) {
    const {t} = useLocalization();
    const [successOpen, setSuccessOpen, clearSuccessOpen] = useFlag(false);

    return (
        <AuthWrapper allowRoles={[ UserRole.Admin ]}>
            <ClubForm
                club={club}
                onSave={setSuccessOpen}
            />
            <Snackbar
                variant="solid"
                color="success"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={successOpen}
                onClose={clearSuccessOpen}
            >
                {t('hints.dataSaved')}
            </Snackbar>
        </AuthWrapper>
    );
}

function SettingsClubTransactionBookmarks({club, onUpdate}: { club: Club, onUpdate: () => Promise<void> }) {

    return (
        <Stack
            direction="column"
            alignItems="flex-end"
        >
            <ClubTransactionBookmarks club={club} onUpdate={onUpdate} />
        </Stack>
    );
}