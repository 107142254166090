import React, {type FormEvent, type PropsWithChildren, useState} from 'react';
import {useFlag} from '@mlyngvo/common-ui';
import {Alert, Box, Button, Divider, Sheet, type SheetProps as MuiSheetProperties, Snackbar, Stack} from '@mui/joy';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {useLocalization} from '../context/localization';

interface FormSheetProperties<T> {
    onSave: (event: FormEvent<T>) => Promise<void>;
    successMessage: string;
    onCancel?: () => void;
    SheetProps?: MuiSheetProperties;
}

export function FormSheet<T extends HTMLFormElement>({onCancel, onSave, successMessage, SheetProps: {sx, ...sheetProperties} = {}, children}: PropsWithChildren<FormSheetProperties<T>>) {
    const {t} = useLocalization();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error>();
    const [successOpen, setSuccessOpen, clearSuccessOpen] = useFlag(false);

    function handleSave(event_: FormEvent<T>) {
        if (!loading) {
            setError(undefined);
            setLoading(true);
            onSave(event_)
                .then(() => { setSuccessOpen(); })
                .catch((error_: Error) => {
                    try {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        setError(JSON.parse(error_.message));
                    } catch {
                        setError(error_);
                    }
                })
                .finally(() => { setLoading(false); });
        }
    }

    return (
        <>
            <Sheet
                variant="outlined"
                component="form"
                onSubmit={handleSave}
                sx={{
                    p: 2,
                    ...sx,
                }}
                {...sheetProperties}
            >
                {children}

                {error !== undefined && (
                    <>
                        <Box my={2} />
                        <Alert color="danger">
                            {error.message}
                        </Alert>
                    </>
                )}

                <Box my={2} mx={-2}>
                    <Divider />
                </Box>
                <Stack
                    sx={{ pt: 1 }}
                    direction="row"
                    justifyContent="flex-end"
                    gap={2}
                >
                    <Button
                        type="submit"
                        startDecorator={<CheckRoundedIcon />}
                        loading={loading}
                        disabled={loading}
                    >
                        {t('actions.save')}
                    </Button>
                    {onCancel !== undefined && (
                        <Button
                            variant="outlined"
                            color="neutral"
                            startDecorator={<CloseRoundedIcon />}
                            onClick={onCancel}
                            disabled={loading}
                        >
                            {t('actions.cancel')}
                        </Button>
                    )}
                </Stack>
            </Sheet>
            <Snackbar
                variant="solid"
                color="success"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={successOpen}
                onClose={clearSuccessOpen}
            >
                {successMessage}
            </Snackbar>
        </>
    );
}