import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {useAsync} from 'react-async-hook';
import {Body, Breadcrumbs, Title, useFlag} from '@mlyngvo/common-ui';
import {Box, Button, Divider, Grid, Sheet, Stack, Typography} from '@mui/joy';
import React, {useState} from 'react';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import {ConfirmDialog} from '../../component/confirm-dialog';
import {type NotificationRequest, useNotificationApi} from '../../data/notification';
import {useLocalization} from '../../context/localization';
import {Input} from '../../component/input';
import {Textarea} from '../../component/textarea';
import {AuthWrapper} from '../../context/auth';
import {UserRole} from '../../data/user';

export function NotificationEditor() {
    return (
        <AuthWrapper allowRoles={[ UserRole.Root, UserRole.Admin ]}>
            <ContentView />
        </AuthWrapper>
    );
}

interface FormElements extends HTMLFormControlsCollection {
    title: HTMLInputElement;
    content: HTMLInputElement;
}

interface NotificationEditorFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

function ContentView() {
    const {id } = useParams<{id: string}>();
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {find, put, deleteNotification} = useNotificationApi();

    const {result: notification, error: loadError, loading} = useAsync(async () =>
            (id === undefined) ? undefined : await find(id)
        , [id]);

    const [deletionPromptOpen, setDeletionPromptOpen, clearDeletionPromptOpen] = useFlag(false);
    const [error, setError] = useState<Error>();

    function handleFormSubmit(event: React.FormEvent<NotificationEditorFormElement>) {
        event.preventDefault();

        if (notification !== undefined) {
            const formElements = event.currentTarget.elements;

            const data: NotificationRequest = {
                title: formElements.title.value,
                content: formElements.content.value,
                clubId: notification.clubId
            };

            put(notification.id, data)
                .then(() => { navigate(`/notifications/${notification.id}`); })
                .catch(error_ => {
                    console.error('Failed to put notification data', error_);
                    setError(new Error('Failed to update.'));
                });
        }
    }

    function handleDelete() {
        if (notification !== undefined) {
            deleteNotification(notification.id)
                .then(() => { navigate('/notifications'); })
                .catch(setError);
        }
    }

    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('notifications.title'), onClick: () => { navigate('/notifications'); } },
                        { label: notification === undefined ? t('common.details') : notification.title, onClick: () => { navigate(`/notifications/${id}`); } },
                        { label: t('actions.edit') }
                    ]}
                />
            )}
            title={(
                <Title
                    title={notification === undefined ? t('notifications.singular') : notification.title}
                    actions={(
                        <Button
                            variant="outlined"
                            color="neutral"
                            startDecorator={<ArrowBackIosRoundedIcon />}
                            size="sm"
                            onClick={() => { navigate(`/notifications/${notification?.id}`); }}
                        >
                            {t('actions.back')}
                        </Button>
                    )}
                />
            )}
            loading={loading}
            error={error ?? loadError}
        >
            {notification !== undefined && (
                <>
                    <form onSubmit={handleFormSubmit}>
                        <Sheet
                            variant="outlined"
                            sx={{
                                p: 2
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid xs={12}>
                                    <Typography level="title-lg">{t('common.details')}</Typography>
                                </Grid>
                                <Grid xs={12}>
                                    <Input
                                        label={t('common.name')}
                                        InputProps={{
                                            name: 'title',
                                            defaultValue: notification.title
                                        }}
                                        FormControlProps={{
                                            required: true
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <Textarea
                                        label={t('notifications.content')}
                                        InputProps={{
                                            name: 'content',
                                            defaultValue: notification.content,
                                            minRows: 7,
                                            maxRows: 15,
                                        }}
                                        FormControlProps={{
                                            required: true
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Sheet>
                        <Divider sx={{my: 2}}/>
                        <Stack
                            direction={{sm: 'column', md: 'row'}}
                            gap={2}
                        >
                            <Button
                                startDecorator={<SaveRoundedIcon/>}
                                type="submit"
                            >
                                {t('actions.save')}
                            </Button>
                            <Button
                                startDecorator={<CloseRoundedIcon/>}
                                variant="outlined"
                                color="neutral"
                                onClick={() => {
                                    navigate(`/notifications/${notification?.id}`);
                                }}
                            >
                                {t('actions.cancel')}
                            </Button>
                            <Box flexGrow={1} sx={theme => ({[theme.breakpoints.down('md')]: {display: 'none'}})}/>
                            <Button
                                startDecorator={<DeleteRoundedIcon />}
                                variant="outlined"
                                color="danger"
                                onClick={setDeletionPromptOpen}
                            >
                                {t('actions.delete')}
                            </Button>
                        </Stack>
                    </form>
                    <ConfirmDialog
                        open={deletionPromptOpen}
                        onCancel={clearDeletionPromptOpen}
                        onConfirm={handleDelete}
                        title={t('accountGroups.deleteTitle')}
                        message={t('accountGroups.deletePrompt')}
                        color="danger"
                    />
                </>
            )}
        </Body>
    );
}