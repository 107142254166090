import React, {type PropsWithChildren} from 'react';
import {BrowserRouter, Route, type RouteProps, Routes, useNavigate} from 'react-router-dom';
import {AppThemeProvider, ErrorBoundary, Sidebar} from '@mlyngvo/common-ui';
import {Box, IconButton, Typography} from '@mui/joy';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import DeveloperBoardRoundedIcon from '@mui/icons-material/DeveloperBoardRounded';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import WalletRoundedIcon from '@mui/icons-material/WalletRounded';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import {AuthenticationProvider, useAuthContext} from './context/auth';
import {LocalizationProvider, useLocalization} from './context/localization';
import {Logo} from './assets/logo';
import {PageTitleProvider} from './context/page-title';
import {authStore} from './store/auth';
import {UserRole} from './data/user';
import {AppAuthRefresh, AppForgotPassword, AppLogin} from './page/auth';
import {ClubDetails, ClubEditor, ClubList} from './page/club';
import {UserDetails, UserEditor, UserList} from './page/user';
import {DeviceDetails, DeviceEditor, DeviceList} from './page/device';
import {AccountDetails, AccountEditor, AccountList} from './page/account';
import {AccountGroupDetails, AccountGroupEditor, AccountGroupList} from './page/account-group';
import {IntlWalletList, ClubWalletList, WalletDetails} from './page/wallet';
import {NotificationDetails, NotificationEditor, NotificationList} from './page/notification';
import {ActivityStatisticView, TransactionStatisticView} from './page/statistic';
import {InternalEmailNotifications, InternalFunctionsList} from './page/internal';
import {PaymentList} from './page/payment';
import {Dashboard} from './page/dashboard';
import {Settings} from './page/settings';
import {AccountPasswordReset, AccountConfirm, UserAccepts} from './page/public';

const routes: Record<string, RouteProps> = {
    '/': { element: <Dashboard /> },
    '/login': { element: <AppLogin /> },
    '/auth-refresh': { element: <AppAuthRefresh /> },
    '/forgot-password': { element: <AppForgotPassword /> },
    '/clubs': { element: <ClubList /> },
    '/clubs/:id': { element: <ClubDetails /> },
    '/clubs/:id/edit': { element: <ClubEditor /> },
    '/users': { element: <UserList /> },
    '/users/:id': { element: <UserDetails /> },
    '/users/:id/edit': { element: <UserEditor /> },
    '/devices': { element: <DeviceList /> },
    '/devices/:id': { element: <DeviceDetails /> },
    '/devices/:id/edit': { element: <DeviceEditor /> },
    '/accounts': { element: <AccountList /> },
    '/accounts/:id': { element: <AccountDetails /> },
    '/accounts/:id/edit': { element: <AccountEditor /> },
    '/account-groups': { element: <AccountGroupList /> },
    '/account-groups/:id': { element: <AccountGroupDetails /> },
    '/account-groups/:id/edit': { element: <AccountGroupEditor /> },
    '/wallets/intl': { element: <IntlWalletList /> },
    '/wallets/club': { element: <ClubWalletList /> },
    '/wallets/:id': { element: <WalletDetails /> },
    '/notifications': { element: <NotificationList /> },
    '/notifications/:id': { element: <NotificationDetails /> },
    '/notifications/:id/edit': { element: <NotificationEditor /> },
    '/statistics/transactions': { element: <TransactionStatisticView /> },
    '/statistics/activities': { element: <ActivityStatisticView /> },
    '/payments': { element: <PaymentList /> },
    '/settings': { element: <Settings /> },
    '/internal': { element: <InternalFunctionsList /> },
    '/internal/email-notifications': { element: <InternalEmailNotifications /> },

    '/user-accepts/:token': { element: <UserAccepts /> },
    '/account-confirm/:token': { element: <AccountConfirm /> },
    '/account-email-confirm/:token': { element: <div /> },  // TODO: Will be required when app is on next version
    '/account-password-reset/:token': { element: <AccountPasswordReset /> },
};

export function App() {
    return (
        <AppThemeProvider>
            <ErrorBoundary anonymizeStorageKeyPatterns={['auth']}>
                <LocalizationProvider>
                    <AuthenticationProvider>
                        <PageTitleProvider>
                            <BrowserRouter>
                                <Shell>
                                    <Routes>
                                        {Object.entries(routes).map(([path, properties]) => (
                                            <Route key={path} path={path} {...properties} />
                                        ))}
                                    </Routes>
                                </Shell>
                            </BrowserRouter>
                        </PageTitleProvider>
                    </AuthenticationProvider>
                </LocalizationProvider>
            </ErrorBoundary>
        </AppThemeProvider>
    );
}

function checkSelected(...paths: string[]) {
    // eslint-disable-next-line no-restricted-syntax
    for (const path of paths) {
        if (path.length === 1) return window.location.pathname === path;
        if (window.location.pathname.includes(path)) return true;
    }
    return false;
}

function Shell({children}: PropsWithChildren) {
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {authenticated, profile, role} = useAuthContext();

    function handleLogout() {
        authStore.clear();
        window.location.replace('/login');
    }

    return (
        <Box
            sx={{
                display: 'flex',
                minHeight: '100dvh'
            }}
        >
            {authStore.isTakeoverMode && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: 2,
                        px: 2,
                        position: 'fixed',
                        top: { xs: 'auto', md: 0 },
                        bottom: { xs: 0, md: 'auto' },
                        left: 0,
                        zIndex: 9_999_999,
                        width: '100%',
                        height: '40px',
                        background: 'rgba(255, 208, 88, 0.76)',
                        backdropFilter: 'blur(2px)'
                    }}
                >
                    <Typography level="body-xs" sx={{ color: 'text.primary' }}>
                        <strong>IM VERTRETTUNG MODUS</strong>
                    </Typography>
                    <IconButton
                        size="sm"
                        sx={{ color: 'text.primary' }}
                        onClick={() => { authStore.exitTakeover(); }}
                    >
                        <CloseRoundedIcon />
                    </IconButton>
                </Box>
            )}

            {authenticated && (
                <Sidebar
                    appTitle="Center"
                    logo={<Logo />}
                    navItems={[
                        { title: t('dashboard.title'), icon: <DashboardRoundedIcon />, selected: checkSelected('/'), onClick: () => { navigate('/'); } },
                        ...(role === UserRole.Root
                                ? [{ title: t('clubs.title'), icon: <CorporateFareRoundedIcon />, selected: checkSelected('/clubs'), onClick: () => { navigate('/clubs'); }  }]
                                : []
                        ),
                        { title: t('devices.title'), icon: <DeveloperBoardRoundedIcon />, selected: checkSelected('/devices'), onClick: () => { navigate('/devices'); }  },
                        { title: t('accounts.title'), icon: <AccountTreeRoundedIcon />, selected: checkSelected('/accounts', '/account-groups'),
                            children: [
                                { title: t('common.all'), onClick: () => { navigate('/accounts'); } },
                                { title: t('accountGroups.title'), onClick: () => { navigate('/account-groups'); } },
                            ]
                        },
                        ...(role === UserRole.Root
                                ? [
                                    { title: t('wallets.title'), icon: <WalletRoundedIcon />, selected: checkSelected('/wallets'),
                                        children: [
                                            { title: t('wallets.types.International'), onClick: () => { navigate('/wallets/intl'); } },
                                            { title: t('wallets.types.Club'), onClick: () => { navigate('/wallets/club'); } },
                                        ]
                                    }
                                ]
                                : [{ title: t('wallets.title'), icon: <WalletRoundedIcon />, selected: checkSelected('/wallets'), onClick: () => { navigate('/wallets/club'); } }]
                        ),
                        { title: t('payments.title'), icon: <ReceiptRoundedIcon />, selected: checkSelected('/payments'), onClick: () => { navigate('/payments'); } },
                        { title: t('notifications.title'), icon: <CampaignRoundedIcon />, selected: checkSelected('/notifications'), onClick: () => { navigate('/notifications'); } },
                        { title: t('stats.title'), icon: <AssessmentRoundedIcon />, selected: checkSelected('/statistics'),
                            children: [
                                { title: t('stats.types.Activities'), onClick: () => { navigate('/statistics/activities'); } },
                                { title: t('stats.types.Transactions'), onClick: () => { navigate('/statistics/transactions'); } },
                            ]
                        },
                    ]}
                    bottomNavItems={[
                        ...(role === UserRole.User
                                ? []
                                : [{ title: t('users.title'), icon: <GroupRoundedIcon />, selected: checkSelected('/users'), onClick: () => { navigate('/users'); } }]
                        ),
                        { title: t('settings.title'), icon: <SettingsRoundedIcon />, selected: checkSelected('/settings'), onClick: () => { navigate('/settings'); } },
                        ...(role === UserRole.Root
                                ? [{ title: 'Interne', icon: <ScienceOutlinedIcon />, selected: checkSelected('/internal'), onClick: () => { navigate('/internal'); } }]
                                : []
                        ),
                    ]}
                    profile={{
                        displayLine: profile?.name ?? '',
                        secondaryLine: profile?.position,
                        profileImageUrl: profile?.avatarUrl,
                        onLogout: handleLogout
                    }}
                />
            )}

            <Box
                component="span"
                sx={{
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    px: 6,
                    pt: 4,
                    typography: 'body-xs',
                    textAlign: 'right',
                    opacity: 0.7,
                }}
            >
                &copy; {new Date().getFullYear()} Pin Point GmbH - v{process.env.VERSION}
            </Box>
            {children}
        </Box>
    );
}
