import {utils, type CellObject, type WorkSheet} from 'xlsx';

export function computeColumnLengths(rows: Array<Record<string, any>>) {
    const objectMaxLength: number[] = [];
    /* eslint-disable no-restricted-syntax */
    for (const row of rows) {
        const value = Object.values(row);
        for (const [index, element] of value.entries()) {
            if (typeof element === 'number') {
                objectMaxLength[index] = 10;
            } else {
                objectMaxLength[index] =
                    objectMaxLength[index] >= element.length
                        ? objectMaxLength[index]
                        : element.length;
            }
        }
    }
    /* eslint-enable no-restricted-syntax */
    return objectMaxLength.map(width => ({ wch: width + 5 }));
}

export function formatCellAsNumber(worksheet: WorkSheet, row: number, col: number, rowIncrement: number, format?: string) {
    // eslint-disable-next-line no-restricted-syntax, no-plusplus
    for (let index = 0; index < rowIncrement; index++) {
        const cell = worksheet[utils.encode_cell({ r: row + index, c: col })] as CellObject|null;
        if (cell !== null) {
            cell.t = 'n';
            if (format !== undefined) cell.z = format;
        }
    }
}