import React, {useEffect, useState} from 'react';
import {useAsyncCallback} from 'react-async-hook';
import {debounce} from 'lodash';
import {Autocomplete, CircularProgress, FormControl, FormLabel} from '@mui/joy';
import {useLocalization} from '../context/localization';
import {type DeviceOptionView, type DeviceType, useDeviceApi} from '../data/device';

interface DeviceSelectProperties {
    onChange: (deviceId?: string) => void;
    clubId?: string;
    defaultDeviceId?: string;
    disabled?: boolean;
    type?: DeviceType;
}

export function DeviceSelect({onChange, clubId: pClubId, defaultDeviceId, disabled = false, type: pType}: DeviceSelectProperties) {
    const {t} = useLocalization();
    const {listOptions, find} = useDeviceApi();

    const [device, setDevice] = useState<DeviceOptionView>();

    const {result: options, loading, execute} = useAsyncCallback(async (clubId?: string, needle?: string, type?: DeviceType) =>
        await listOptions(clubId, type, needle)
    );

    useEffect(() => {
        if (!disabled) {
            execute(pClubId, undefined, pType)
                .then(() => { setDevice(undefined); })
                .catch(console.error);
        }
    }, [pClubId, pType, disabled, execute]);

    useEffect(() =>
        {
            if (!disabled
                && typeof defaultDeviceId === 'string'
                && defaultDeviceId !== device?.id) {
                if (defaultDeviceId.length > 0) {
                    find(defaultDeviceId)
                        .then(setDevice)
                        .catch(console.error);
                } else {
                    setDevice(undefined);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [pClubId, disabled, defaultDeviceId]);

    const handleDeviceSearch = debounce((needle: string) => {
        execute(pClubId, needle, pType)
            .catch(console.error);
    }, 300);

    function handleDeviceId(d: DeviceOptionView|null) {
        setDevice(d ?? undefined);
        onChange(d?.id);
    }

    return (
        <FormControl disabled={disabled}>
            <FormLabel
                sx={{
                    typography: 'body-sm',
                    fontWeight: 600
                }}
            >
                {t('devices.singular')}
            </FormLabel>
            <Autocomplete
                placeholder={t('hints.select')}
                // eslint-disable-next-line unicorn/no-null
                value={device ?? null}
                onChange={(_, value) => { handleDeviceId(value); }}
                onInputChange={(_, value) => handleDeviceSearch(value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name ?? ''}
                options={options ?? []}
                loading={loading}
                endDecorator={
                    loading
                        ? <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
                        : undefined
                }
                sx={{ bgcolor: 'background.body' }}
            />
        </FormControl>
    );
}