import {debounce} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useAsyncCallback} from 'react-async-hook';
import {Autocomplete, CircularProgress, FormControl, FormLabel} from '@mui/joy';
import {type ClubOptionView, useClubApi} from '../data/club';
import {useLocalization} from '../context/localization';

interface ClubSelectProperties {
    onChange: (clubId?: string) => void;
    defaultClubId?: string;
    small?: boolean;
    required?: boolean;
}

export function ClubSelect({onChange, defaultClubId, small = false, required = false}: ClubSelectProperties) {
    const {t} = useLocalization();
    const {listOptions, find} = useClubApi();

    const [club, setClub] = useState<ClubOptionView>();

    const {result: options, loading, execute} = useAsyncCallback(async (needle?: string) => await listOptions(needle));

    useEffect(() => {
        execute()
            .catch(console.error);
    }, [execute]);

    useEffect(() =>
        {
            if (defaultClubId === undefined) {
                setClub(undefined);
                return;
            }
            if (defaultClubId !== club?.id) {
                if (defaultClubId.length > 0) {
                    find(defaultClubId)
                        .then(setClub)
                        .catch(console.error);
                } else {
                    setClub(undefined);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [defaultClubId]);

    const handleClubSearch = debounce((needle: string) => {
        execute(needle)
            .catch(console.error);
    }, 300);

    function handleClubId(f: ClubOptionView|null) {
        setClub(f ?? undefined);
        onChange(f?.id);
    }

    return (
        <FormControl
            size={small ? 'sm' : 'md'}
            required={required}
        >
            <FormLabel
                sx={small
                    ? undefined
                    : {
                        typography: 'body-sm',
                        fontWeight: 600
                    }
                }
            >
                {t('clubs.singular')}
            </FormLabel>
            <Autocomplete
                placeholder={t('hints.select')}
                // eslint-disable-next-line unicorn/no-null
                value={club ?? null}
                onChange={(_, value) => { handleClubId(value); }}
                onInputChange={(_, value) => handleClubSearch(value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                options={options ?? []}
                loading={loading}
                endDecorator={
                    loading
                        ? <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
                        : undefined
                }
                sx={small
                    ? undefined
                    : { bgcolor: 'background.body' }
                }
            />
        </FormControl>
    );
}