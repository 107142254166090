import {Body, Breadcrumbs, storage, Title} from '@mlyngvo/common-ui';
import {
    Box,
    Button,
    Divider,
    Grid, LinearProgress,
    Option,
    Sheet,
    Stack,
    Table,
    Typography
} from '@mui/joy';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import dayjs, {type Dayjs} from 'dayjs';
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import ScatterPlotRoundedIcon from '@mui/icons-material/ScatterPlotRounded';
import EuroRoundedIcon from '@mui/icons-material/EuroRounded';
import {useAsync, useAsyncCallback} from 'react-async-hook';
import {ClubSelect} from '../../component/club-select';
import {DatePicker} from '../../component/date-picker';
import {AccountSelect} from '../../component/account-select';
import {DeviceSelect} from '../../component/device-select';
import {Checkbox} from '../../component/checkbox';
import {useLocalization} from '../../context/localization';
import {displayAmount, displayDatetime, IsoDateFormat, notBlank} from '../../utils';
import {
    type AccountTransaction,
    TransactionDirection,
    TransactionPurpose,
    type TransactionsStatisticFilter,
    type TransactionStatistic,
    type TransactionSummary,
    TransactionType,
    useStatisticApi
} from '../../data/statistic';
import {Select} from '../../component/select';
import {TransactionTypeChip} from './transaction-type-chip';
import {TransactionPurposeChip} from './transaction-purpose-chip';
import {ColoredAmount} from '../../component/colored-amount';
import {UserLabel} from '../../component/user-label';
import {WalletType} from '../../data/wallet';
import {TransactionStatusChip} from './transaction-status-chip';
import {DeviceType} from '../../data/device';
import {TransactionExportButton} from './transaction-export-button';
import {TransactionModal} from './transaction-modal';
import {AuthWrapper, useAuthContext} from '../../context/auth';
import {UserRole} from '../../data/user';
import {MultiSelect} from '../../component/multi-select';

export function TransactionStatisticView() {
    return (
        <AuthWrapper>
            <ContentView />
        </AuthWrapper>
    );
}

function ContentView() {
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role, clubId: authClubId} = useAuthContext();
    const {computeTransactions, computeNotes} = useStatisticApi();

    const StorageKey = `filter-transactions-statistics:${role}`;

    const {result: data, execute, reset, loading} = useAsyncCallback(computeTransactions);

    const [clubId, setClubId] = useState(authClubId);
    const [deviceId, setDeviceId] = useState<string>();
    const [accountId, setAccountId] = useState<string>();

    /* eslint-disable unicorn/no-null */
    const [from, setFrom] = useState<Dayjs|null>(null);
    const [until, setUntil] = useState<Dayjs|null>(null);
    /* eslint-enable unicorn/no-null */

    const [direction, setDirection] = useState('');
    const [purpose, setPurpose] = useState('');
    const [notes, setNotes] = useState<string[]>([]);
    const [hideBlankAmount, setHideBlankAmount] = useState(false);
    const [hideIncomplete, setHideIncomplete] = useState(false);

    const [transaction, setTransaction] = useState<AccountTransaction>();

    const {result: noteOptions} = useAsync(async () =>
        notBlank(clubId)
            ? computeNotes(clubId as string)
            : undefined
        , [clubId]);

    useEffect(() =>
        {
            const stored = storage.get<TransactionsStatisticFilter>(StorageKey);
            if (stored !== undefined) {
                setClubId(role === UserRole.Root ? stored.clubId : authClubId);
                setFrom(dayjs(stored.from, IsoDateFormat.Date));
                setUntil(dayjs(stored.until, IsoDateFormat.Date));
                setDirection(stored.direction ?? '');
                setPurpose(stored.purpose ?? '');
                setNotes(stored.notes ?? []);
                setHideBlankAmount(stored.hideBlankAmount ?? false);
                setHideIncomplete(stored.hideIncomplete ?? false);
                execute(stored)
                    .catch(console.error);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [authClubId]
    );

    function handleReset() {
        setClubId(authClubId);
        /* eslint-disable unicorn/no-null */
        setFrom(null);
        setUntil(null);
        /* eslint-enable unicorn/no-null */
        setDeviceId(undefined);
        setAccountId(undefined);
        setDirection('');
        setPurpose('');
        setNotes([]);
        setHideBlankAmount(false);
        setHideIncomplete(false);
        reset();
        storage.delete(StorageKey);
    }

    function handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        fetchData()
            .catch(console.error);

    }

    async function fetchData() {
        const filter = getFilter();
        let fetchResult;
        if (filter !== undefined && clubId !== undefined) {
            try {
                fetchResult = await execute({...filter, deviceId, accountId});
                storage.save(StorageKey, filter);
            } catch (error) {
                console.error('Failed to export data', error);
            }
        }
        return fetchResult;
    }

    function getFilter(): TransactionsStatisticFilter|undefined {
        if (clubId !== undefined
            && from !== null
            && until !== null
        ) {
            return {
                clubId,
                from: from.format(IsoDateFormat.Date),
                until: until.format(IsoDateFormat.Date),
                direction: direction as TransactionDirection,
                purpose: purpose as TransactionPurpose,
                notes,
                hideBlankAmount,
                hideIncomplete
            };
        }
        return undefined;
    }

    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('stats.title'), onClick: () => { navigate('/statistics/transactions'); } },
                        { label: t('stats.types.Transactions') },
                    ]}
                />
            )}
            title={(
                <Title
                    title={`${t('stats.title')} - ${t('stats.types.Transactions')}`}
                />
            )}
        >
            <Box
                mt={2}
                rowGap={2}
            >
                <Sheet
                    variant="outlined"
                >
                    <form onSubmit={handleFormSubmit}>
                        <Box p={2}>
                            <Typography level="title-md">{t('table.filterLabel')}</Typography>
                            <Typography level="body-sm">{t('stats.filterDescription')}</Typography>
                        </Box>
                        <Divider />
                        <Box p={2}>
                            <Stack
                                flexDirection={{ xs: 'column', lg: 'row' }}
                                rowGap={1}
                                columnGap={2}
                            >
                                <Box
                                    width={{ xs: '100%', lg: '40%' }}
                                >
                                    <Grid container spacing={2}>
                                        {role === UserRole.Root && (
                                            <Grid xs={12}>
                                                <ClubSelect
                                                    defaultClubId={clubId}
                                                    onChange={setClubId}
                                                />
                                            </Grid>
                                        )}
                                        <Grid xs={12} md={6}>
                                            <DatePicker
                                                label={t('common.from')}
                                                PickerProps={{
                                                    value: from,
                                                    onChange: setFrom
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                            <DatePicker
                                                label={t('common.until')}
                                                PickerProps={{
                                                    value: until,
                                                    onChange: setUntil
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={12}>
                                            <DeviceSelect
                                                disabled={!notBlank(clubId)}
                                                clubId={clubId}
                                                defaultDeviceId={deviceId}
                                                onChange={setDeviceId}
                                                type={DeviceType.BallVendingMachine}
                                            />
                                        </Grid>
                                        <Grid xs={12}>
                                            <AccountSelect
                                                disabled={!notBlank(clubId)}
                                                clubId={clubId}
                                                defaultAccountId={accountId}
                                                onChange={setAccountId}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    width={{ xs: '100%', lg: '60%' }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid xs={12} md={6} >
                                            <Select
                                                emptyValue
                                                label={t('common.direction')}
                                                options={Object.values(TransactionDirection)}
                                                renderOption={o => (
                                                    <Option key={o} value={o}>{t(`transactions.directions.${o}`)}</Option>
                                                )}
                                                SelectProps={{
                                                    placeholder: t('hints.select'),
                                                    value: direction as TransactionDirection,
                                                    onChange: (_, value) => { setDirection(value ?? ''); },
                                                    onClear: () =>  { setDirection(''); }
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={12} md={6} >
                                            <Select
                                                emptyValue
                                                label={t('transactions.purpose')}
                                                options={Object.values(TransactionPurpose)}
                                                renderOption={o => (
                                                    <Option key={o} value={o}>{t(`transactions.purposes.${o}`)}</Option>
                                                )}
                                                SelectProps={{
                                                    placeholder: t('hints.select'),
                                                    value: purpose as TransactionPurpose,
                                                    onChange: (_, value) => {
                                                        setPurpose(value ?? '');
                                                    },
                                                    onClear: () => { setPurpose(''); }
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={12}>
                                            <MultiSelect
                                                label={t('transactions.notes')}
                                                options={noteOptions ?? []}
                                                SelectProps={{
                                                    placeholder: t('hints.select'),
                                                    value: notes,
                                                    onChange: (_, value) => { setNotes(value); },
                                                    onClear: () => { setNotes([]); }
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={12}>
                                            <Box my={3} />
                                            <Checkbox
                                                CheckboxProps={{
                                                    label: t('stats.hideBlankAmount'),
                                                    name: 'enabled',
                                                    checked: hideBlankAmount,
                                                    onChange: (event_) => { setHideBlankAmount(event_.target.checked); }
                                                }}
                                            />
                                            <Box my={2} />
                                            <Checkbox
                                                CheckboxProps={{
                                                    label: t('stats.hideIncomplete'),
                                                    name: 'enabled',
                                                    checked: hideIncomplete,
                                                    onChange: (event_) => { setHideIncomplete(event_.target.checked); }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Stack>
                        </Box>
                        <Divider />
                        <Stack
                            sx={{ p: 2 }}
                            direction={{ xs: 'column-reverse', sm: 'row' }}
                            justifyContent={{ xs: 'stretch', sm: 'space-between' }}
                            gap={2}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: {
                                        xs: 'column',
                                        sm: 'row'
                                    },
                                    gap: 2
                                }}
                            >
                                {clubId !== undefined && from !== null && until !== null && (
                                    <TransactionExportButton
                                        {...{ clubId, from, until }}
                                        fetch={fetchData}
                                    />
                                )}
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: {
                                        xs: 'column',
                                        sm: 'row'
                                    },
                                    gap: 2
                                }}
                            >
                                <Button
                                    type="submit"
                                    startDecorator={<FilterAltRoundedIcon />}
                                    disabled={loading}
                                >
                                    {t('actions.submit')}
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="neutral"
                                    startDecorator={<RestartAltRoundedIcon />}
                                    onClick={handleReset}
                                    disabled={loading}
                                >
                                    {t('actions.reset')}
                                </Button>
                            </Box>
                        </Stack>
                    </form>
                </Sheet>

                {loading && (
                    <Box my={2}>
                        <LinearProgress />
                    </Box>
                )}

                {data !== undefined && (
                    <Stack
                        direction="column"
                        gap={3}
                    >
                        <Box my={1} />
                        <StatisticSummary data={data} />
                        <Sheet
                            variant="outlined"
                            sx={{
                                display: { xs: 'none', sm: 'initial' },
                                width: '100%',
                                borderRadius: 'sm',
                                flexShrink: 1,
                                overflow: 'auto',
                                minHeight: 0
                            }}
                        >
                            <Table hoverRow>
                                <thead>
                                <tr>
                                    <th style={{ width: 40 }}>#</th>
                                    <th style={{ width: 160 }}>{t('common.timestamp')}</th>
                                    <th style={{ width: 140 }}>{t('accounts.singular')}</th>
                                    <th style={{ width: 120 }}>{t('common.type')}</th>
                                    <th style={{ width: 160 }}>{t('transactions.purpose')}</th>
                                    <th style={{ width: 100 }}>{t('transactions.clerk')}</th>
                                    <th className="amount" style={{ width: 120 }}>{t('common.amount')} (Intl.)</th>
                                    <th className="amount" style={{ width: 120 }}>{t('common.amount')} ({t('clubs.singular')})</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.record.map((r, index) => (
                                    <TableRow
                                        key={r.uuid}
                                        accountTransaction={r}
                                        index={index}
                                        onClick={() => { setTransaction(r); }}
                                    />
                                ))}
                                </tbody>
                            </Table>
                        </Sheet>
                    </Stack>
                )}
                {transaction !== undefined && <TransactionModal transaction={transaction} onClose={() => { setTransaction(undefined); }} />}
            </Box>
        </Body>
    );
}

function TableRow({accountTransaction: {timestamp, clearedAt, accountName, type, note, data, clerkName, walletType}, index, onClick}: { accountTransaction: AccountTransaction, index: number, onClick: () => void }) {
    const isNegative = type === TransactionType.SubtractCredit || type === TransactionType.ConsumeCredit;
    return (
        <tr style={{ cursor: 'pointer' }} onClick={onClick}>
            <td>
                <Typography level="body-xs">{index + 1}</Typography>
            </td>
            <td>
                <Typography level="body-xs" gutterBottom>{displayDatetime(timestamp)}</Typography>
                <TransactionStatusChip clearedAt={clearedAt}/>
            </td>
            <td>
                <Typography level="body-xs">{accountName}</Typography>
            </td>
            <td>
                <TransactionTypeChip type={type}/>
            </td>
            <td>
                <Typography level="body-xs" gutterBottom>{note}</Typography>
                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                {notBlank(data.purpose) && <TransactionPurposeChip purpose={data.purpose!}/>}
            </td>
            <td>
                {typeof clerkName === 'string' && <UserLabel label={clerkName} noWrap/>}
            </td>
            <td>
                {walletType === WalletType.International && (
                    <ColoredAmount amount={data.amount} negative={isNegative} disabled={!notBlank(clearedAt)}/>
                )}
            </td>
            <td>
                {walletType === WalletType.Club && (
                    <ColoredAmount amount={data.amount} negative={isNegative} disabled={!notBlank(clearedAt)}/>
                )}
            </td>
        </tr>
    );
}

function StatisticSummary({data}: { data: TransactionStatistic }) {
    const {t} = useLocalization();
    return (
        <Grid container spacing={2}>
            <Grid xs={12} lg={6} xl={4}>
                <Sheet
                    variant="outlined"
                    sx={{p: 2}}
                >
                    <Typography level="title-md">{t('transactions.directions.BookIn')}</Typography>
                    <Divider sx={{mx: -2, my: 2}}/>
                    <SummaryView summary={data.bookIn}/>
                </Sheet>
            </Grid>
            <Grid xs={12} lg={6} xl={4}>
                <Sheet
                    variant="outlined"
                    sx={{p: 2}}
                >
                    <Typography level="title-md">{t('transactions.directions.BookOut')}</Typography>
                    <Divider sx={{mx: -2, my: 2}}/>
                    <SummaryView summary={data.bookOut}/>
                </Sheet>
            </Grid>
            <Grid xs={12} xl={4}>
                <Sheet
                    variant="outlined"
                    sx={{p: 2}}
                >
                    <Typography level="title-md">{t('common.summary')}</Typography>
                    <Divider sx={{mx: -2, my: 2}}/>
                    <Stack
                        direction="row"
                        gap={2}
                    >
                        <div>
                        <Typography level="body-xs" gutterBottom><strong>{t('devices.quantity.Balls')}</strong></Typography>
                            <Typography level="title-lg" startDecorator={<ScatterPlotRoundedIcon />}>{data.totalBallQty}</Typography>
                        </div>
                        <div>
                            <Typography level="body-xs" gutterBottom><strong>{t('clubs.serviceFee')} ({data.serviceFeePercentage}%)</strong></Typography>
                            <Typography level="title-lg" startDecorator={<EuroRoundedIcon />}>{displayAmount(data.serviceFee)}</Typography>
                        </div>
                        <div>
                            <Typography level="body-xs" gutterBottom><strong>Payout</strong></Typography>
                            <Typography level="title-lg" startDecorator={<EuroRoundedIcon />}>{displayAmount(data.payout)}</Typography>
                        </div>
                    </Stack>
                </Sheet>
            </Grid>
        </Grid>
    );

}

function SummaryView({summary: { totalSum, intlSum, clubSum }}: { summary: TransactionSummary }) {
    const {t} = useLocalization();
    return (
        <Stack
            direction="row"
            gap={2}
        >
            <div>
                <Typography level="body-xs" gutterBottom><strong>{t('common.total')}</strong></Typography>
                <Typography level="title-lg" startDecorator={<EuroRoundedIcon />}>{displayAmount(totalSum)}</Typography>
            </div>
            <div>
                <Typography level="body-xs" gutterBottom><strong>Intl.</strong></Typography>
                <Typography level="title-lg" startDecorator={<EuroRoundedIcon />}>{displayAmount(intlSum)}</Typography>
            </div>
            <div>
                <Typography level="body-xs" gutterBottom><strong>{t('clubs.singular')}</strong></Typography>
                <Typography level="title-lg" startDecorator={<EuroRoundedIcon />}>{displayAmount(clubSum)}</Typography>
            </div>
        </Stack>
    );
}


