import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {Body, Breadcrumbs, Title, useFlag} from '@mlyngvo/common-ui';
import React, {useMemo, useState} from 'react';
import {useAsync} from 'react-async-hook';
import {Box, Button, Divider, Grid, Sheet, Stack, Typography} from '@mui/joy';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {Input} from '../../component/input';
import {type AccountRequest, useAccountApi} from '../../data/account';
import {notNull} from '../../utils';
import {useLocalization} from '../../context/localization';
import {ConfirmDialog} from '../../component/confirm-dialog';
import {AuthWrapper, useAuthContext} from '../../context/auth';
import {UserRole} from '../../data/user';

export function AccountEditor() {
    return (
        <AuthWrapper>
            <ContentView />
        </AuthWrapper>
    );
}

interface FormElements extends HTMLFormControlsCollection {
    title: HTMLInputElement;
    firstName: HTMLInputElement;
    lastName: HTMLInputElement;
    email: HTMLInputElement;
    phone: HTMLInputElement;
    addressAddressLine1: HTMLInputElement;
    addressAddressLine2: HTMLInputElement;
    addressZipCode: HTMLInputElement;
    addressCity: HTMLInputElement;
    addressCountry: HTMLInputElement;
}

interface AccountEditorFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

function ContentView() {
    const {id } = useParams<{id: string}>();
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role} = useAuthContext();
    const {find, put, deleteAccount} = useAccountApi();

    const {result: account, error: loadError, loading} = useAsync(async () =>
            (id === undefined) ? undefined : await find(id)
        , [id]);

    const [deletionPromptOpen, setDeletionPromptOpen, clearDeletionPromptOpen] = useFlag(false);
    const [error, setError] = useState<Error>();

    function handleFormSubmit(event: React.FormEvent<AccountEditorFormElement>) {
        event.preventDefault();

        if (account !== undefined) {
            const formElements = event.currentTarget.elements;

            const data: AccountRequest = {
                email: formElements.email.value,
                title: formElements.title.value,
                firstName: formElements.firstName.value,
                lastName: formElements.lastName.value,
                phone: formElements.phone.value,
                address: {
                    addressLine1: formElements.addressAddressLine1.value,
                    addressLine2: formElements.addressAddressLine2.value,
                    zipCode: formElements.addressZipCode.value,
                    city: formElements.addressCity.value,
                    country: formElements.addressCountry.value,
                },
            };

            put(account.id, data)
                .then(() => { navigate(`/accounts/${account.id}`); })
                .catch(error_ => {
                    console.error('Failed to put account data', error_);
                    setError(new Error('Failed to update.'));
                });
        }
    }

    function handleDelete() {
        if (account !== undefined) {
            deleteAccount(account.id)
                .then(() => { navigate('/accounts'); })
                .catch(setError);
        }
    }

    const name = useMemo(() => [account?.title, [account?.lastName, account?.firstName].filter(notNull).join(', ')].join(' ').trim(), [account]);
    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('accounts.title'), onClick: () => { navigate('/accounts'); } },
                        { label: account === undefined ? t('common.details') : name, onClick: () => { navigate(`/accounts/${id}`); } },
                        { label: t('actions.edit') }
                    ]}
                />
            )}
            title={(
                <Title
                    title={account === undefined ? t('accounts.singular') : name}
                    actions={(
                        <Button
                            variant="outlined"
                            color="neutral"
                            startDecorator={<ArrowBackIosRoundedIcon />}
                            size="sm"
                            onClick={() => { navigate(`/accounts/${account?.id}`); }}
                        >
                            {t('actions.back')}
                        </Button>
                    )}
                />
            )}
            loading={loading}
            error={error ?? loadError}
        >
            {account !== undefined && (
                <>
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={3}>
                            <Grid xs={12} lg={8}>
                                <Sheet
                                    variant="outlined"
                                    sx={{
                                        p: 2
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid xs={12}>
                                            <Typography level="title-lg">{t('common.details')}</Typography>
                                        </Grid>
                                        <Grid xs={12} md={12} xl={4}>
                                            <Input
                                                label={t('common.title')}
                                                InputProps={{
                                                    name: 'title',
                                                    defaultValue: account.title
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={12} md={12} xl={4}>
                                            <Input
                                                label={t('common.firstName')}
                                                InputProps={{
                                                    name: 'firstName',
                                                    defaultValue: account.firstName
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={12} md={12} xl={4}>
                                            <Input
                                                label={t('common.lastName')}
                                                InputProps={{
                                                    name: 'lastName',
                                                    defaultValue: account.lastName
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{my: 3, mx: -2}}/>
                                    <Grid container spacing={2}>
                                        <Grid xs={12}>
                                            <Typography level="title-lg">{t('common.address')}</Typography>
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                            <Input
                                                label={t('common.addresses.line1')}
                                                InputProps={{
                                                    name: 'addressAddressLine1',
                                                    defaultValue: account.address?.addressLine1
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                            <Input
                                                label={t('common.addresses.line2')}
                                                InputProps={{
                                                    name: 'addressAddressLine2',
                                                    defaultValue: account.address?.addressLine2
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={12} md={4}>
                                            <Input
                                                label={t('common.zipcode')}
                                                InputProps={{
                                                    type: 'number',
                                                    inputMode: 'numeric',
                                                    name: 'addressZipCode',
                                                    defaultValue: account.address?.zipCode
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={12} md={4}>
                                            <Input
                                                label={t('common.city')}
                                                InputProps={{
                                                    name: 'addressCity',
                                                    defaultValue: account.address?.city
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={12} md={4}>
                                            <Input
                                                label={t('common.country')}
                                                InputProps={{
                                                    name: 'addressCountry',
                                                    defaultValue: account.address?.country
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Sheet>
                            </Grid>
                            <Grid xs={12} lg={4}>
                                <Sheet
                                    variant="outlined"
                                    sx={{
                                        p: 2
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid xs={12}>
                                            <Typography level="title-lg">{t('common.contact')}</Typography>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Input
                                                label={t('common.email')}
                                                InputProps={{
                                                    startDecorator: <EmailRoundedIcon fontSize="inherit"/>,
                                                    type: 'email',
                                                    inputMode: 'email',
                                                    name: 'email',
                                                    defaultValue: account.email
                                                }}
                                                FormControlProps={{
                                                    required: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={12}>
                                            <Input
                                                label={t('common.phone')}
                                                InputProps={{
                                                    startDecorator: <LocalPhoneRoundedIcon fontSize="inherit"/>,
                                                    name: 'phone',
                                                    defaultValue: account.phone
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Sheet>
                            </Grid>
                        </Grid>
                        <Divider sx={{my: 2}}/>
                        <Stack
                            direction={{sm: 'column', md: 'row'}}
                            gap={2}
                        >
                            <Button
                                startDecorator={<SaveRoundedIcon/>}
                                type="submit"
                            >
                                {t('actions.save')}
                            </Button>
                            <Button
                                startDecorator={<CloseRoundedIcon/>}
                                variant="outlined"
                                color="neutral"
                                onClick={() => {
                                    navigate(`/accounts/${account?.id}`);
                                }}
                            >
                                {t('actions.cancel')}
                            </Button>
                            {role === UserRole.Root && (
                                <>
                                    <Box flexGrow={1} sx={theme => ({[theme.breakpoints.down('md')]: {display: 'none'}})}/>
                                    <Button
                                        startDecorator={<DeleteRoundedIcon />}
                                        variant="outlined"
                                        color="danger"
                                        onClick={setDeletionPromptOpen}
                                    >
                                        {t('actions.delete')}
                                    </Button>
                                </>
                            )}
                        </Stack>
                    </form>
                    <ConfirmDialog
                        open={deletionPromptOpen}
                        onCancel={clearDeletionPromptOpen}
                        onConfirm={handleDelete}
                        title={t('accounts.deleteTitle')}
                        message={t('accounts.deletePrompt')}
                        color="danger"
                    />
                </>
            )}
        </Body>
    );
}