import {authStore} from '../store/auth';
import {notBlank} from '../utils';

function buildHeaders(omitAccessToken: boolean) {
    const {accessToken} = authStore;
    return {
        'Content-Type': 'application/json',
        ...(!omitAccessToken && notBlank(accessToken))
            ? {
                'Authorization': `Bearer ${accessToken}`,
                'X-Requested-With': 'XMLHttpRequest'
            }
            : {}
    };
}

function buildPath(path: string) {
    return `/api${path}`;
}

async function fetchData<T>(path: string, request: RequestInit, omitAccessToken: boolean): Promise<T|undefined> {
    const requestPath = buildPath(path);

    let response: Response;
    try {
        response = await fetch(requestPath, {
            ...request,
            headers: {
                ...request.headers,
                ...buildHeaders(omitAccessToken),
            }
        });
    } catch (error) {
        console.error('Fetch error', error);
        throw new Error('Failed to fetch. Please contact support.');
    }

    if (!response.ok) {
        if (response.status === 401 && notBlank(authStore.refreshToken) && window.location.pathname !== '/auth-refresh') {
            window.location.replace(`/auth-refresh?redirectPath=${encodeURIComponent(window.location.pathname)}`);
            return undefined;
        }
        const message = (await response.text()) ?? response.statusText;
        throw new Error(message);
    }
    if (response.status === 204) {
        console.info('Response status of 204. Return empty body.');
        return undefined;
    }

    try {
        return (await response.json()) as T;
    } catch (error) {
        console.error('Body parsing error', error);
        throw new Error('Invalid body returned.');
    }
}

export function createQueryString(parameters: Record<string, string|number|boolean|undefined>) {
    return Object.entries(parameters)
        .map(([k, v]) => `${k  }=${encodeURIComponent(v ?? '')}`)
        .join('&');
}

export function useApi(omitAccessToken?: boolean) {
    return {
        get: async <T>(path: string) =>
            await fetchData<T>(path, {}, omitAccessToken ?? false),
        post: async <T>(path: string, body: unknown) =>
            await fetchData<T>(path, {
                method: 'POST',
                body: JSON.stringify(body)
            }, omitAccessToken ?? false),
        put: async (path: string, body: unknown) =>
            await fetchData(path, {
                method: 'PUT',
                body: JSON.stringify(body)
            }, omitAccessToken ?? false),
        patch: async (path: string, parameters: Record<string, string|number|boolean>) =>
            await fetchData(`${path  }?${  createQueryString(parameters)}`, {
                method: 'PATCH'
            }, omitAccessToken ?? false),
        del: async (path: string) =>
            await fetchData(`${path}`, {
                method: 'DELETE'
            }, omitAccessToken ?? false),
    };
}