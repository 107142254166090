import {createPageableParameters, type Page, type Pageable} from '@mlyngvo/common-ui';
import {useApi} from './api';

export interface Notification {
    id: string;
    createdAt: string;
    publishedAt?: string;
    title: string;
    content: string;
    clubId: string;
    clubName: string;
}

export interface NotificationRequest {
    title: string;
    content: string;
    clubId: string;
}

export enum NotificationFilterStatus {
    Published = 'Published',
    Pending = 'Pending'
}

export type NotificationListView = Pick<Notification, 'id'|'createdAt'|'publishedAt'|'title'|'clubId'|'clubName'>

export function useNotificationApi() {
    const {get, post, put, patch, del} = useApi();
    return {
        list: async (pageable: Pageable<NotificationListView>) =>
            await get<Page<NotificationListView>>(`/center/notifications?${createPageableParameters(pageable)}`),
        create: async (request: NotificationRequest) =>
            await post<Notification>('/center/notifications', request),
        find: async (id: string) =>
            await get<Notification>(`/center/notifications/${id}`),
        put: async (id: string, request: NotificationRequest) =>
            await put(`/center/notifications/${id}`, request),
        publish: async (id: string, timestamp: string) =>
            await patch(`/center/notifications/${id}/published`, { timestamp }),
        deleteNotification: async (id: string) =>
            await del(`/center/notifications/${id}`),
    };
}