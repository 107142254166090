import React, {useState} from 'react';
import {Divider, Dropdown, IconButton, ListItemDecorator, Menu, MenuButton, MenuItem} from '@mui/joy';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {handleExplicitClick} from '../../utils';
import {useLocalization} from '../../context/localization';

export function ItemMenu({onSelect, onDelete}: { onSelect: () => void, onDelete: () => void }) {
    const {t} = useLocalization();

    const [active, setActive] = useState(false);

    return (
        <Dropdown open={active} onOpenChange={(_, open) => { setActive(open); }}>
            <MenuButton
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
            >
                <MoreHorizRoundedIcon />
            </MenuButton>
            <Menu size="sm" sx={{ minWidth: 140 }} >
                <MenuItem
                    onClick={event_ =>
                    { handleExplicitClick(event_, () => { onSelect(); }); }
                    }
                >
                    <ListItemDecorator>
                        <EditRoundedIcon />
                    </ListItemDecorator>
                    {t('actions.edit')}
                </MenuItem>
                <Divider />
                <MenuItem
                    color="danger"
                    onClick={event_ =>
                    { handleExplicitClick(event_, () => { onDelete(); }); }
                    }
                >
                    <ListItemDecorator>
                        <DeleteRoundedIcon />
                    </ListItemDecorator>
                    {t('actions.delete')}
                </MenuItem>
            </Menu>
        </Dropdown>
    );
}