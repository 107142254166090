import React, {useState} from 'react';
import {useParams} from 'react-router';
import {useAsync} from 'react-async-hook';
import {useNavigate} from 'react-router-dom';
import {AvatarEditor, Body, Breadcrumbs, PlainTable, Tabs, Title, useFlag, useTabs} from '@mlyngvo/common-ui';
import {
    AspectRatio,
    Box,
    Button,
    Chip,
    Divider,
    IconButton,
    Link,
    List,
    ListDivider,
    ListItemButton,
    ListItemContent,
    Modal,
    ModalClose,
    ModalDialog,
    Sheet,
    Stack,
    Switch,
    Typography
} from '@mui/joy';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import PhotoRoundedIcon from '@mui/icons-material/PhotoRounded';
import {type Club, useClubApi} from '../../data/club';
import {useLocalization} from '../../context/localization';
import {ClubTypeChip} from './club-type-chip';
import {StatusChip} from '../../component/status-chip';
import {notBlank, notNull} from '../../utils';
import {type DeviceListView, useDeviceApi} from '../../data/device';
import {DeviceTypeChip} from '../device/device-type-chip';
import {ClubTransactionBookmarks} from './club-transaction-bookmarks';

const TabsKey = 'club_tabs';

export function ClubDetails() {
    const {id } = useParams<{id: string}>();
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {
        find,
        setEnabled,
        setLogo,
    } = useClubApi();
    const {list} = useDeviceApi();

    const {tab, changeTab} = useTabs(`${TabsKey}:${id}`);

    const {
        result: [
            club,
            devices,
        ] = [],
        error,
        loading,
        execute
    } = useAsync(async () =>
        (id === undefined)
            ? undefined
            : await Promise.all([
                find(id),
                list({ page: 0, size: 999, sort: { fields: ['name'], order: 'asc' }, filter: { clubId: id } })
            ])
        , [id]);

    async function handleSetEnabled(enabled: boolean) {
        if (id !== undefined) {
            try {
                await setEnabled(id, enabled);
            } catch (error_) {
                console.error('Failed to set enabled', error_);
            }
        }
    }

    async function handleSetLogo(data: string) {
        if (id !== undefined) {
            try {
                await setLogo(id, data.slice(22));
            } catch (error_) {
                console.error('Failed to set logo', error_);
            }
        }
    }

    async function handleBookmarksChange() {
        await execute();
    }

    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('clubs.title'), onClick: () => { navigate('/clubs'); } },
                        { label: club === undefined ? t('common.details') : club.name }
                    ]}
                />
            )}
            title={(
                <Title
                    title={club === undefined ? t('clubs.singular') : club.name}
                    actions={(
                        <Button
                            color="primary"
                            startDecorator={<EditRoundedIcon />}
                            size="sm"
                            onClick={() => { navigate(`/clubs/${club?.id}/edit`); }}
                        >
                            {t('actions.edit')}
                        </Button>
                    )}
                />
            )}
            {...{ error, loading }}
        >
            <Tabs
                value={tab}
                onChange={changeTab}
                items={[
                    (<Typography key="tab-0" level="body-sm" noWrap><strong>{t('common.overview')}</strong></Typography>),
                    (<Typography key="tab-1" level="body-sm" noWrap><strong>{t('devices.title')}</strong></Typography>),
                    (<Typography key="tab-3" level="body-sm" noWrap><strong>{t('payments.bookmarks.title')}</strong></Typography>),
                ]}
                expandMx={{
                    xs: -2,
                    md: -6
                }}
            />

            {club !== undefined && (
                <Box
                    mt={2}
                >
                    {tab === 0 && (
                        <Stack
                            rowGap={3}
                        >
                            <ClubMeta
                                {...club}
                                onStatusChange={handleSetEnabled}
                                onLogoChange={handleSetLogo}
                            />
                            <Stack
                                direction={{ sm: 'column', md: 'row' }}
                                rowGap={3}
                                columnGap={3}
                            >
                                <ClubInfos {...club} />
                                <ClubContact {...club} />
                            </Stack>

                        </Stack>
                    )}
                    {tab === 1 && (
                        <PlainTable
                            items={devices?.content}
                            loading={loading}
                            error={error}
                            headers={[
                                { label: 'ID', width: 100 },
                                { label: t('common.name'), width: 300 },
                                { label: t('devices.model'), width: 100 },
                                { label: t('devices.serialNumber'), width: 150 },
                            ]}
                            renderTableRow={device => <DeviceTableRow key={device.id} {...{device}} />}
                            renderListRow={device => <DeviceListRow key={device.id} {...{device}} />}
                        />
                    )}
                    {tab === 2 && (
                        <ClubTransactionBookmarks club={club} onUpdate={handleBookmarksChange} />
                    )}
                </Box>
            )}
        </Body>
    );
}


/**
 * Club overview
 */
type ClubMetaProperties = Pick<Club, 'id'|'lta'|'type'|'pinpointServiceFee'|'enabled'|'logoUrl'> & {
    onStatusChange: (enabled: boolean) => Promise<void>;
    onLogoChange: (logoData: string) => Promise<void>;
}

function ClubMeta({id, lta, type, pinpointServiceFee, enabled, logoUrl, onStatusChange, onLogoChange}: ClubMetaProperties) {
    const {t} = useLocalization();

    const [counter, setCounter] = useState(0);
    const [checked, setChecked] = useState(enabled);
    const [logoEditorOpen, setLogoEditorOpen, clearLogoEditorOpen] = useFlag(false);
    const [previewUrl, setPreviewUrl] = useState(logoUrl);

    function handleCheck(value: boolean) {
        onStatusChange(value)
            .then(() => { setChecked(value); })
            .catch(console.error);
    }

    function handleLogo(data: string) {
        onLogoChange(data)
            .then(() => {
                clearLogoEditorOpen();
                setPreviewUrl(`/api/clubs/${id}/logo`);
                setCounter(c => c + 1);
            })
            .catch(console.error);
    }


    return (
        <Stack
            direction={{ xs: 'column', sm: 'row' }}
            gap={3}
        >
            <Stack
                direction="column"
                spacing={1}
                sx={{ position: 'relative' }}
            >
                <AspectRatio
                    ratio="1"
                    maxHeight={150}
                    variant="outlined"
                    objectFit="contain"
                    sx={{
                        flex: 1,
                        minWidth: 100,
                        borderRadius: '7px',
                        bgcolor: 'common.white'
                    }}
                >
                    {notBlank(previewUrl)
                        ? (
                            <img
                                src={`${previewUrl}?s=${counter}`}
                                loading="lazy"
                                alt={lta}
                            />
                        )
                        : <div><PhotoRoundedIcon sx={{fontSize: '3rem', opacity: 0.5}}/></div>
                    }

                </AspectRatio>
                <IconButton
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    sx={theme => ({
                        bgcolor: 'background.body',
                        position: 'absolute',
                        zIndex: 2,
                        borderRadius: '50%',
                        right: -7,
                        bottom: -5,
                        boxShadow: 'sm',
                        [theme.breakpoints.down('sm')]: {
                            display: 'none'
                        }
                    })}
                    onClick={setLogoEditorOpen}
                >
                    <EditRoundedIcon />
                </IconButton>
            </Stack>
            <Sheet
                variant="outlined"
                sx={{
                    p: 2,
                    flexGrow: 1
                }}
            >
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    height="100%"
                    rowGap={2}
                    columnGap={3}
                >
                    <div>
                        <Typography level="body-xs" gutterBottom><strong>LTA</strong></Typography>
                        <Chip variant="outlined"><code>{lta}</code></Chip>
                    </div>
                    <div>
                        <Typography level="body-xs" gutterBottom><strong>{t('common.type')}</strong></Typography>
                        <ClubTypeChip type={type} />
                    </div>
                    <div>
                        <Typography level="body-xs" gutterBottom><strong>{t('clubs.serviceFee')}</strong></Typography>
                        {pinpointServiceFee}%
                    </div>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: { xs: 'flex-start', sm: 'flex-end' },
                        }}
                    >
                        <Typography level="body-xs" gutterBottom><strong>Status</strong></Typography>
                        <Switch
                            checked={checked}
                            onChange={event_ => { handleCheck(event_.target.checked); }}
                            color={checked ? 'success' : 'neutral'}
                            variant={checked ? 'solid' : 'solid'}
                            startDecorator={<StatusChip {...{ enabled: checked }} />}
                            slotProps={{
                                root: {
                                    sx: {
                                        display: 'flex',
                                        alignSelf: 'flex-end',
                                    }
                                },
                                input: {
                                    sx: {
                                        zIndex: 9
                                    }
                                },
                            }}
                        />
                    </Box>
                </Stack>
            </Sheet>
            <Modal open={logoEditorOpen} onClose={clearLogoEditorOpen}>
                <ModalDialog>
                    <ModalClose />
                    <Typography level="title-lg">{t('clubs.editLogo')}</Typography>
                    <Divider />
                    <AvatarEditor
                        defaultImageUrl={previewUrl ?? undefined}
                        onSave={handleLogo}
                        onCancel={clearLogoEditorOpen}
                        i18n={{
                            uploadFile: t('actions.uploadPhoto'),
                            save: t('actions.save'),
                            cancel: t('actions.cancel')
                        }}
                    />
                </ModalDialog>
            </Modal>
        </Stack>
    );
}

type ClubInfosProperties = Pick<Club, 'address'|'contactPerson'|'contact'|'support'>

function ClubInfos({address, contactPerson, contact, support}: ClubInfosProperties) {
    const {t} = useLocalization();

    return (
        <Sheet
            variant="outlined"
            sx={{ flex: 1 }}
        >
            <Box p={2}>
                <Typography level="title-md">{t('common.details')}</Typography>
                <Typography level="body-sm">{t('clubs.detailsDescription')}</Typography>
            </Box>
            <Divider />
            <Stack
                p={2}
                flexDirection="column"
                rowGap={2}
            >
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('common.address')}</strong></Typography>
                    <Typography>{address?.addressLine1}</Typography>
                    <Typography>{address?.addressLine2}</Typography>
                    <Typography>{address?.zipCode} {address?.city}</Typography>
                    <Typography>{address?.country}</Typography>
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('clubs.contactPerson')}</strong></Typography>
                    <Typography>{contactPerson}</Typography>
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('common.contact')}</strong></Typography>
                    <Typography startDecorator={<EmailRoundedIcon fontSize="inherit" />}> <Link href={`mailto:${contact?.email}`}>{contact?.email}</Link>&nbsp;</Typography>
                    <Typography startDecorator={<LocalPhoneRoundedIcon fontSize="inherit" />}> <Link href={`tel:${contact?.phone}`}>{contact?.phone}</Link>&nbsp;</Typography>
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('common.support')}</strong></Typography>
                    <Typography startDecorator={<EmailRoundedIcon fontSize="inherit" />}> <Link href={`mailto:${support?.email}`}>{support?.email}</Link>&nbsp;</Typography>
                    <Typography startDecorator={<LocalPhoneRoundedIcon fontSize="inherit" />}> <Link href={`tel:${support?.phone}`}>{support?.phone}</Link>&nbsp;</Typography>
                </div>
            </Stack>
        </Sheet>
    );
}

type ClubContactProperties = Pick<Club, 'rangefee'|'offlineSessionDuration'|'maxScanDuration'|'intlWalletEnabled'|'automaticallyAccountActivationEnabled'>;

function ClubContact({rangefee, offlineSessionDuration, maxScanDuration, intlWalletEnabled, automaticallyAccountActivationEnabled}: ClubContactProperties) {
    const {t} = useLocalization();
    return (
        <Sheet
            variant="outlined"
            sx={{ flex: 1 }}
        >
            <Box p={2}>
                <Typography level="title-md">{t('common.settings')}</Typography>
                <Typography level="body-sm">{t('clubs.settingsDescription')}</Typography>
            </Box>
            <Divider />
            <Stack
                p={2}
                flexDirection="column"
                rowGap={2}
            >
                <div>
                    <Typography level="body-xs" gutterBottom><strong>Rangefee</strong></Typography>
                    <Typography startDecorator="€">{rangefee}</Typography>
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('clubs.offlineSessionDuration')}</strong></Typography>
                    <Typography endDecorator={t('timeUnits.Hours')}>{offlineSessionDuration}</Typography>
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('clubs.maxScanDuration')}</strong></Typography>
                    {notNull(maxScanDuration) && <Typography endDecorator="ms">{maxScanDuration}</Typography>}
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('clubs.intWalletEnabled')}</strong></Typography>
                    <StatusChip enabled={intlWalletEnabled} />
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('clubs.autoAccountActivation')}</strong></Typography>
                    <StatusChip enabled={automaticallyAccountActivationEnabled} />
                </div>
            </Stack>
        </Sheet>
    );
}


/**
 * Devices
 */
function DeviceTableRow({device: {id, name, type, model, serialNumber}}: { device: DeviceListView }) {
    const navigate = useNavigate();
    return (
        <tr
            onClick={() => { navigate(`/devices/${id}`); }}
        >
            <td>
                <Chip
                    size="sm"
                    variant="outlined"
                    sx={theme => ({
                        minWidth: 80,
                        textAlign: 'center',
                        fontFamily: theme.fontFamily.code,
                        fontSize: '0.63rem'
                    })}
                >
                    {id}
                </Chip>
            </td>
            <td>
                <Typography level="body-xs" gutterBottom><strong>{name}</strong></Typography>
                <DeviceTypeChip type={type}/>
            </td>
            <td>
                <Typography level="body-xs">{model}</Typography>
            </td>
            <td>
                <Typography level="body-xs">{serialNumber}</Typography>
            </td>
        </tr>
    );
}

function DeviceListRow({device: {id, name, type, serialNumber}}: { device: DeviceListView }) {
    const navigate = useNavigate();

    return (
        <List
            size="sm"
            sx={{
                '--ListItem-paddingX': 0,
            }}
        >
            <ListItemButton
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    '&:hover': {
                        background: 'none !important'
                    }
                }}
                onClick={() => {
                    navigate(`/devices/${id}`);
                }}
            >
                <ListItemContent>
                    <DeviceTypeChip type={type} />
                    <Typography fontWeight={600} gutterBottom sx={{ mt: 1 }}>{name}</Typography>
                    <Typography level="body-xs">{serialNumber}</Typography>
                </ListItemContent>
                <Chip
                    size="sm"
                    variant="outlined"
                    sx={theme => ({
                        minWidth: 80,
                        textAlign: 'center',
                        fontFamily: theme.fontFamily.code,
                        fontSize: '0.63rem'
                    })}
                >
                    {id}
                </Chip>
            </ListItemButton>
            <ListDivider />
        </List>
    );
}