import React, {type FormEvent, type PropsWithChildren, useEffect, useState} from 'react';
import {Alert, Button, Divider, Modal, ModalClose, ModalDialog, Snackbar, Stack} from '@mui/joy';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {useFlag} from '@mlyngvo/common-ui';
import {useLocalization} from '../context/localization';
import {notBlank} from '../utils';

interface FormModalProperties<T> {
    open: boolean;
    onCancel: () => void;
    onSave: (event: FormEvent<T>) => Promise<void>;
    saveLabel?: string;
    successMessage?: string;
    fixedTop?: boolean;
    disabled?: boolean;
}

export function FormModal<T extends HTMLFormElement>({open, onCancel, onSave, saveLabel, successMessage, fixedTop = false, disabled = false, children}: PropsWithChildren<FormModalProperties<T>>) {
    const {t} = useLocalization();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error>();
    const [successOpen, setSuccessOpen, clearSuccessOpen] = useFlag(false);

    useEffect(() => {
        if (open) setError(undefined);
    }, [open]);

    function handleSave(event_: FormEvent<T>) {
        if (!loading) {
            setLoading(true);
            onSave(event_)
                .then(() => { setSuccessOpen(); })
                .catch(error_ => {
                    try {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        setError(JSON.parse(error_.message));
                    } catch {
                        setError(new Error('Failed to save data.'));
                    }
                })
                .finally(() => { setLoading(false); });
        }
    }

    return (
        <>
            <Modal
                open={open}
                onClose={() => {
                    if (!loading) onCancel();
                }}
            >
                <ModalDialog
                    maxWidth="sm"
                    minWidth="sm"
                    component="form"
                    onSubmit={handleSave}
                    sx={fixedTop ? { top: '5%', transform: 'translate(-50%, 0)' } : {}}
                >
                    {!loading && <ModalClose />}

                    {children}

                    {error !== undefined && (
                        <Alert color="danger">
                            {error.message}
                        </Alert>
                    )}

                    <Divider />
                    <Stack
                        sx={{ pt: 1 }}
                        direction="row"
                        justifyContent="flex-end"
                        gap={2}
                    >
                        {!disabled && (
                            <>
                                <Button
                                    type="submit"
                                    startDecorator={<CheckRoundedIcon />}
                                    loading={loading}
                                    disabled={loading}
                                >
                                    {notBlank(saveLabel) ? saveLabel : t('actions.save')}
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="neutral"
                                    startDecorator={<CloseRoundedIcon />}
                                    onClick={onCancel}
                                    disabled={loading}
                                >
                                    {t('actions.cancel')}
                                </Button>
                            </>
                        )}
                        {disabled && (
                            <Button
                                variant="outlined"
                                color="neutral"
                                startDecorator={<CloseRoundedIcon />}
                                onClick={onCancel}
                            >
                                {t('actions.close')}
                            </Button>
                        )}
                    </Stack>
                </ModalDialog>
            </Modal>
            {notBlank(successMessage) && (
                <Snackbar
                    variant="solid"
                    color="success"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={successOpen}
                    onClose={clearSuccessOpen}
                >
                    {successMessage}
                </Snackbar>
            )}
        </>
    );
}