import {createPageableParameters, type Page, type Pageable} from '@mlyngvo/common-ui';
import {useApi} from './api';
import {type AuthenticationResponse} from './auth';

export enum UserRole {
    Root = 'Root',
    Admin = 'Admin',
    User = 'User',
}

export interface User {
    id: string;
    email: string;
    title?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    position?: string;
    role: UserRole;
    clubs: Record<string, string>;
    avatarUrl?: string;
}

export interface UserRequest {
    email: string;
    role: UserRole;
    title?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    position?: string;
    clubId?: string;
}

export function useUserApi() {
    const {get, post, put, patch, del} = useApi();
    return {
        list: async (pageable: Pageable<User>) =>
            await get<Page<User>>(`/center/users?${createPageableParameters(pageable)}`),
        create: async (request: UserRequest) =>
            await post<User>('/center/users', request),
        takeover: async (id: string) =>
            await post<AuthenticationResponse>(`/center/users/${id}/takeover`, {}),
        put: async (id: string, request: UserRequest) =>
            await put(`/center/users/${id}`, request),
        find: async (id: string) =>
            await get<User>(`/center/users/${id}`),
        setAvatar: async (id: string, data: string) =>
            await put(`/center/users/${id}/avatar`, { data }),
        setPassword: async (id: string, password: string) =>
            await patch(`/center/users/${id}/password`, { password }),
        deleteUser: async (id: string) =>
            await del(`/center/users/${id}`)
    };
}