import * as jose from 'jose';
import {storage} from '@mlyngvo/common-ui';
import dayjs from 'dayjs';
import {type AuthenticationResponse} from '../data/auth';

const StorageKey = 'auth';

interface AuthData extends AuthenticationResponse {
    // The original credentials of root user during their takeover session
    originCredentials?: AuthenticationResponse;
}

class AuthStore {

    private readonly store = storage;

    get authData() {
        return this.store.get<AuthData>(StorageKey);
    }

    set authData(authData: AuthData|undefined) {
        if (authData === undefined) {
            this.store.delete(StorageKey);
        } else {
            this.store.save<AuthData>(StorageKey, authData);
        }
    }

    get accessToken() {
        return this.authData?.accessToken;
    }

    set accessToken(token: string|undefined) {
        this.authData = {
            ...this.authData,
            accessToken: token ?? ''
        };
    }

    get refreshToken() {
        return this.authData?.refreshToken;
    }

    get isTakeoverMode() {
        return this.authData?.originCredentials !== undefined;
    }

    startTakeover(takeoverCredentials: AuthenticationResponse) {
        const originCredentials = this.authData;
        if (originCredentials === undefined) {
            throw new Error('Invalid origin credentials.');
        }
        this.authData = {
            originCredentials,
            ...takeoverCredentials
        };
        window.location.replace('/');
    }

    exitTakeover() {
        const originCredentials = this.authData?.originCredentials;
        if (originCredentials === undefined) {
            throw new Error('Invalid origin credentials.');
        }
        this.authData = {
            originCredentials: undefined,
            ...originCredentials
        };
        window.location.replace('/users');
    }

    clear() {
        this.store.delete(StorageKey);
    }
}

export const authStore = new AuthStore();

export function isAuthTokenExpired(token: string) {
    const expiration = jose.decodeJwt(token).exp;
    return expiration === undefined
        ? true
        : dayjs(expiration * 1000).isBefore(dayjs());
}