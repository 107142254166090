import React, {createContext, type PropsWithChildren, useContext, useEffect, useMemo, useState} from 'react';
import {useAsyncCallback} from 'react-async-hook';
import {
    Alert,
    Button,
    CircularProgress,
    Divider,
    LinearProgress,
    Link,
    Modal,
    ModalDialog,
    Stack,
    Typography,
    useTheme
} from '@mui/joy';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import LoginIcon from '@mui/icons-material/Login';
import {useAuthApi} from '../data/auth';
import {useLocalization} from './localization';
import {authStore, isAuthTokenExpired} from '../store/auth';
import {notBlank} from '../utils';
import {useCenterApi} from '../data/center';

interface AuthProfile {
    name: string;
    position?: string;
    avatarUrl: string;
}

interface IAuthContext {
    authenticated: boolean;
    setAuthenticated: (value: boolean) => void;
    profile?: AuthProfile;
    role?: string;
    clubId?: string;
}

const AuthContext = createContext<IAuthContext>({
    authenticated: false,
    setAuthenticated: () => {}
});

function redirectToLogin() {
    const publicPaths = [
        '/login',
        '/forgot-password',
        '/user-accepts',
        '/account-confirm',
        '/account-email-confirm',
        '/account-password-reset',
    ];
    // eslint-disable-next-line no-restricted-syntax
    for (const path of publicPaths) {
        if (window.location.pathname.includes(path)) {
            return;
        }
    }
    window.location.href = '/login';
}

export function AuthenticationProvider({children}: PropsWithChildren) {
    const {t} = useLocalization();
    const {refreshAccessToken} = useAuthApi();
    const {me} = useCenterApi();
    const theme = useTheme();

    const [authenticated, setAuthenticated] = useState(false);
    const [profile, setProfile] = useState<AuthProfile>();
    const [role, setRole] = useState<string>();
    const [clubId, setClubId] = useState<string>();

    const {loading, error, execute} = useAsyncCallback(async () => {
        if (authStore.accessToken !== undefined) {
            try {
                if (isAuthTokenExpired(authStore.accessToken)
                    && authStore.refreshToken !== undefined
                ) {
                    console.info('Auth token expired. Fetching new token...');
                    authStore.accessToken = undefined;
                    const {accessToken} = await refreshAccessToken(authStore.refreshToken) ?? {};
                    if (accessToken !== undefined) {
                        authStore.authData = {
                            accessToken,
                            refreshToken: authStore.refreshToken
                        };
                        console.info('Auth token refreshed successfully.');
                    }
                }
            } catch (error_) {
                console.error('Failed to refresh access token', error_);
                authStore.clear();
                return;
            }

            try {
                const authData = await me();
                setAuthenticated(notBlank(authData?.id));
                if (authData !== undefined) {
                    setProfile({
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        name: notBlank(authData.nameShort) ? authData.nameShort! : authData.id,
                        position: authData.position,
                        avatarUrl: `/api/users/${authData.id}/avatar`,
                    });
                    setRole(authData.role);
                    setClubId(authData.clubIds.length > 0 ? authData.clubIds[0] : undefined);
                    return;
                }
            } catch (error_) {
                console.error('Failed to fetch authentication', error_);
                authStore.clear();
            }
        }

        redirectToLogin();
    });

    const value = useMemo(() => ({
        authenticated,
        setAuthenticated,
        profile,
        role,
        clubId
    }), [authenticated, profile, role, clubId]);

    useEffect(() =>
        {
            execute()
                .catch(console.error);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);

    return (
        <AuthContext.Provider
            value={value}
        >
            {loading && (
                <Stack
                    height="calc(100vh)"
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CircularProgress
                        size="lg"
                        variant="soft"
                    />
                </Stack>
            )}

            {notBlank(error) && (
                <Modal open>
                    <ModalDialog
                        variant="outlined"
                        maxWidth="md"
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                        >
                            <LockPersonIcon sx={{ color: theme.palette.danger.plainColor }} />
                            <Typography level="title-md" color="danger"><strong>{t('error.forbidden')}</strong></Typography>
                        </Stack>
                        <Typography>{t('auth.forbiddenAccess')}</Typography>
                        <Divider sx={{ my: 1 }} />
                        <div>
                            <Button
                                component={Link}
                                startDecorator={<LoginIcon />}
                                href="/login"
                            >
                                {t('actions.login')}
                            </Button>
                        </div>
                    </ModalDialog>
                </Modal>
            )}

            {children}
        </AuthContext.Provider>
    );
}

export function useAuthContext() {
    return useContext(AuthContext);
}

export function AuthWrapper({allowRoles = [], children}: PropsWithChildren<{ allowRoles?: string[] }>) {
    const {role} = useAuthContext();

    if (role === undefined) {
        return <LinearProgress />;
    }

    if (allowRoles?.length > 0 && !allowRoles?.includes(role)) {
        return (
            <Stack
                direction="column"
                width="100%"
                p={3}
            >
                <Alert color="danger">
                    Forbidden.
                </Alert>
            </Stack>
        );
    }

    return children;
}