import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import {Stack, Typography, useTheme} from '@mui/joy';
import React from 'react';
import {RouterLink} from './link';

export function ClubLabel({label, clubId, noWrap = false}: { label: string, clubId: string, noWrap?: boolean }) {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            alignItems="center"
            gap={1}
            component={RouterLink}
            href={`/clubs/${clubId}`}
        >
            <CorporateFareRoundedIcon sx={{ color: theme.palette.text.tertiary, fontSize: theme.fontSize.md }}/>
            <Typography
                level="body-xs"
                noWrap={noWrap}
            >
                {label}
            </Typography>
        </Stack>
    );
}